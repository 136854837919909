import { ButtonV3 as Button } from '@provi/provi-components'
import GuarantorChecklistOptions from '~/components/molecules/GuarantorChecklistOptions'
import { useGuarantorChecklistMessage } from './hooks'
import * as S from './style'

const GuarantorChecklistMessage = () => {
  const { isLoadingData, checklistOptions, handleSubmitButton, isSendingData } = useGuarantorChecklistMessage()

  return (
    <S.Wrapper>
      <S.GuarantorChecklistContent>
        <S.TitleInformation>Indicação de avalista</S.TitleInformation>
        <GuarantorChecklistOptions checklistOptions={checklistOptions} withTip iconVariant />
      </S.GuarantorChecklistContent>

      {isLoadingData ? (
        <S.ChecklistButtonLoading />
      ) : (
        <Button text="Continuar" icon onClick={handleSubmitButton} isLoading={isSendingData} />
      )}
    </S.Wrapper>
  )
}

export default GuarantorChecklistMessage
