import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { CoursesContext } from '~/contexts/CoursesContext'
import { CreditPathContext } from '~/contexts/CreditPathContext'
import { readPreDocument, writePreDocument } from '~/services/api'
import { IDocumentType } from '~/types/index'
import { isWebview } from '~/utils/isWebview'

export const usePreDocumentContent = () => {
  const { step, isLoadingData, setIsLoadingData, isSendingData } = useContext(CheckoutContext)
  const { setDocumentType, openFilePicker } = useContext(CreditPathContext)
  const { openModal } = useContext(CoursesContext)

  const [documentOptions, setDocumentOptions] = useState<IDocumentType[]>()
  const [selectedDocument, setSelectedDocument] = useState<IDocumentType | null>(null)

  useEffect(() => {
    async function getPreDocument() {
      setIsLoadingData(true)
      const result = await readPreDocument()

      if (typeof result != 'string') {
        setDocumentOptions(result.response.documentOptions)
      }
    }

    getPreDocument().finally(() => setIsLoadingData(false))
  }, [setIsLoadingData])

  const handleSelectedDocumentType = useCallback(
    async (selectedDocumentType: IDocumentType) => {
      setSelectedDocument(selectedDocumentType)
    },
    [setSelectedDocument],
  )

  const handleSendPhoto = useCallback(() => {
    setDocumentType('upload')

    const onSuccess = async () => {
      await writePreDocument({
        currentScreen: step,
        data: {
          documentType: selectedDocument,
        },
      })
    }

    openFilePicker({ onSuccess })
  }, [openFilePicker, setDocumentType, step, selectedDocument])

  const handleOpenCamera = useCallback(async () => {
    const shouldShowModal = isWebview()

    if (shouldShowModal) {
      window?.smartlook?.('track', 'showModalWebView')
      openModal()
      return
    }

    setDocumentType('webcam')

    await writePreDocument({
      currentScreen: step,
      data: {
        documentType: selectedDocument,
      },
    })
  }, [step, setDocumentType, selectedDocument, openModal])

  return {
    documentOptions,
    selectedDocument,
    setSelectedDocument,
    handleSelectedDocumentType,
    isLoadingData,
    handleSendPhoto,
    handleOpenCamera,
    isSendingData,
  }
}
