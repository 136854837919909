import { ButtonV3 as Button } from '@provi/provi-components'
import { useRouter } from 'next/router'
import { MutableRefObject, useCallback, useContext, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Swiper, SwiperSlide } from 'swiper/swiper-react.cjs.js'
import * as S from './style'

import { IClass, ICourses, ICoursesData } from 'types'
import { ButtonGroup, CourseHeading, GenerateLeadFromEmptyCourse } from '~/components/organisms'
import CardCourse from '../CardCourse'

import { LoadingCarouselCourses } from '~/components/atoms'
import { WhatsappIcon } from '~/components/atoms/Icons/WhatsappIcon'
import { CoursesContext } from '~/contexts/CoursesContext'
import { useWindowSize } from '~/utils/useWindowSize'
import { useCarouselCourses } from './hooks'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

interface ICarouselCourses {
  coursesData: ICoursesData
}

interface ICarouselData {
  classCourse: IClass[]
  courseId: number
  workload: ICourses['workload']
}

const amountOfItemsToShow = (swiperRef: MutableRefObject<any>, width: number) => {
  const maxWidth = 896
  const containerWidth = swiperRef.current?.swiper?.width || 400

  const maxContainerWidth = Math.min(maxWidth, containerWidth)

  const itemWidth = (width > 800 ? 252 : 214) + 16

  const amountOfItems = maxContainerWidth / itemWidth

  return amountOfItems
}

const CarouselClasses = ({ classCourse, courseId, workload }: ICarouselData) => {
  const { width } = useWindowSize()
  const router = useRouter()
  const { selectedClasses } = useContext(CoursesContext)

  const swiperRef = useRef<any>(null)
  const slidesPerView = amountOfItemsToShow(swiperRef, width)

  const defaultSelectedClasses = router.query?.classes
    ? (router.query?.classes as string)?.split(',').map(Number)
    : selectedClasses?.map((selectedClass) => selectedClass.classId)

  const renderClasses = useCallback(
    (classCourse, courseId, workload) => {
      return classCourse.map((course: IClass) => {
        const isSelected =
          // this is the default value on page load
          defaultSelectedClasses?.includes(course.ClassId)

        return (
          <SwiperSlide key={course.ClassId}>
            <CardCourse
              key={course.ClassId}
              classNameCourse={course?.className}
              classDuration={course.classDurationDate}
              workload={workload}
              price={course.price}
              isFullTime={
                course.extraInfo?.classPeriod === null ? null : course.extraInfo?.classPeriod?.toLowerCase() === 'integral'
              }
              modality={course.extraInfo?.modality}
              attendType={course.extraInfo?.attendType}
              campus={course.extraInfo?.campus}
              classId={course.ClassId}
              removable={course.removable}
              courseId={courseId}
              checkedRadio={isSelected}
              discountPercentage={course.discountPercentage}
              totalPriceToPay={course.priceWithDiscount}
            />
          </SwiperSlide>
        )
      })
    },
    [defaultSelectedClasses],
  )

  return (
    <S.CarouselContainer>
      <ButtonGroup
        swiperRef={swiperRef}
        classLength={classCourse.length}
        key={swiperRef?.current?.swiper.realIndex || courseId}
      />

      {/* @ts-ignore */}
      <Swiper spaceBetween={16} slidesPerView={slidesPerView} ref={swiperRef}>
        {renderClasses(classCourse, courseId, workload)}
      </Swiper>
    </S.CarouselContainer>
  )
}

const CarouselCourses = ({ coursesData }: ICarouselCourses) => {
  const { fetchMoreData, hasNextPage, courses } = useCarouselCourses({ coursesData })
  const { unavailableClickedCourseObject, setUnavailableClickedCourseObject } = useContext(CoursesContext)

  if (unavailableClickedCourseObject) {
    return (
      <GenerateLeadFromEmptyCourse
        onClose={() => setUnavailableClickedCourseObject(null)}
        courseObject={unavailableClickedCourseObject}
      />
    )
  }

  return (
    <div key={coursesData?.PartnerId}>
      {(!coursesData?.courses || courses?.length === 0) && (
        <S.EmptyCarouselContent>
          <h3>Ops!</h3>
          <p>Nenhum curso disponível no momento.</p>

          <p>Caso tenha ficado com alguma duvida, fique à vontade para entrar em contato com o nosso atendimento.</p>
          <Button
            as="button"
            text={
              <>
                <WhatsappIcon className="mr-2" /> Falar com atendimento
              </>
            }
            icon={false}
            buttonProps={{
              href: 'https://api.whatsapp.com/send?phone=5511914447176&text=Oi, Principia! Pode me ajudar?',
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
        </S.EmptyCarouselContent>
      )}

      <InfiniteScroll
        dataLength={courses?.length || 0}
        next={fetchMoreData}
        hasMore={hasNextPage}
        loader={<LoadingCarouselCourses />}
      >
        {courses?.map((course: ICourses) => (
          <S.CarouselWrapper key={course.CourseId}>
            <CourseHeading
              courseName={course.courseName}
              courseDescription={course.courseDescription}
              activeClasses={course.activeClasses}
            />

            <S.CarouselContent>
              {course.activeClasses === 0 && (
                <S.UnavailableCourseText>
                  Nenhuma turma disponível. Para receber um aviso sobre as próximas turmas, preencha os dados no{' '}
                  <S.UnavailableCourseButton data-cy="generate-lead" onClick={() => setUnavailableClickedCourseObject(course)}>
                    formulário
                  </S.UnavailableCourseButton>
                  .
                </S.UnavailableCourseText>
              )}
              <CarouselClasses classCourse={course.classes} courseId={course.CourseId} workload={course.workload} />
            </S.CarouselContent>
          </S.CarouselWrapper>
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default CarouselCourses
