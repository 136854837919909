import { RowContact } from '~/components/molecules'
import * as S from './style'

const CartFooterInformation = () => (
  <S.CartFooterInformationWrapper>
    <RowContact
      text="+55 (11) 91444-7176"
      source="/assets/svg/whatsappLogo.svg"
      alt="WhatsApp"
      url="https://api.whatsapp.com/send?phone=5511914447176&text=(PrincipiaPay) Oi, Principia! Pode me ajudar?"
      isVariant={true}
      external={true}
    />
    <RowContact
      text="pri@principia.net"
      source="/assets/svg/envelopeSimple.svg"
      alt="Email"
      url="mailto:pri@principia.net"
      isVariant={true}
      external={true}
    />
    <RowContact
      text={
        <>
          Av. Dr. Cardoso de Melo, 1340 cj. 11 <br />
          Vila Olímpia, São Paulo/SP – 04548-004
        </>
      }
      source="/assets/svg/gps-icon.svg"
      alt="Local"
      url="https://goo.gl/maps/awmVruyXQdCmNKHz5"
      external={true}
    />
  </S.CartFooterInformationWrapper>
)

export default CartFooterInformation
