import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface IModalContainer {
  width?: string
  bgColor?: string
  borderRadius?: string
}

export const ModalWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
`

export const ModalBackDrop = styled.div<{ backdropColor?: string }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99998;
  background-color: ${({ backdropColor }) => backdropColor || 'rgba(0, 0, 0, 0.3)'};
`

export const ModalContainer = styled.div<IModalContainer>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 48px);
  max-height: calc(100vh - 48px);
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 99999;
  padding: 1rem;
  width: ${({ width }) => width || '500px'};
  background-color: ${({ bgColor }) => bgColor || theme.colors.white100};
  border-radius: ${({ borderRadius }) => borderRadius || '4px'};
`
