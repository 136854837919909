import { theme } from '@provi/provi-components'

const FailureIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M20.5 40.5C31.5457 40.5 40.5 31.5457 40.5 20.5C40.5 9.45431 31.5457 0.5 20.5 0.5C9.45431 0.5 0.5 9.45431 0.5 20.5C0.5 31.5457 9.45431 40.5 20.5 40.5Z"
        fill={theme.colors.white100}
      />
      <ellipse cx="20.5" cy="32.5" rx="2" ry="2" fill={theme.colors.pink900} />
      <line x1="20.5" y1="11.5" x2="20.5" y2="24.5" stroke={theme.colors.pink900} strokeWidth="4" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="40" fill={theme.colors.white100} transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default FailureIcon
