import styled from 'styled-components'
import { loadingCSS } from '~/styles/global'

interface ILoadingInput {
  inputWidth?: string
}

export const InputLoadingContainer = styled.div<ILoadingInput>`
  display: flex;
  flex-direction: column;
  width: ${({ inputWidth }) => inputWidth || '100%'};

  * {
    ${loadingCSS}
  }
`

export const LoadingTitle = styled.div`
  width: 60%;
  height: 24px;
  margin-bottom: 8px;
`

export const LoadingInput = styled.div`
  width: 100%;
  height: 48px;
`
