import styled, { css } from 'styled-components'
import { Body2, H4 } from '~/styles/global'
import { theme } from '@provi/provi-components'

export const ReviewFlowWrapper = styled.div`
  max-width: 368px;
  margin: 23px 24px 24px;

  ${theme.breakpoints.down('md')} {
    margin: 0 24px 24px;
  }
`

export const ReviewFlowContent = styled.div`
  min-height: 224px;

  > div:last-child {
    margin-top: 28px;
  }
`

export const ReviewFlowTitle = styled(H4)``

export const ReviewFlowSubTitle = styled(Body2)<{ marginVariant?: boolean }>`
  margin: 23px 0 30px;

  ${({ marginVariant }) =>
    marginVariant &&
    css`
      margin-bottom: 54px;

      ${theme.breakpoints.down('md')} {
        margin-bottom: 24px;
      }
    `}
`

export const InformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 16px;

  svg {
    width: 21.6px;
    min-width: 21.6px;
    margin-left: 8px;
  }

  ${theme.breakpoints.down('md')} {
    svg {
      width: 18px;
      min-width: 18px;
    }
  }
`

export const Category = styled(Body2)`
  color: ${theme.colors.bluePrimary};
  font-weight: 600;
  letter-spacing: 0.005em;
`

export const Description = styled(Body2)``

export const InformationWrapperLoading = styled.div`
  > div:last-child {
    margin-top: 50px;
  }
`
