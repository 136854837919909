import { useEffect, useState } from 'react'

export const useInIframe = () => {
  const [isInIframe, setIsInIframe] = useState(false)

  useEffect(() => {
    try {
      setIsInIframe(window.self !== window.top)
    } catch (e) {
      setIsInIframe(true)
    }
  }, [])

  return { isInIframe }
}
