import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2, Caption } from '~/styles/global'

export const SummaryWrapper = styled.div`
  height: 100%;
  border-radius: 4px;
  border-bottom: none;
  padding: 16px 15px 12px;

  :not(:first-child):not(:last-child) {
    padding-top: 0;
  }
`

export const CaptionTitle = styled(Body2)`
  color: ${theme.colors.bluePrimary};
`

export const CaptionCourseName = styled(Caption)``

export const CaptionPrice = styled(Body2)``
