import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Caption } from '~/styles/global'

export const EmailTypoWrapper = styled.div``

export const EmailTypoText = styled(Caption)`
  white-space: nowrap;

  ${theme.breakpoints.down('md')} {
    white-space: normal;
    word-wrap: break-word;
  }
`

export const EmailTypoButton = styled(Caption)`
  cursor: pointer;
  color: ${theme.colors.bluePrimary};
  margin-left: 4px;

  :hover {
    color: ${theme.colors.bluePrimaryDark};
  }
`
