import { ButtonV3 as Button } from '@provi/provi-components'
import { useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { InputInvoiceCode } from '~/components/atoms'
import { CopyToClipboardButton } from '~/components/molecules'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import useCountDown from '~/hooks/useCountdown'
import { useIFrameParams } from '~/hooks/useIFrameParams'
import { useInIframe } from '~/hooks/useInIframe'
import { readCheckoutSuccess } from '~/services/api'
import * as S from './style'

interface ICheckoutMessage {
  isWithButton?: boolean
  isWithIcon?: boolean
  buttonText?: string | JSX.Element
  isTicket?: boolean
  handleTryAgain?: () => void
  hrefURL?: string
  text: string | JSX.Element | JSX.Element[]
  isPix?: boolean
  shouldTalkToUs?: boolean
}

const msToMinutesSeconds = (ms: number) => {
  const totalSeconds = Math.floor(ms / 1000)

  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

const timer = 15 * 60 * 1000
const thirtySecondsInMs = 30 * 1000

const CheckoutMessage = ({
  text,
  isWithButton,
  isWithIcon,
  buttonText,
  isTicket,
  hrefURL,
  handleTryAgain,
  isPix,
  shouldTalkToUs,
}: ICheckoutMessage) => {
  const { isInIframe } = useInIframe()
  const { iframeParams } = useIFrameParams()
  const [isLoading, setIsLoading] = useState(false)
  const { step, isSendingData, checkoutSuccessObject, setCheckoutSuccessObject } = useContext(CheckoutContext)

  const { timeLeft, actions } = useCountDown(timer, 1000)
  const { start } = actions

  async function getCheckoutSuccess() {
    try {
      const result = await readCheckoutSuccess()

      if (typeof result != 'string') {
        setCheckoutSuccessObject(result.response)
      }
    } catch (err) {
      console.log({ err })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (step === 'checkoutSuccess') {
      setIsLoading(true)

      getCheckoutSuccess()
      start()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, setIsLoading, setCheckoutSuccessObject])

  useEffect(() => {
    if (isPix && checkoutSuccessObject?.paymentInfo?.status !== 'paid' && timeLeft % thirtySecondsInMs === 0) {
      getCheckoutSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft])

  const formattedTimeLeft = useMemo(() => {
    return msToMinutesSeconds(timeLeft)
  }, [timeLeft])

  const isCheckoutSuccess =
    checkoutSuccessObject?.paymentInfo?.status === 'paid' ||
    (checkoutSuccessObject?.paymentInfo?.status === 'pending' && !isPix && !isTicket)

  useEffect(() => {
    if (isCheckoutSuccess && isInIframe && iframeParams?.redirectUrl) {
      toast.success('Sucesso! Você será redirecionado em 5 segundos.')

      setTimeout(() => {
        window.top.location.href = decodeURIComponent(iframeParams.redirectUrl)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckoutSuccess, isInIframe])

  const renderFormBasicInfoLoading = useMemo(() => {
    return (
      <S.FeedbackBody>
        <S.FeedbackTitle>{text}</S.FeedbackTitle>

        {isTicket || isPix ? (
          <>
            {isPix ? <S.LoadingQrCodeImage /> : null}
            <S.LoadingInvoiceCode />
            <S.CheckoutMessageLoadingButton />
            <S.CheckoutMessageLoadingButton />
          </>
        ) : (
          Array.from({ length: 3 }).map((_, index) => <S.LoadingInvoiceCode key={index} />)
        )}
      </S.FeedbackBody>
    )
  }, [text, isTicket, isPix])

  const renderPaidStatus = useMemo(() => {
    return (
      <S.FeedbackBody>
        <S.FeedbackTitle>O pagamento foi confirmado! Você receberá um e-mail com os detalhes da sua compra.</S.FeedbackTitle>

        <S.BodyTicket>As instruções de acesso ao curso serão enviadas pela escola diretamente para você.</S.BodyTicket>
      </S.FeedbackBody>
    )
  }, [])

  if ((isLoading || !text) && process.env.NODE_ENV !== 'test') {
    return renderFormBasicInfoLoading
  }

  if (checkoutSuccessObject?.paymentInfo?.status === 'paid') {
    return renderPaidStatus
  }

  return (
    <S.FeedbackBody>
      <S.FeedbackTitle>{text}</S.FeedbackTitle>

      {isTicket && (
        <>
          <InputInvoiceCode width="380px" value={checkoutSuccessObject?.paymentInfo.invoiceCode} />
          <CopyToClipboardButton text="Copiar código" invoiceCode={checkoutSuccessObject?.paymentInfo.invoiceCode} />
          <S.BodyTicket>
            Você também pode acessar o boleto, escolher salvar ou imprimir para pagá-lo em uma agência bancária ou lotérica.
          </S.BodyTicket>
        </>
      )}

      {isPix && (
        <>
          <S.QrCodeImage
            alt="QR Code"
            src={checkoutSuccessObject?.paymentInfo.pixQrCode}
            width="368px"
            height="368px"
            placeholder="blur"
          />

          {/* we should probably ab test this but w/e */}
          {timer !== -1 ? (
            <S.FeedbackTitle>
              Você tem{' '}
              <strong
                style={{
                  fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace',
                }}
              >
                {formattedTimeLeft}
              </strong>{' '}
              para realizar o pagamento.
            </S.FeedbackTitle>
          ) : null}

          <InputInvoiceCode width="380px" value={checkoutSuccessObject?.paymentInfo.pixQrCodeText} noMask />
          <CopyToClipboardButton text="PIX Copia e Cola" invoiceCode={checkoutSuccessObject?.paymentInfo.pixQrCodeText} />
          <S.BodyTicket>
            Você pode pagar o PIX pelo aplicativo do seu banco ou pelo internet banking. Basta copiar e colar o código acima, ou
            escanear o QR Code com a câmera do seu celular.
          </S.BodyTicket>
        </>
      )}

      {shouldTalkToUs ? (
        <S.FeedbackTitle>
          Caso tenha ficado com alguma duvida, fique à vontade para entrar em contato com o nosso atendimento.
        </S.FeedbackTitle>
      ) : null}

      {(isWithButton || isTicket) && (
        <S.ButtonWrapper>
          <Button
            as="button"
            text={buttonText}
            icon={isWithIcon}
            onClick={handleTryAgain}
            isLoading={isSendingData}
            buttonProps={{
              href: hrefURL || checkoutSuccessObject?.paymentInfo.invoiceUrl || '',
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
        </S.ButtonWrapper>
      )}
    </S.FeedbackBody>
  )
}
export default CheckoutMessage
