import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, Body2, Overline, loadingCSS } from '~/styles/global'

interface ICreditCardHolderData {
  shouldBeAbleToEditInputs: boolean
}

interface IButtonWrapper {
  shouldShowOnMobile?: boolean
}

export const CreditCardFormWrapper = styled.div<{ isVariant: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 24px;

  margin-top: 62px;
  margin-bottom: 30px;
  max-width: 760px;
  width: 100%;

  .select-menu {
    z-index: 0;

    label {
      cursor: pointer;

      span {
        margin-left: 4px;
        font-size: 12.8px;
        line-height: 24px;
      }
    }
  }

  ${({ isVariant }) =>
    isVariant &&
    css`
      width: 100%;
      max-width: 368px;
      grid-template-columns: 1fr;
      grid-gap: 25px;

      margin-top: 24px;

      .select-menu {
        label > span {
          font-size: 11px;
          line-height: 16px;
        }
      }
    `}

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    grid-gap: 25px;

    margin-top: 24px;

    .select-menu {
      label > span {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
`

export const CreditCardSmallInputs = styled.div<{ isVariant: boolean }>`
  display: flex;
  flex: 1 1;
  gap: 24px;
  width: 100%;

  ${({ isVariant }) =>
    isVariant &&
    css`
      gap: 16px;

      label {
        height: 100%;
        justify-content: space-between;
      }
    `}

  ${theme.breakpoints.down('md')} {
    gap: 16px;

    label {
      height: 100%;
      justify-content: space-between;
    }
  }
`

export const CreditCardHolderData = styled.div<ICreditCardHolderData>`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
  transition: all 0.2s ease;

  button {
    margin-top: 19px;
  }

  ${({ shouldBeAbleToEditInputs }) =>
    shouldBeAbleToEditInputs &&
    css`
      margin-top: 20px;

      ${theme.breakpoints.down('md')} {
        margin-top: 0px;
      }
    `}

  ${theme.breakpoints.down('md')} {
    grid-column: 1 / span 1;
  }
`

export const CreditCardHolderTitle = styled(Body1)`
  margin-bottom: 15px;
`

export const CreditCardHolderInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CreditCardHolderContainer = styled.span``

export const CreditCardHolderField = styled(Overline)`
  font-weight: 600;
  text-transform: unset;
`

export const CreditCardHolderValue = styled(Body2)``

export const CreditCardHolderInformationInputs = styled.div`
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 24px;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }
`

export const ButtonWrapper = styled.div<IButtonWrapper>`
  ${theme.breakpoints.down('md')} {
    display: ${({ shouldShowOnMobile }) => (shouldShowOnMobile ? 'flex' : 'none')};
  }
`

export const CreditCardLoadingTitle = styled.div`
  height: 28px;
  width: 50%;
  margin-bottom: 16px;
  ${loadingCSS}
`

export const CreditCardLoadingText = styled.div`
  height: 24px;
  width: 40%;
  ${loadingCSS}
`

export const CreditCardLoadingButton = styled.div`
  height: 24px;
  width: 184px;
  margin-top: 16px;

  ${loadingCSS}
`

export const SelectorWrapper = styled.div``

export const LabelSelector = styled(Body2)`
  font-weight: 600;
  margin-bottom: 8px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 13.2px;
    line-height: 20px;
  }
`

export const SelectContent = styled.select`
  width: 100%;
  height: 48px;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  background: ${theme.colors.white100};
  font-family: 'Montserrat', sans-serif;
  border-radius: 3px;
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;

  :focus,
  :focus-visible,
  :focus-within {
    outline: 2px auto ${theme.colors.bluePrimary};
  }

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
  }
`
