import { useState } from 'react'

export const useInputFieldEmail = () => {
  const [suggestionEmail, setSuggestionEmail] = useState('')

  return {
    suggestionEmail,
    setSuggestionEmail,
  }
}
