import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Body2 } from '~/styles/global'

interface ICartItemName {
  isReadOnly?: boolean
}

export const CartItemWrapper = styled.li`
  list-style: none;
`

export const CartItemNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`

export const CartItemName = styled(Body2)<ICartItemName>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: ${theme.colors.bluePrimary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
    `}

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    letter-spacing: 0.005em;
  }
`

export const CartClassName = styled.span`
  margin-top: 3px;
  display: block;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
  }
`

export const CartItemPriceWrapper = styled.div`
  margin-top: 8px;
`

export const CartItemPrice = styled.span`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${theme.colors.black100};

  ${theme.breakpoints.down('md')} {
    line-height: 16px;
    letter-spacing: 0.0075em;
  }
`
