import { useCallback, useContext, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { generateOTP } from '~/services/api'
import { IGenerateOTP } from 'types'

export const useVerification = () => {
  const { verificationInfo, isSendingData } = useContext(CheckoutContext)
  const [selectedMethod, setSelectedMethod] = useState<IGenerateOTP['validationType']>('email')

  const proceed = useCallback(async () => {
    await generateOTP({
      validationType: selectedMethod,
    })
  }, [selectedMethod])

  return {
    verificationInfo,
    proceed,
    selectedMethod,
    setSelectedMethod,
    isSendingData,
  }
}
