import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readPreApproved, writePreApproved } from '~/services/api'
import { IGuarantorData } from '~/types/index'

export const usePreApprovedContent = () => {
  const { isLoadingData, setIsLoadingData, step, isSendingData } = useContext(CheckoutContext)
  const [infoGuarantor, setInfoGuarantor] = useState<IGuarantorData | null>()

  useEffect(() => {
    async function getInfoGuarantor() {
      setIsLoadingData(true)
      const result = await readPreApproved()

      if (typeof result != 'string') {
        setInfoGuarantor(result.response.guarantorData)
      }
    }

    getInfoGuarantor().finally(() => setIsLoadingData(false))
  }, [setIsLoadingData])

  const handleSubmitButton = useCallback(async () => {
    await writePreApproved({
      currentScreen: step,
    })
  }, [step])

  return {
    infoGuarantor,
    isLoadingData,
    handleSubmitButton,
    isSendingData,
  }
}
