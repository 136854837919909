import styled from 'styled-components'
import { Body1, Body2, loadingCSS } from '~/styles/global'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  margin: 35px 24px 24px;

  ${theme.breakpoints.down('md')} {
    margin-top: 14px;

    div:last-child {
      min-width: 100%;
    }
  }
`

export const GuarantorChecklistContent = styled.div`
  max-width: 368px;
  margin-bottom: 32px;
`

export const TitleInformation = styled(Body1)``

export const ChecklistOptionsContent = styled.div`
  display: flex;
  align-items: center;

  > svg {
    width: 21.6px;
    margin-right: 8px;
  }

  ${theme.breakpoints.down('md')} {
    svg {
      width: 18px;
      min-width: 18px;
    }
  }
`

export const Option = styled(Body2)``

export const ChecklistButtonLoading = styled.div`
  height: 40px;
  width: 25%;
  margin: 8px 0;

  ${loadingCSS}
`
