import { memo, useEffect, useMemo, useRef } from 'react'
import { PaymentMethodCard } from '~/components/molecules'
import { usePaymentMethodCards } from './hooks'
import * as S from './style'
import { IPaymentForms } from 'types'
import 'react-multi-carousel/lib/styles.css'

interface IPaymentMethodCards {
  hasInstability?: boolean
}

const PaymentMethodCards = ({ hasInstability }: IPaymentMethodCards) => {
  const { isPaymentInfoLoading, paymentInformationData, handlePaymentMethod, chosenPaymentMethod } = usePaymentMethodCards()

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef?.current) {
      if (chosenPaymentMethod === 'CourseFinancing') {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth
      }

      if (chosenPaymentMethod === 'CreditCard') {
        containerRef.current.scrollLeft = 0
      }
    }
  }, [chosenPaymentMethod])

  const renderPaymentMethodCards = useMemo(() => {
    const isPixEnabled = paymentInformationData?.response?.paymentMethods?.includes('Pix')

    const allPaymentMethods: IPaymentForms[] = isPixEnabled
      ? ['CreditCard', 'Pix', 'CourseFinancing', 'Boleto']
      : ['CreditCard', 'Boleto', 'CourseFinancing']

    const haveReason = paymentInformationData?.response?.reason?.length

    const courseFinancingFragment = haveReason && (
      <PaymentMethodCard
        paymentMethod={'CourseFinancing'}
        isDisabled={true}
        isLoading={false}
        isActive={false}
        onClick={() => {}}
      />
    )

    return (
      <>
        {allPaymentMethods.map((currentPaymentMethod) => {
          const isCreditCardAndBoletoDisabled =
            (hasInstability && currentPaymentMethod === 'Boleto') || (hasInstability && currentPaymentMethod === 'CreditCard')
          const paymentMethodCardDisabled =
            isCreditCardAndBoletoDisabled || !paymentInformationData?.response?.paymentMethods?.includes(currentPaymentMethod)

          return (
            !paymentMethodCardDisabled && (
              <PaymentMethodCard
                key={currentPaymentMethod}
                paymentMethod={currentPaymentMethod}
                isActive={chosenPaymentMethod === currentPaymentMethod}
                isDisabled={false}
                isLoading={isPaymentInfoLoading}
                onClick={() => {
                  handlePaymentMethod(currentPaymentMethod)
                }}
              />
            )
          )
        })}
        {courseFinancingFragment}
      </>
    )
  }, [chosenPaymentMethod, paymentInformationData, handlePaymentMethod, isPaymentInfoLoading, hasInstability])

  return <S.PaymentMethodCardsWrapper ref={containerRef}>{renderPaymentMethodCards}</S.PaymentMethodCardsWrapper>
}

export default memo(PaymentMethodCards)
