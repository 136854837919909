import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Wrapper = styled.svg`
  color: ${theme.colors.white100};
  cursor: pointer;

  :hover {
    path {
      fill: ${theme.colors.bluePrimaryLight};
    }
  }

  :active {
    path {
      fill: ${theme.colors.blueSecundaryLight};
    }
  }
`
