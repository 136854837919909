/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useFormik } from 'formik'
import moment from 'moment'
import { ChangeEvent, Dispatch, FormEvent, KeyboardEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ICreditCardPayment, IPaymentCondition, ITemporaryCreditCardData } from 'types'
import * as Yup from 'yup'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { errorMessages, requiredErrorMessages } from '~/enums/defaultMessages'
import { useGeolocation } from '~/hooks/index'
import { useBarteScript } from '~/hooks/useBarteScript'
import { createPayment } from '~/services/api'
import {
  addMask,
  hashCard,
  isPhoneValid,
  parsePrice,
  removeMask,
  validateBirthDate,
  validateCPF,
  validateCardNumber,
  validateExpireDate,
  validateFullName,
} from '~/utils/index'

export interface IPartnerConditions {
  label: string | JSX.Element
  labelInMobile?: string
  value: number
  target?: {
    value: string
  }
}

interface IUseCreditCardForm {
  temporaryCreditCardData: ITemporaryCreditCardData | null
  setTemporaryCreditCardData: Dispatch<ITemporaryCreditCardData | null>
  imTheCardHolder: boolean
  setImTheCardHolder: Dispatch<boolean>
  hideSubmit?: boolean
}

export const useCreditCardForm = ({
  temporaryCreditCardData,
  setTemporaryCreditCardData,
  imTheCardHolder,
  setImTheCardHolder,
  hideSubmit,
}: IUseCreditCardForm) => {
  const { attemptReference, idempotencyKey } = useBarteScript({})

  const { paymentInformationData, chosenPaymentMethod, isSendingData, inPageError } = useContext(CheckoutContext)
  const [isCreditCardLoading, setCreditCardLoading] = useState(true)
  const { latitude, longitude, watchPosition } = useGeolocation()
  const { CPF, birthDate: birth, phone } = paymentInformationData?.response?.client || {}
  const birthDate = birth ? moment(birth).format('DD/MM/YYYY') : ''
  const partnerConditionId = paymentInformationData?.response?.CreditCard?.paymentConditions?.[0]?.PartnerConditionId
  const installmentRestrictionReason = paymentInformationData?.response?.CreditCard?.installmentRestrictionReason || ''
  const isUserIPFromDifferentCountry = paymentInformationData?.response?.isUserIPFromDifferentCountry || false

  const initialValues = {
    partnerConditionId: '',
    cardCountry: 'br',
    cardNumber: '',
    cardExpireDate: '',
    cardCvv: '',
    cardName: '',
    holderCPF: '',
    birthDate: '',
    phone: '',
  }

  const proceed = useCallback(
    async (creditCardValues: typeof initialValues) => {
      watchPosition()

      const cardHash = async (): Promise<string> => {
        return await hashCard({
          cardNumber: temporaryCreditCardData.cardNumber,
          cvv: temporaryCreditCardData.cardCvv,
          expirationDate: temporaryCreditCardData.cardExpireDate,
          holderName: temporaryCreditCardData.cardName,
        })
      }

      const userAgent = navigator ? navigator.userAgent : ''

      const params: ICreditCardPayment = {
        currentScreen: 'paymentSelection',
        data: {
          paymentMethod: 'CreditCard',
          PartnerConditionId: Number(creditCardValues.partnerConditionId),
          creditCardInformation: {
            hash: await cardHash(),
            holder: {
              fullName: creditCardValues.cardName,
              birthDate: creditCardValues.birthDate,
              CPF: creditCardValues.holderCPF,
              phone: removeMask(creditCardValues.phone),
            },
          },
          device: {
            geolocation: {
              latitude,
              longitude,
            },
            userAgent: userAgent,
          },
          attemptReference,
          idempotencyKey,
        },
      }
      await createPayment(params)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [latitude, longitude, watchPosition],
  )

  const getPartnerConditionLabel = (partnerCondition: IPaymentCondition) => (
    <>
      <label>
        {partnerCondition.installmentsToApply}x de {parsePrice(partnerCondition.pmt)}
        {Number(partnerCondition.monthlyInterest) ? <span>*</span> : ' - sem juros'}
      </label>
    </>
  )

  const getPartnerConditionLabelInMobile = (partnerCondition: IPaymentCondition) => `
    ${partnerCondition.installmentsToApply}x de ${parsePrice(partnerCondition.pmt, true)}${
    Number(partnerCondition.monthlyInterest) ? '*' : ' - sem juros'
  }`

  const hasAnyPartnerConditionWithInterest = useMemo(
    () =>
      paymentInformationData?.response?.CreditCard?.paymentConditions?.some(
        (partnerCondition) => Number(partnerCondition.monthlyInterest) > 0,
      ),
    [paymentInformationData?.response?.CreditCard?.paymentConditions],
  )

  Yup.setLocale({
    string: {
      min: 'Deve ter mais de ${min} números',
    },
  })

  const { submitForm, values, errors, setFieldValue, validateForm, touched, setFieldTouched, dirty, isValid, isSubmitting } =
    useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: initialValues,
      validationSchema: Yup.object({
        partnerConditionId: Yup.string().required(errorMessages.requiredField),
        cardCountry: Yup.string().optional(),
        cardNumber: Yup.string().required(requiredErrorMessages.cardNumber),
        cardExpireDate: Yup.string().required(requiredErrorMessages.cardExpireDate),
        cardCvv: Yup.string().required(requiredErrorMessages.cardCvv).min(3, requiredErrorMessages.cardCvv),
        cardName: imTheCardHolder === true ? Yup.string().notRequired() : Yup.string().required(requiredErrorMessages.cardName),
        holderCPF:
          imTheCardHolder === true
            ? Yup.string().notRequired()
            : Yup.string().required(requiredErrorMessages.holderCpf).min(14, errorMessages.cpf),
        birthDate:
          imTheCardHolder === true || hideSubmit
            ? Yup.string().notRequired()
            : Yup.string().required(requiredErrorMessages.holderBirthDate),
        phone:
          imTheCardHolder === true || hideSubmit
            ? Yup.string().notRequired()
            : Yup.string().required(requiredErrorMessages.phone),
      }),
      onSubmit: proceed,

      validate: (fields) => {
        const fieldsArray = Object.keys(fields)
        return fieldsArray.reduce((prev, cur) => {
          if (cur === 'cardNumber' && fields[cur] && !validateCardNumber(fields[cur])) {
            return {
              ...prev,
              [cur]: errorMessages.cardNumber,
            }
          }
          if (cur === 'cardName' && fields[cur] && !validateFullName(fields[cur])) {
            return {
              ...prev,
              [cur]: errorMessages.cardName,
            }
          }

          if (cur === 'cardExpireDate' && fields[cur] && !validateExpireDate(fields[cur])) {
            return {
              ...prev,
              [cur]: requiredErrorMessages.cardExpireDate,
            }
          }

          if (cur === 'birthDate' && fields[cur] && !validateBirthDate(fields[cur])) {
            const wrongFormat = fields[cur].length < 10

            if (moment().diff(moment(fields[cur], 'DD/MM/YYYY'), 'years') <= 16 && !wrongFormat) {
              return {
                ...prev,
                [cur]: 'Você deve ter mais de 16 anos de idade',
              }
            }

            return {
              ...prev,
              [cur]: wrongFormat ? requiredErrorMessages.wrongFormatBirthDate : errorMessages.birthDate,
            }
          }

          if (
            cur === 'holderCPF' &&
            !imTheCardHolder &&
            fields[cur] &&
            removeMask(fields[cur]).length === 11 &&
            !validateCPF(fields[cur])
          ) {
            return {
              ...prev,
              [cur]: errorMessages.invalidCPF,
            }
          }

          if (cur === 'phone' && fields[cur] && !isPhoneValid(fields[cur])) {
            if (fields[cur].length === 1) {
              return {
                ...prev,
                [cur]: requiredErrorMessages.phone,
              }
            }
            return {
              ...prev,
              [cur]: errorMessages.phone,
            }
          }

          return { ...prev }
        }, {})
      },
    })

  const partnerConditions: IPartnerConditions[] = useMemo(() => {
    if (values.cardCountry !== 'br') {
      const oneInstallmentPartnerCondition = paymentInformationData?.response?.CreditCard?.paymentConditions.find(
        (partnerCondition) => partnerCondition.installmentsToApply === 1,
      )

      if (oneInstallmentPartnerCondition) {
        return [
          {
            label: getPartnerConditionLabel(oneInstallmentPartnerCondition),
            value: oneInstallmentPartnerCondition.PartnerConditionId,
            labelInMobile: getPartnerConditionLabelInMobile(oneInstallmentPartnerCondition),
          },
        ]
      }
    }

    return (
      paymentInformationData?.response?.CreditCard?.paymentConditions?.map((partnerCondition) => ({
        label: getPartnerConditionLabel(partnerCondition),
        value: partnerCondition.PartnerConditionId,
        labelInMobile: getPartnerConditionLabelInMobile(partnerCondition),
      })) || [{ label: 'Carregando', value: 0 }]
    )
  }, [paymentInformationData?.response?.CreditCard?.paymentConditions, values.cardCountry])

  const selectedOverTwelveInstallments = useMemo(() => {
    const chosenPaymentCondition = paymentInformationData?.response?.CreditCard?.paymentConditions.find((partnerCondition) => {
      return Number(partnerCondition.PartnerConditionId) === Number(values.partnerConditionId)
    })

    return chosenPaymentCondition?.installmentsToApply > 12
  }, [paymentInformationData?.response?.CreditCard?.paymentConditions, values.partnerConditionId])

  useEffect(() => {
    partnerConditionId && setCreditCardLoading(false)
    const CPFWithMask = (CPF && addMask(CPF, '###.###.###-##')) || ''

    setFieldValue('holderCPF', CPFWithMask)
    setFieldValue('birthDate', birthDate)
    setFieldValue('phone', phone)
    setFieldTouched('holderCPF')
    setFieldTouched('phone')
    setFieldTouched('birthDate')

    validateForm()
  }, [CPF, birthDate, phone, partnerConditionId, imTheCardHolder, setFieldTouched, setFieldValue, validateForm])

  const setTextFieldData = useCallback(
    (fieldInputName: string, value) => {
      return (
        setTemporaryCreditCardData({ ...temporaryCreditCardData, [fieldInputName]: value }),
        setFieldValue(fieldInputName, value)
      )
    },
    [temporaryCreditCardData, setTemporaryCreditCardData, setFieldValue],
  )

  const handlePartnerConditions = useCallback(
    (event: IPartnerConditions | ChangeEvent<HTMLSelectElement>) => {
      setFieldTouched('partnerConditionId', true)
      //@ts-ignore
      if (event?.value) {
        //@ts-ignore
        setTextFieldData('partnerConditionId', event?.value?.toString())
      } else {
        setTextFieldData('partnerConditionId', event?.target?.value?.toString())
      }

      validateForm()
    },
    [setTextFieldData, setFieldTouched, validateForm],
  )

  const isFormValid = useCallback(() => {
    return Object.keys(errors).length === 0 && Object.keys(touched).length >= 4
  }, [errors, touched])

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      validateForm()
      !!isFormValid && submitForm()
    },
    [validateForm, isFormValid, submitForm],
  )

  const handleEnterKey = useCallback(
    (event: KeyboardEvent<HTMLFormElement>) => {
      if (event.key === 'Enter') {
        !isSubmitting && handleSubmit(event)
      }
    },
    [handleSubmit, isSubmitting],
  )

  const handleCardHolder = useCallback(() => {
    setImTheCardHolder(!imTheCardHolder)

    validateForm()
  }, [imTheCardHolder, setImTheCardHolder, validateForm])

  const getObjectFromPartnerConditionsId = partnerConditions.find(
    (partnerCondition) => partnerCondition.value == temporaryCreditCardData?.partnerConditionId,
  )

  useEffect(() => {
    temporaryCreditCardData &&
      Object.entries(temporaryCreditCardData).map(
        ([label, value]) => value && setFieldValue(label, value) && setFieldTouched(label),
      )

    temporaryCreditCardData && validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  useEffect(() => {
    // auto select the 12x partner condition or the last one
    if (partnerConditions?.length > 0 && !values?.partnerConditionId) {
      setFieldTouched('partnerConditionId', true)
      const bestPartnerCondition = partnerConditions.find((partnerCondition) =>
        partnerCondition?.labelInMobile?.includes('12x'),
      )
      if (bestPartnerCondition?.value) {
        setTextFieldData('partnerConditionId', bestPartnerCondition.value.toString())
      } else {
        const lastPartnerConditionId = partnerConditions[partnerConditions.length - 1].value.toString()
        setTextFieldData('partnerConditionId', lastPartnerConditionId)
      }

      validateForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerConditions])

  useEffect(() => {
    if (isUserIPFromDifferentCountry) {
      setFieldValue('cardCountry', 'intl')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserIPFromDifferentCountry])

  return {
    isCreditCardLoading,
    chosenPaymentMethod,
    partnerConditions,
    handlePartnerConditions,
    handleCardHolder,
    cardHolderData: {
      CPF,
      birthDate,
      phone,
    },
    values,
    errors,
    touched,
    dirty,
    isValid,
    handleSubmit,
    setFieldValue,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    getObjectFromPartnerConditionsId,
    setTextFieldData,
    isSendingData,
    hasAnyPartnerConditionWithInterest,
    inPageError,
    installmentRestrictionReason,
    selectedOverTwelveInstallments,
  }
}
