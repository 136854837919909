import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Body1 } from '~/styles/global'

interface ICardTotalItemPrice {
  hasDiscount?: boolean
  isVariant?: boolean
}

export const CardCourseTotalPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const CardTotalItemPrice = styled.span<ICardTotalItemPrice>`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${theme.colors.greyPrimary};

  ${({ isVariant }) =>
    isVariant &&
    css`
      color: ${theme.colors.black100};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0075em;
    `}

  ${({ hasDiscount }) =>
    hasDiscount &&
    css`
      color: ${theme.colors.greyPrimary};
      text-decoration-line: line-through;
    `};

  ${theme.breakpoints.down('md')} {
    line-height: 16px;
    letter-spacing: 0.0075em;
  }
`

export const CardFullPrice = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardTotalPriceValue = styled(Body1)`
  color: ${theme.colors.bluePrimary};
`
