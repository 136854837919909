import { useMemo } from 'react'
import { ButtonV3 as Button } from '@provi/provi-components'
import { ButtonChoice, ChecklistOption, UploadIcon } from '~/components/atoms'
import { checklistInformationsCNH, checklistInformationsRG } from './listChecklistInformations'
import { useWindowSize } from '~/utils/useWindowSize'
import { usePreDocumentContent } from './hooks'
import * as S from './style'
import { WebviewModal } from '~/components/molecules/WebviewModal'

const PreDocumentContent = () => {
  const {
    documentOptions,
    selectedDocument,
    handleSelectedDocumentType,
    isLoadingData,
    handleSendPhoto,
    handleOpenCamera,
    isSendingData,
  } = usePreDocumentContent()

  const { width } = useWindowSize()

  const isDesktop = useMemo(() => (width && width > 800) || false, [width])

  const renderDocumentOptions = useMemo(() => {
    if ((isLoadingData || !documentOptions) && process.env.NODE_ENV !== 'test') {
      return (
        <>
          <S.DocInputLoading />
          <S.DocInputLoading />
        </>
      )
    }

    return documentOptions?.map((doc) => (
      <ButtonChoice key={doc} text={doc} isActive={selectedDocument === doc} onClick={() => handleSelectedDocumentType(doc)} />
    ))
  }, [documentOptions, selectedDocument, isLoadingData, handleSelectedDocumentType])

  const renderListOfInformations = useMemo(() => {
    if (selectedDocument === 'RG') {
      return checklistInformationsRG?.map((option: string) => <ChecklistOption key={option} text={option} isVariant />)
    }

    return checklistInformationsCNH?.map((option: string) => <ChecklistOption key={option} text={option} isVariant />)
  }, [selectedDocument])

  const buttonCameraWideVersion = useMemo(() => {
    if (!selectedDocument) {
      return null
    }

    return isDesktop ? (
      <>
        <Button
          onClick={handleOpenCamera}
          text="Abrir câmera"
          width="fit-content"
          color="white"
          disabled={!selectedDocument}
          isLoading={isSendingData}
        />
        <Button
          text="Enviar foto"
          onClick={handleSendPhoto}
          disabled={!selectedDocument}
          isLoading={isSendingData}
          buttonProps={{
            endIcon: <UploadIcon />,
          }}
        />
      </>
    ) : (
      <Button
        text="Tirar foto"
        type="submit"
        onClick={handleOpenCamera}
        disabled={!selectedDocument}
        icon
        isLoading={isSendingData}
      />
    )
  }, [selectedDocument, handleSendPhoto, handleOpenCamera, isDesktop, isSendingData])

  return (
    <S.PreDocumentContent>
      <WebviewModal />
      <S.MainPreDocumentContent>
        <S.PreDocumentTitle>Documento de identidade</S.PreDocumentTitle>
        <S.PreDocumentSubTitle>Escolha o documento que vai fotografar:</S.PreDocumentSubTitle>

        <S.RadioWrapper>
          <S.RadioContent>{renderDocumentOptions}</S.RadioContent>

          {selectedDocument && (
            <S.InformationsWrapper>
              <S.PreDocumentSubTitle>Siga essas dicas para tirar a foto:</S.PreDocumentSubTitle>

              {renderListOfInformations}
            </S.InformationsWrapper>
          )}
        </S.RadioWrapper>
      </S.MainPreDocumentContent>
      <S.ButtonWrapper>{buttonCameraWideVersion}</S.ButtonWrapper>
    </S.PreDocumentContent>
  )
}

export default PreDocumentContent
