import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

interface IMain {
  isVariant: boolean | null
}

interface IWrapper {
  isInIframe?: boolean
}

interface ISidebar {
  isInIframe?: boolean
}

export const Wrapper = styled.div<IWrapper>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 72px;

  ${({ isInIframe }) =>
    isInIframe &&
    css`
      padding-top: 0;
    `}

  ${theme.breakpoints.down('md')} {
    padding-top: 0;
  }
`

export const Content = styled.div`
  margin: 24px auto;

  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(0, 80px)) 1fr;
  gap: 24px;
  grid-template-rows: auto;
  grid-auto-flow: dense;

  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`

export const Main = styled.main<IMain>`
  background: white;
  border-radius: 16px;
  grid-column: 2 / span 9;
  height: fit-content;
  min-height: 496px;

  .infinite-scroll-component {
    overflow: hidden !important;
  }

  ${theme.breakpoints.down('md')} {
    padding-top: 64px;
    border-radius: 0;
    min-height: 100vh;

    ${({ isVariant }) =>
      isVariant &&
      css`
        padding-top: 0;
      `}
  }
`

export const Sidebar = styled.aside<ISidebar>`
  grid-column: 11 / span 3;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: sticky;
  top: 96px;
  height: fit-content;

  ${({ isInIframe }) =>
    isInIframe &&
    css`
      top: 24px;
    `}

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const Cart = styled.div`
  grid-column: 11 / span 3;
  height: fit-content;
  min-height: 249px;
  border-radius: 16px;
  padding: 16px;
  background: white;
`

export const ButtonWrapper = styled.div`
  grid-column: 11 / span 3;
`

export const MobileButtonWrapper = styled.div`
  display: none;

  ${theme.breakpoints.down('md')} {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 16px 24px;
    z-index: 1;
  }
`

export const ButtonWrapperContinueRequest = styled.div`
  margin: 18px 16px 9px;

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`
