import { theme } from '@provi/provi-components'

const CopyIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0909 14.0908H19V1H5.90916V5.90906M1 5.90918H14.0908V19H1V5.90918Z"
      stroke={theme.colors.bluePrimary}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CopyIcon
