import { useContext } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'

export const useDisclosure = () => {
  const { paymentInformationData } = useContext(CheckoutContext)
  const courseFinancingData = paymentInformationData?.response?.CourseFinancing

  return {
    isFlex: courseFinancingData?.type === 'flex',
  }
}
