import styled, { css } from 'styled-components'

interface IOpenCloseCollapseWrapper {
  isOpen: boolean
}

export const OpenCloseCollapseWrapper = styled.div<IOpenCloseCollapseWrapper>`
  transition: all 0.3s ease-in-out;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: rotate(180deg);
    `}
`
