import { theme } from '@provi/provi-components'

export const WhatsappIcon = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.8393 11.0624C2.09384 9.8057 1.83277 8.32012 2.10512 6.88459C2.37747 5.44906 3.1645 4.16233 4.31843 3.26602C5.47235 2.3697 6.91379 1.92547 8.37207 2.01672C9.83036 2.10798 11.2052 2.72845 12.2384 3.76162C13.2715 4.7948 13.892 6.16961 13.9833 7.62789C14.0745 9.08618 13.6303 10.5276 12.734 11.6815C11.8377 12.8355 10.551 13.6225 9.11544 13.8949C7.67991 14.1672 6.19433 13.9062 4.93766 13.1607L4.93767 13.1607L2.86539 13.7527C2.77965 13.7772 2.68892 13.7784 2.60261 13.756C2.51629 13.7336 2.43752 13.6886 2.37447 13.6255C2.31142 13.5625 2.26638 13.4837 2.24401 13.3974C2.22164 13.3111 2.22277 13.2203 2.24726 13.1346L2.83935 11.0623L2.8393 11.0624Z"
        stroke={theme.colors.white100}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50711 11.5C8.8493 11.5009 8.19778 11.3721 7.58987 11.1208C6.98195 10.8695 6.4296 10.5007 5.96447 10.0355C5.49933 9.5704 5.13054 9.01805 4.87924 8.41013C4.62794 7.80222 4.49907 7.1507 4.50001 6.49289C4.50149 6.02988 4.68657 5.58635 5.01464 5.25962C5.34271 4.93288 5.78698 4.74961 6.25 4.75V4.75C6.32595 4.75 6.40054 4.77008 6.46623 4.8082C6.53191 4.84632 6.58635 4.90112 6.62403 4.96706L7.35472 6.24577C7.39879 6.32288 7.42152 6.41034 7.42057 6.49915C7.41963 6.58797 7.39504 6.67492 7.34935 6.75108L6.76253 7.72911C7.06444 8.3991 7.6009 8.93557 8.27089 9.23747V9.23747L9.24892 8.65066C9.32508 8.60496 9.41203 8.58038 9.50084 8.57943C9.58966 8.57849 9.67712 8.60122 9.75423 8.64528L11.0329 9.37597C11.0989 9.41365 11.1537 9.46809 11.1918 9.53377C11.2299 9.59946 11.25 9.67406 11.25 9.75V9.75C11.2487 10.2125 11.0648 10.6558 10.7385 10.9835C10.4121 11.3112 9.96959 11.4968 9.50711 11.5V11.5Z"
        stroke={theme.colors.white100}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
