import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Body2 } from '~/styles/global'

export const AgreeWithTosWrapper = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  max-width: 370px;

  label {
    margin: ${({ margin }) => margin};
  }

  a {
    color: ${theme.colors.black100};
    text-decoration: underline;
  }
`

export const TextTerms = styled(Body2)<{ disabled: boolean }>`
  color: black;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.colors.greyPrimary};
      letter-spacing: 0;

      > a {
        color: ${theme.colors.greyPrimary};
        font-weight: 600;
      }
    `}
`
