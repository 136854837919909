import styled, { css } from 'styled-components'
import { Body1, Body2, loadingCSS, Button } from '~/styles/global'
import { theme } from '@provi/provi-components'

interface IDateCellsText {
  justifyContent: string
  isSubTitleReview?: boolean
}

interface IDateCellsWrapper {
  shouldShowMore: boolean
  cellsCount: number
}

export const FinancingSummaryWrapper = styled.div`
  padding: 35px 24px 24px;
`

export const SummaryReviewWrapper = styled.div`
  margin: 27px 0 24px;
  max-width: 368px;

  > p:nth-child(1) {
    color: ${theme.colors.bluePrimary};
    font-weight: bold;
    text-transform: uppercase;
  }

  hr {
    height: 1px;
    border: 0px;
    border-top: 2px solid ${theme.colors.bluePrimaryLight};
    margin: 16px 0;
  }
`

export const FinancingSummaryTitle = styled(Body1)``

export const SummaryReviewTitle = styled(Body2)``

export const SummaryReviewContainer = styled.div`
  border-bottom: 2px solid ${theme.colors.bluePrimary};
  margin-bottom: 22px;
`

export const SummaryReviewContent = styled.div`
  > p:nth-child(1) {
    font-weight: 600;
  }

  margin-bottom: 22px;
`

export const SummaryRowLoading = styled.div`
  ${loadingCSS};

  width: 100%;
  max-width: 368px;
  height: 48px;
  border-radius: 8px;
  margin-top: 16px;
`

export const SeeMore = styled(Button)``

export const DateCellsWrapper = styled.div<IDateCellsWrapper>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px 0;

  width: 100%;
  max-height: 122px;
  margin-bottom: 16px;
  overflow: hidden;
  cursor: default;
  transition: max-height 0.3s ease-in-out;

  ${({ shouldShowMore, cellsCount }) =>
    shouldShowMore &&
    cellsCount &&
    css`
      max-height: calc(${cellsCount} * 60px);
    `}

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  ${theme.breakpoints.down('md')} {
    max-height: 106px;

    ${({ shouldShowMore, cellsCount }) =>
      shouldShowMore &&
      cellsCount &&
      css`
        max-height: calc(${cellsCount} * 53px);
      `}
  }
`

export const DateCellsText = styled(Body2)<IDateCellsText>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  border-bottom: 1px solid ${theme.colors.bluePrimaryLight};

  :nth-last-child(-n + 3) {
    border: none;
  }

  :nth-child(2) {
    padding: 0 8px;
  }

  ${({ isSubTitleReview }) =>
    isSubTitleReview &&
    css`
      margin-bottom: 10px;
    `}
`
