import { theme } from '@provi/provi-components'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { IStep } from 'types'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import * as S from './style'

interface IBackButton {
  step: IStep
  disabled?: boolean
}

const BackButton = ({ step, disabled }: IBackButton) => {
  const router = useRouter()
  const { setStep, isInCourseFinancingReviewScreen, chosenPaymentMethod, setIsInCourseFinancingReviewScreen } =
    useContext(CheckoutContext)
  const { partnerSlug } = useContext(ParamsContext)

  const handleClickArrow = (newStep: IStep) => {
    if (chosenPaymentMethod === 'CourseFinancing' && isInCourseFinancingReviewScreen) {
      setIsInCourseFinancingReviewScreen(false)
      return
    }

    setStep(newStep)
  }

  if (step === 'preRegistration') {
    const url = changeRouteKeepParams(router.asPath, `/courses/${partnerSlug}`)

    return (
      <NextLink href={url} passHref>
        <S.BackButtonContainer as="a" title="Voltar">
          <svg width="24" height="24" viewBox="0 0 24 24" fill={'none'} xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21 12.5L3.75 12.5M10 19.5L3 12.5L10 5"
              stroke={theme.colors.black100}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </S.BackButtonContainer>
      </NextLink>
    )
  }

  return (
    <S.BackButtonContainer
      onClick={() => (disabled ? null : handleClickArrow(step))}
      disabled={disabled}
      aria-disabled={disabled}
      title={disabled ? 'Você não pode voltar mais' : 'Voltar'}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21 12.5L3.75 12.5M10 19.5L3 12.5L10 5"
          stroke={disabled ? theme.colors.greyLight : theme.colors.black100}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </S.BackButtonContainer>
  )
}
export default BackButton
