import * as S from './styles'

interface ILabel {
  text: string | JSX.Element | null
  isVariant?: boolean
  isUnavailable?: boolean
}

const Label = ({ text, isVariant, isUnavailable }: ILabel) => {
  return (
    <S.LabelText isVariant={isVariant} isUnavailable={isUnavailable}>
      {text}
    </S.LabelText>
  )
}

export default Label
