import { TextButton } from '@provi/provi-components'
import { useRouter } from 'next/router'
import { memo, useContext, useState } from 'react'
import { OpenCloseCollapse, SearchInput } from '~/components/atoms'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { CoursesContext } from '~/contexts/CoursesContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { useInIframe } from '~/hooks/useInIframe'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import * as S from './style'

interface IHeader {
  partnerName?: string
  partnerLogo?: string
  cart: JSX.Element
  cartItems: number
}

const Header = ({ partnerName, partnerLogo, cartItems, cart }: IHeader) => {
  const router = useRouter()
  const { isInIframe } = useInIframe()
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isCartOpen, setIsCartOpen] = useState(false)
  const { isHeaderCheckoutShown, step, chosenPaymentMethod, isInCourseFinancingReviewScreen } = useContext(CheckoutContext)
  const { unavailableClickedCourseObject } = useContext(CoursesContext)
  const { partnerSlug, productType } = useContext(ParamsContext)

  const isProviPay = productType === 'ProviPay'

  const isInCoursesScreen = router.route === '/courses/[slug]'
  const shouldHideHeader =
    !isHeaderCheckoutShown ||
    (step === 'paymentSelection' && chosenPaymentMethod && isInCourseFinancingReviewScreen) ||
    !isProviPay

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <S.HeaderWrapper isVariant={shouldHideHeader} isInIFrame={isInIframe}>
      <S.Content>
        <S.TitleWrapper isSearchOpen={isSearchOpen}>
          {!isInIframe && (
            <S.DesktopHeader isInCoursesScreen={isInCoursesScreen}>
              {partnerName && (
                <>
                  <S.Icon src={partnerLogo || '/assets/svg/default-partner-logo.svg'} alt={partnerName} />
                  <S.Title>{partnerName}</S.Title>
                </>
              )}
            </S.DesktopHeader>
          )}

          <S.InvisibleClickable
            onClick={() => (shouldHideHeader ? setIsCartOpen(!isCartOpen) : null)}
            aria-label={isCartOpen ? 'Fechar carrinho' : 'Abrir carrinho'}
            area-expanded={isCartOpen}
          />
          <S.MobileHeader isInCoursesScreen={isInCoursesScreen} onClick={() => setIsCartOpen(!isCartOpen)}>
            <OpenCloseCollapse isOpen={isCartOpen} />
            <S.CardHeader>Resumo do pedido</S.CardHeader>
          </S.MobileHeader>
        </S.TitleWrapper>

        {!unavailableClickedCourseObject && (
          <S.SearchWrapper>
            {isInCoursesScreen && !isInIframe && (
              <S.ButtonWrapper>
                <TextButton
                  text="Já comecei uma compra"
                  icon
                  onClick={() => router.push(changeRouteKeepParams(router.asPath, `/checkout/${partnerSlug}`))}
                />
              </S.ButtonWrapper>
            )}
            <SearchInput
              isSearchOpen={isSearchOpen}
              setIsSearchOpen={setIsSearchOpen}
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              cartItems={cartItems}
              cart={cart}
            />
          </S.SearchWrapper>
        )}
      </S.Content>
    </S.HeaderWrapper>
  )
}

export default memo(Header)
