import { CreditApprovedContent } from '~/components/organisms'
import * as S from './style'

const CreditApproved = () => (
  <S.CreditApprovedWrapper>
    <CreditApprovedContent />
  </S.CreditApprovedWrapper>
)

export default CreditApproved
