import * as S from './style'

interface ICourseName {
  courseName: string
  isUnavailable?: boolean
}

const CourseName = ({ courseName, isUnavailable }: ICourseName) => {
  return <S.CourseTitle isUnavailable={isUnavailable}>{courseName}</S.CourseTitle>
}

export default CourseName
