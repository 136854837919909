import { useContext } from 'react'
import { ParamsContext } from '~/contexts/ParamsContext'

export const useShowTaxesOnCheckout = () => {
  const { partnerData } = useContext(ParamsContext)

  if (!partnerData) {
    return { showTaxesOnCheckout: true }
  }

  return {
    showTaxesOnCheckout: partnerData?.showTaxesOnCheckout || false,
  }
}
