import { useMemo } from 'react'
import { ProgressBar, ButtonV3 as Button, theme } from '@provi/provi-components'
import { LoadingInput } from '~/components/molecules'
import { useCreditApproved } from './hooks'
import * as S from './style'

const CreditApprovedContent = () => {
  const { isLoadingData, remainingTimeInPercentage, callNextRequests, tryCreditApproved, isSendingData } = useCreditApproved()

  const renderCreditApprovedData = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return <LoadingInput />
    }

    return (
      <>
        {callNextRequests ? (
          <S.ProgressBarWrapper>
            <ProgressBar
              progress={remainingTimeInPercentage}
              borderRadius="4px"
              height="16px"
              width="368px"
              backgroundColor={theme.colors.bluePrimaryLight}
              color={theme.colors.bluePrimary}
            />
            <S.CreditApprovedSubTitle>{`Falta pouco...`}</S.CreditApprovedSubTitle>
          </S.ProgressBarWrapper>
        ) : (
          <S.ButtonWrapper>
            <Button text="Continuar" icon onClick={tryCreditApproved} isLoading={isSendingData} mobileWidth="100%" />
          </S.ButtonWrapper>
        )}
      </>
    )
  }, [isLoadingData, callNextRequests, remainingTimeInPercentage, tryCreditApproved, isSendingData])

  return (
    <S.CreditApprovedWrapper>
      <S.CreditApprovedTitle>Estamos preparando seu contrato</S.CreditApprovedTitle>
      <S.CreditApprovedSubTitle>
        O contrato é pessoal e intransferível, por isso precisamos criá-lo especialmente para você.
      </S.CreditApprovedSubTitle>

      {renderCreditApprovedData}
    </S.CreditApprovedWrapper>
  )
}

export default CreditApprovedContent
