import { ButtonV3 as Button } from '@provi/provi-components'
import { useCallback, useMemo } from 'react'
import { BackButton, SuccessIcon } from '~/components/atoms'
import { LoadingInput } from '~/components/molecules'
import { IReviewBlock } from '~/types/index'
import { useReviewFlow } from './hooks'
import * as S from './style'

interface IInformationToReview {
  category: string
  descriptions: string[]
}

const ReviewFlow = () => {
  const { step, isVerifiedInformation, reviewBlocks, proceed, isLoadingData, progressBar } = useReviewFlow()

  const informationToReview = useCallback(
    ({ category, descriptions }: IInformationToReview) => {
      return (
        <S.InformationWrapper key={category}>
          <div>
            <S.Category>{category}</S.Category>
            {descriptions.map((description, index) => (
              <S.Description key={index}>{description}</S.Description>
            ))}
          </div>
          {isVerifiedInformation && <SuccessIcon isVariant />}
        </S.InformationWrapper>
      )
    },
    [isVerifiedInformation],
  )

  const renderInformationsToReview = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return (
        <S.InformationWrapperLoading>
          {Array.from({ length: 3 }).map((_, index) => (
            <S.InformationWrapper key={index}>
              <LoadingInput hasLabel={false} />
            </S.InformationWrapper>
          ))}

          <LoadingInput hasLabel={false} inputWidth="180px" />
        </S.InformationWrapperLoading>
      )
    }

    return (
      <S.ReviewFlowContent>
        {reviewBlocks?.map((block: IReviewBlock) => (
          <> {informationToReview({ category: block.label, descriptions: block.reviewReasons })}</>
        ))}

        <Button text="Continuar" type="submit" icon={true} mobileWidth="100%" onClick={proceed} />
      </S.ReviewFlowContent>
    )
  }, [isLoadingData, reviewBlocks, informationToReview, proceed])

  return (
    <>
      {isVerifiedInformation && <BackButton step={progressBar?.previousScreenUniqueName} disabled={!progressBar?.canBack} />}
      <S.ReviewFlowWrapper>
        <S.ReviewFlowTitle>{step === 'startReview' ? 'Revisão de cadastro' : 'Pronto!'}</S.ReviewFlowTitle>

        <S.ReviewFlowSubTitle marginVariant={isVerifiedInformation}>
          {step === 'startReview'
            ? 'Para seguir com o seu pedido, você precisa revisar algumas informações. É rapidinho!'
            : 'Confirme as informações que você revisou:'}
        </S.ReviewFlowSubTitle>

        {renderInformationsToReview}
      </S.ReviewFlowWrapper>
    </>
  )
}

export default ReviewFlow
