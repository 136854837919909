import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Body1, Body2, Caption } from '~/styles/global'

export const Wrapper = styled.div`
  min-height: 496px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ProductWrapper = styled.div<{ selected: boolean }>`
  max-width: 368px;
  border-radius: 8px;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  cursor: pointer;

  & + div {
    margin-top: 20px;
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid ${theme.colors.bluePrimary};

      button {
        background: ${theme.colors.bluePrimary};
        color: ${theme.colors.white100};
      }
    `}
`

export const ProductsContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
`

export const ProductsDataWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`

export const ProductsData = styled.div`
  max-width: 229px;
  overflow: hidden;
  margin-right: 10px;

  & + div {
    margin-top: 4px;
  }
`

export const CourseData = styled(Body2)<{ isPrice?: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${theme.colors.bluePrimary};

  ${({ isPrice }) =>
    isPrice &&
    css`
      width: fit-content;
      text-overflow: unset;
      overflow: unset;
    `}
`

export const ClassData = styled(Caption)``

export const ButtonStatus = styled.button`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: ${theme.colors.black100};

  width: 100%;
  min-height: 28px;
  background: ${theme.colors.bluePrimaryLight};
  border-radius: 0;
  padding: 4px 8px;
  cursor: pointer;
  border: 0;
`

export const TitleRequests = styled(Body1)`
  margin-bottom: 28px;
`

export const ButtonRequestsWrapper = styled.div<{ marginVariant?: boolean }>`
  max-width: 368px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  cursor: pointer;

  .MuiButton-label {
    width: unset;
  }

  ${({ marginVariant }) =>
    marginVariant &&
    css`
      min-width: 100%;
      margin-top: 32px;
    `}

  @media(max-width: 420px) {
    flex-direction: column;

    > button {
      margin-bottom: 27px;
    }

    .MuiButton-label {
      width: 100%;
    }
  }
`

export const PaymentMethodWrapper = styled.div`
  margin: 10px 8px 3px;
`

export const PaymentMethodText = styled(Caption)``
