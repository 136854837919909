import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Link } from '~/styles/global'

export const InformationTextWithTooltipWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 368px;
`

export const InformationText = styled(Link)`
  color: ${theme.colors.bluePrimary};
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${theme.colors.blueSecundary};
  }

  &:active {
    color: ${theme.colors.bluePrimaryDark};
  }
`
