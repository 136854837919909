import { useEffect } from 'react'

export const useClickOutside = (
  targetRef: { current: EventTarget | null },
  callbackFn: () => void,
  shouldShowToolTip: boolean,
) => {
  useEffect(() => {
    const onDocumentClick = (event: Event) => {
      const withinBoundaries = event.composedPath().includes(targetRef.current as Node)
      if (!withinBoundaries && shouldShowToolTip) callbackFn()
    }

    document.addEventListener('click', onDocumentClick)

    return () => {
      document.removeEventListener('click', onDocumentClick)
    }
  }, [targetRef, callbackFn, shouldShowToolTip])
}
