import { useRef } from 'react'
import { Tooltip } from '~/components/atoms'
import { useClickOutside } from '~/hooks/index'
import { useInformationTextWithToolTip } from './hooks'
import * as S from './style'

interface IInformationTextWithTooltip {
  text: string
  tooltipText: string | JSX.Element
}

const InformationTextWithTooltip = ({ text, tooltipText }: IInformationTextWithTooltip) => {
  const { shouldShowToolTip, handleShowToolTip } = useInformationTextWithToolTip()
  const modalRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(modalRef, handleShowToolTip, shouldShowToolTip)

  return (
    <S.InformationTextWithTooltipWrapper ref={modalRef} onClick={handleShowToolTip}>
      <S.InformationText as="span">{text}</S.InformationText>
      {shouldShowToolTip && <Tooltip text={tooltipText} />}
    </S.InformationTextWithTooltipWrapper>
  )
}

export default InformationTextWithTooltip
