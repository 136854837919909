import * as S from './style'

import { Description, Svg } from '~/components/atoms'

interface IRowSpecifics {
  text: string
  source: string
  alt: string
}

const RowSpecifics = ({ text, source, alt }: IRowSpecifics) => {
  return (
    <S.RowSpecificsWrapper>
      <Svg src={source} alt={alt} width={24} height={24} />
      <Description text={text} />
    </S.RowSpecificsWrapper>
  )
}

export default RowSpecifics
