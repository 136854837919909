import styled, { css } from 'styled-components'
import { Body1, H4 } from '~/styles/global'
import { theme } from '@provi/provi-components'

export const WrapperForm = styled.section`
  margin: 35px 24px 24px;

  ${theme.breakpoints.down('md')} {
    margin-top: 14px;
  }
`

export const WrapperInput = styled.form`
  margin-top: 22px;

  > div {
    margin-bottom: 24px;

    :nth-child(2) {
      margin-bottom: 18px;
    }

    :nth-child(3) {
      margin-bottom: 0;
    }

    :nth-child(4) {
      margin-top: 15px;
    }

    :nth-last-child(2) {
      margin-bottom: 31px;
    }

    @media (max-width: 500px) {
      :nth-child(4) {
        margin-top: 21px;
      }
    }
  }
`

export const SubTitleForm = styled(Body1)<{ fontSizeVariant?: boolean }>`
  max-width: 368px;

  ${({ fontSizeVariant }) =>
    fontSizeVariant &&
    css`
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0075em;
      margin-bottom: 32px;

      ${theme.breakpoints.down('md')} {
        font-size: 13.2px;
        line-height: 20px;
        letter-spacing: 0.005em;
      }
    `}
`

export const TitleForm = styled(H4)`
  max-width: 368px;
  margin-bottom: 23px;
`

export const ButtonWrapper = styled.div<{ displayFlex?: boolean }>`
  ${({ displayFlex }) =>
    displayFlex &&
    css`
      max-width: 472px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    gap: 12px;

    > div {
      min-width: 100%;
    }
  }
`
