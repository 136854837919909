import styled from 'styled-components'
import { Body2, H1 } from '~/styles/global'

export const WebviewModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const WebviewModalTitle = styled(H1)``

export const WebviewModalText = styled(Body2)`
  margin-bottom: 8px;
`

export const EmptyDiv = styled.div`
  margin-top: 15px;
`
