import { useCallback, useMemo } from 'react'
import * as S from './style'
import { IPaymentForms } from 'types'
import { PaymentMethodIcon } from '~/components/atoms'
import { toast } from 'react-toastify'

interface IPaymentMethodCard {
  paymentMethod: IPaymentForms
  isActive: boolean
  isDisabled: boolean
  isLoading: boolean
  onClick: () => void
}

const PaymentMethodCard = ({ paymentMethod, isActive, isDisabled, isLoading, onClick }: IPaymentMethodCard) => {
  const handleClick = useCallback(() => {
    if (isDisabled) {
      toast.error('Meio de pagamento indisponível')
    } else {
      onClick()
    }
  }, [isDisabled, onClick])

  const renderName = useMemo(() => {
    switch (paymentMethod) {
      case 'CreditCard':
        return 'Cartão de crédito'
      case 'Boleto':
        return 'Boleto à vista'
      case 'Pix':
        return 'Pix'
      default:
        return 'Boleto parcelado'
    }
  }, [paymentMethod])

  const renderPaymentMethodCardLoading = useMemo(() => {
    return (
      <S.LoadingCardWrapper isActive={false} isDisabled={false} as="div">
        <S.LoadingPaymentMethodIcon />
        <S.LoadingCardTitle />
      </S.LoadingCardWrapper>
    )
  }, [])

  const renderCard = useMemo(() => {
    return (
      <S.CardWrapper
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={handleClick}
        title={isDisabled ? 'Meio de pagamento indisponível' : renderName}
      >
        <PaymentMethodIcon paymentMethod={paymentMethod} />
        <S.CardTitle isActive={isActive} isDisabled={isDisabled}>
          {renderName}
        </S.CardTitle>
      </S.CardWrapper>
    )
  }, [renderName, isActive, isDisabled, handleClick, paymentMethod])

  if (isLoading && process.env.NODE_ENV !== 'test') {
    return renderPaymentMethodCardLoading
  }

  return renderCard
}

export default PaymentMethodCard
