import { ReactNode, useMemo } from 'react'
import * as S from './style'

interface IModalContainer {
  partnerName?: string
  closeModal?: () => void
  smallCloseIcon?: boolean
  children?: ReactNode
}

const ModalContainer = ({ closeModal, partnerName, smallCloseIcon = false, children }: IModalContainer) => {
  const renderContentInModal = useMemo(() => {
    if (!children) {
      return (
        <>
          <h1>A promoção expirou</h1>
          <p>
            Poxa, parece que as condições de compra que você selecionou não estão mais disponíveis. <br />
            <br />
            Em caso de dúvidas, entre em contato com {partnerName}.
          </p>
        </>
      )
    }

    return children
  }, [children, partnerName])

  return (
    <S.Container smallCloseIcon={smallCloseIcon}>
      {renderContentInModal}

      {typeof closeModal === 'function' && (
        <button type="button" onClick={() => closeModal()} data-testid="btn-close">
          <img src="/assets/svg/closeIcon.svg" alt="Imagem sinalizando botão de fechar modal" />
        </button>
      )}
    </S.Container>
  )
}

export { ModalContainer }
