import { CopyToClipboard } from '~/components/atoms/CopyToClipboard'
import { PortalModal } from '~/components/atoms/PortalModal'
import { ModalContainer } from '~/components/templates/ModalContainer'
import { useWebviewModal } from './hooks'
import * as S from './styles'

export const WebviewModal = () => {
  const { handleClick, modalRef, currentUrlHref } = useWebviewModal()

  return (
    <PortalModal ref={modalRef} borderRadius={'8px'}>
      <ModalContainer closeModal={() => modalRef?.current?.closeModalFn()} smallCloseIcon>
        <S.WebviewModalContainer>
          <S.WebviewModalTitle>Acesso à câmera indisponível</S.WebviewModalTitle>

          <S.WebviewModalText as="span">
            Poxa, não conseguimos acessar a câmera. É provável que você esteja usando um navegador incompatível, como o nativo
            de redes sociais. 😢
            <S.EmptyDiv />
            Para tirar a foto e completar o seu cadastro, copie e cole o link no Chrome, Firefox ou Safari:
          </S.WebviewModalText>

          <CopyToClipboard buttonText="Copiar" onClick={handleClick} value={currentUrlHref} />
        </S.WebviewModalContainer>
      </ModalContainer>
    </PortalModal>
  )
}
