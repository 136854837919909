import { useCallback, useContext, useEffect } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { readCheckoutSuccess, updateCart } from '~/services/api'
import { IRemoveFromCart } from 'types'
import { gtagPurchase } from '~/utils/gtag/gtagPurchase'

interface IUseCartItems {
  removeFromCart: ({ classId }: IRemoveFromCart) => void
}

export const useCartItems = ({ removeFromCart = () => {} }: IUseCartItems) => {
  const { isInCourseFinancingReviewScreen, step, checkoutSuccessObject, setCheckoutSuccessObject } = useContext(CheckoutContext)
  const { partnerSlug, route, getParams, cartData, mutateCart } = useContext(ParamsContext)

  useEffect(() => {
    async function getCheckoutSuccessForms() {
      const result = await readCheckoutSuccess()
      if (typeof result !== 'string') {
        setCheckoutSuccessObject(result.response)

        gtagPurchase(result.response)
      }
    }
    step === 'checkoutSuccess' && getCheckoutSuccessForms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const handleRemoveFromCart = useCallback(
    async ({ classId }: IRemoveFromCart) => {
      const isInCheckout = route === '/checkout/[slug]'
      const hasUserToken = !!sessionStorage.getItem('user_token')?.length

      if (isInCheckout && hasUserToken) {
        const updatedCart = cartData?.courseClassIds.filter((id) => id !== classId)
        const newCart = await updateCart(updatedCart)

        typeof newCart !== 'string' &&
          mutateCart(newCart.cart, {
            revalidate: false,
          })
      }

      removeFromCart({ classId })
    },
    [cartData, removeFromCart, route, mutateCart],
  )

  return {
    checkoutSuccessObject,
    partnerSlug,
    route,
    getParams,
    isInCourseFinancingReviewScreen,
    step,
    handleRemoveFromCart,
  }
}
