import { useMemo, useState } from 'react'
import { ButtonV3 as Button, TextButton, PortalModal } from '@provi/provi-components'
import { IReadFinancingSuccessCourses } from '~/types/index'
import { parsePrice } from '~/utils/parsePrice'
import { ModalContainer } from '../../ModalContainer'
import { useExistingRequests } from './hooks'
import * as S from './style'

const ExistingRequests = () => {
  const [productIndex, setProductIndex] = useState<number | null>(null)
  const [creditRequestId, setCreditRequestId] = useState<number | null>(null)
  const { closeModal, modalRef, existingRequestsData, proceed, isSendingData, availablePaymentMethods } = useExistingRequests()
  const creditRequestIdNull = existingRequestsData?.find((requests) => requests.CreditRequestId === null)

  const renderModalByCreditRequestIdResult = useMemo(() => {
    return (
      <PortalModal ref={modalRef} borderRadius={'8px'}>
        <ModalContainer closeModal={closeModal}>
          <h1>Pedidos em aberto</h1>
          <p>Você começou uma solicitação, mas não concluiu o seu pedido.</p>
          <S.ButtonRequestsWrapper marginVariant={true}>
            <TextButton text="Ver pedidos em aberto" onClick={closeModal} />
            <Button text="Ignorar" onClick={() => proceed(creditRequestId)} />
          </S.ButtonRequestsWrapper>
        </ModalContainer>
      </PortalModal>
    )
  }, [closeModal, modalRef, creditRequestId, proceed])

  const renderProductContent = useMemo(() => {
    return (
      <div>
        <S.TitleRequests>Esses são os pedidos em aberto:</S.TitleRequests>

        {existingRequestsData?.map((request, index) => (
          <S.ProductWrapper
            key={index}
            selected={productIndex === index}
            onClick={() => {
              setProductIndex(index)
              setCreditRequestId(request.CreditRequestId)
            }}
          >
            <div>
              <S.ProductsContent>
                <S.ProductsDataWrapper>
                  {request.products.map((product: IReadFinancingSuccessCourses) => (
                    <S.ProductsData key={product.courseName}>
                      <S.CourseData>{product.courseName}</S.CourseData>
                      <S.ClassData>{product.className}</S.ClassData>
                    </S.ProductsData>
                  ))}
                </S.ProductsDataWrapper>
                <S.CourseData isPrice={true}>{parsePrice(request.fullPrice || 0)} </S.CourseData>
              </S.ProductsContent>
            </div>

            {availablePaymentMethods?.length > 1 && (
              <S.PaymentMethodWrapper>
                <S.PaymentMethodText>
                  <strong>Formas de pagamento: </strong>
                  {availablePaymentMethods?.[index]}
                </S.PaymentMethodText>
              </S.PaymentMethodWrapper>
            )}

            <S.ButtonStatus>{request.status}</S.ButtonStatus>
          </S.ProductWrapper>
        ))}
      </div>
    )
  }, [productIndex, setProductIndex, existingRequestsData, availablePaymentMethods])

  return (
    <S.Wrapper>
      {renderProductContent}

      {creditRequestIdNull ? renderModalByCreditRequestIdResult : null}

      <S.ButtonRequestsWrapper>
        <TextButton text="Cancelar" onClick={() => proceed(null)} />
        <Button
          text="Continuar"
          onClick={() => proceed(creditRequestId)}
          disabled={productIndex === null}
          isLoading={isSendingData}
        />
      </S.ButtonRequestsWrapper>
    </S.Wrapper>
  )
}

export default ExistingRequests
