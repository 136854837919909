import { useState } from 'react'
import * as S from './style'

import { ButtonArrow } from '~/components/molecules'

export interface IButtonGroup {
  classLength: number
  // @ts-ignore
  swiperRef?: any
}

const ButtonGroup = ({ swiperRef, classLength }: IButtonGroup) => {
  const [slidesPerView, setSlidesPerView] = useState(swiperRef?.current?.swiper?.params?.slidesPerView || 1)
  const [slideIndex, setSlideIndex] = useState(swiperRef?.current?.swiper.realIndex || 0)

  const disabledPrevious = slideIndex === 0
  const disabledNext = classLength <= 3 || classLength <= slidesPerView * (slideIndex - 1)

  return (
    <S.ButtonArrows>
      <ButtonArrow
        handleClick={() => {
          if (!disabledPrevious) {
            swiperRef?.current?.swiper?.slidePrev()
            setSlidesPerView(swiperRef?.current?.swiper?.params?.slidesPerView)
            setSlideIndex(swiperRef?.current?.swiper.realIndex)
          }
        }}
        disabledArrow={disabledPrevious}
      />
      <ButtonArrow
        handleClick={() => {
          if (!disabledNext) {
            swiperRef?.current?.swiper?.slideNext()
            setSlidesPerView(swiperRef?.current?.swiper?.params?.slidesPerView)
            setSlideIndex(swiperRef?.current?.swiper.realIndex)
          }
        }}
        isRight
        disabledArrow={disabledNext}
      />
    </S.ButtonArrows>
  )
}

export default ButtonGroup
