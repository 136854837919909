import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Caption } from '~/styles/global'

interface ISummaryReviewText {
  colorVariant?: boolean
}

export const SummaryReviewWrapper = styled.span<{ hidden?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.largerFont {
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0075em;
    }

    ${theme.breakpoints.down('md')} {
      span {
        font-size: 13.2px;
        line-height: 20px;
        letter-spacing: 0.005em;
      }
    }
  }

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const SummaryReviewText = styled(Caption)<ISummaryReviewText>`
  ${({ colorVariant }) =>
    colorVariant &&
    css`
      color: ${theme.colors.bluePrimary};
    `}

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
  }
`
