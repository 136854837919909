import { theme } from '@provi/provi-components'

const ShareIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.78125 12.75C5.30003 12.75 6.53125 11.5188 6.53125 10C6.53125 8.48122 5.30003 7.25 3.78125 7.25C2.26247 7.25 1.03125 8.48122 1.03125 10C1.03125 11.5188 2.26247 12.75 3.78125 12.75Z"
      stroke={theme.colors.white100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2188 18.9375C17.7375 18.9375 18.9688 17.7063 18.9688 16.1875C18.9688 14.6687 17.7375 13.4375 16.2188 13.4375C14.7 13.4375 13.4688 14.6687 13.4688 16.1875C13.4688 17.7063 14.7 18.9375 16.2188 18.9375Z"
      stroke={theme.colors.white100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2188 6.5625C17.7375 6.5625 18.9688 5.33128 18.9688 3.8125C18.9688 2.29372 17.7375 1.0625 16.2188 1.0625C14.7 1.0625 13.4688 2.29372 13.4688 3.8125C13.4688 5.33128 14.7 6.5625 16.2188 6.5625Z"
      stroke={theme.colors.white100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4685 5.125L6.8125 8.51317"
      stroke={theme.colors.white100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.8125 11.4868L13.1248 14.9375"
      stroke={theme.colors.white100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ShareIcon
