import { useCallback, useEffect, useMemo, useState } from 'react'

import { EventBusy, Lock, Payment, Person } from '@material-ui/icons'
import { ButtonV3 as Button, InputFieldV3 as Input, SelectorV3 as Selector } from '@provi/provi-components'
import { useFormik } from 'formik'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { InputInvoiceCode } from '~/components/atoms'
import { CopyToClipboardButton } from '~/components/molecules'
import { ROOT_URL } from '~/constants/index'
import { requiredErrorMessages } from '~/enums/defaultMessages'
import { useIFrameParams } from '~/hooks/useIFrameParams'
import { useInIframe } from '~/hooks/useInIframe'
import { getInvoiceData, writeUpfrontCreditCardPayment } from '~/services/api'
import { IInvoiceData } from '~/types/index'
import { parsePrice } from '~/utils/parsePrice'
import { removeMask } from '~/utils/removeMask'
import { validateCardNumber } from '~/utils/validation'
import {
  BankSlipContainer,
  BankSlipGrid,
  BarcodeContainer,
  BoldTitle,
  DashedDivider,
  DetailsContainer,
  DetailsItem,
  DetailsLine,
  Divider,
  ErrorMessage,
  FlagContainer,
  FormContainer,
  InfoContainer,
  InvoiceContainer,
  InvoiceFieldContainer,
  PaymentContainer,
  PixContainer,
  SelectorWrapper,
  SmallTextGray,
  StagingFlagContainer,
} from './style'

interface IInvoiceTemplate {
  ssrInvoiceData: IInvoiceData
}

interface IFormData {
  number: string
  verification_value: string
  first_name: string
  last_name: string
  month: { id: string; label: string }
  year: { id: string; label: string }
  installmentsToApply: { id: number; label: string } | null
}

const monthsArray = [
  { id: '01', label: '01' },
  { id: '02', label: '02' },
  { id: '03', label: '03' },
  { id: '04', label: '04' },
  { id: '05', label: '05' },
  { id: '06', label: '06' },
  { id: '07', label: '07' },
  { id: '08', label: '08' },
  { id: '09', label: '09' },
  { id: '10', label: '10' },
  { id: '11', label: '11' },
  { id: '12', label: '12' },
]

const startYear = 2024 // You can change this to the desired start year
const endYear = 2099 // You can change this to the desired end year

const yearsArray = []

for (let year = startYear; year <= endYear; year++) {
  yearsArray.push({ id: `${year}`, label: `${year}` })
}

const InvoiceTemplate = ({ ssrInvoiceData }: IInvoiceTemplate) => {
  const { iframeParams } = useIFrameParams()
  const { isInIframe } = useInIframe()

  const [invoiceData, setInvoiceData] = useState<IInvoiceData>(ssrInvoiceData)
  const [isSendingData, setIsSendingData] = useState(false)

  const router = useRouter()
  const invoiceId = router.query.slug

  const handlePaid = useCallback(() => {
    if (
      isInIframe &&
      iframeParams?.redirectUrl &&
      (invoiceData?.status === 'paid' || invoiceData?.invoiceInformation?.status === 'paid')
    ) {
      toast.success('Sucesso! Você será redirecionado em 5 segundos.', {
        toastId: 'writeUpfrontCreditCardPaymentSuccess',
        autoClose: 10000,
      })

      setTimeout(() => {
        window.top.location.href = decodeURIComponent(iframeParams.redirectUrl)
      }, 5000)
    }
  }, [invoiceData?.status, invoiceData?.invoiceInformation?.status, isInIframe, iframeParams?.redirectUrl])

  useEffect(() => {
    if (invoiceData?.status === 'pending' || invoiceData?.invoiceInformation?.status === 'pending') {
      const interval = setInterval(async () => {
        const response = await getInvoiceData(invoiceId?.toString())
        if (response) {
          setInvoiceData(response)
        }
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [invoiceId, invoiceData?.status, invoiceData?.invoiceInformation?.status])

  useEffect(() => {
    handlePaid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData?.status, invoiceData?.invoiceInformation?.status, iframeParams?.redirectUrl, isInIframe])

  useEffect(() => {
    // Função para executar quando o componente é montado

    const script = document.createElement('script')
    script.src = ROOT_URL?.IUGU_JS_SCRIPT || ''
    script.async = true
    script.onload = () => IuguLoaded()

    document.body.appendChild(script)

    // Função de limpeza (executada quando o componente é desmontado)
    return () => {
      // Remova o script quando o componente for desmontado para evitar vazamentos de memória
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Função para ser chamada quando o script é carregado
  const IuguLoaded = () => {
    Iugu.setAccountID(invoiceData.invoiceInformation.accountId)

    if (process.env.NEXT_PUBLIC_API_ENV !== 'production') {
      Iugu.setTestMode(true)
    }

    return Iugu
  }

  const creditCardPaymentConditions = useMemo(() => {
    return (
      invoiceData?.creditCard?.paymentConditions?.map((paymentCondition) => ({
        id: paymentCondition.installmentsToApply,
        label: `${paymentCondition.installmentsToApply}x de ${parsePrice(paymentCondition.pmt, true)} (sem juros)`,
      })) || []
    )
  }, [invoiceData?.creditCard])

  const { values, errors, touched, setFieldTouched, setFieldValue, setFieldError, handleChange, handleSubmit, validateForm } =
    useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
        number: '',
        verification_value: '',
        first_name: '',
        last_name: '',
        month: null,
        year: null,
        installmentsToApply: creditCardPaymentConditions[0] || null,
      },

      validationSchema: Yup.object({
        number: Yup.string().required(requiredErrorMessages.cardNumber),

        verification_value: Yup.string()
          .required('Código de segurança é obrigatório')
          .matches(/^\d{3,4}$/, 'Código de segurança deve ter 3 ou 4 dígitos'),

        first_name: Yup.string().required('Nome é obrigatório'),

        last_name: Yup.string().required('Sobrenome é obrigatório'),

        month: Yup.mixed().required('Mês é obrigatório'),

        year: Yup.mixed().required('Ano é obrigatório'),

        installmentsToApply: Yup.mixed(),
      }),

      onSubmit: async (cardData: IFormData) => {
        try {
          setIsSendingData(true)

          const cardHash = await createPaymentToken({
            ...cardData,
            number: removeMask(cardData.number),
            year: cardData.year.label,
            month: cardData.month.label,
          })

          const response = await writeUpfrontCreditCardPayment({
            iuguId: invoiceData?.invoiceInformation.iuguId,
            creditCardHash: cardHash,
            installmentsToApply: cardData?.installmentsToApply?.id || 1,
          })

          if (response?.success) {
            const invoiceResponse = await getInvoiceData(invoiceId?.toString())
            if (invoiceResponse) {
              setInvoiceData(invoiceResponse)
            }

            if (isInIframe) {
              handlePaid()
              return
            }

            toast.success(response.message, {
              toastId: 'writeUpfrontCreditCardPayment',
              autoClose: 10000,
            })
            setTimeout(() => window.location.reload(), 10000)
            return
          }

          if (response?.response?.data?.error || !response?.success) {
            toast.error(response?.response?.data?.msg || response?.message, {
              toastId: 'writeUpfrontCreditCardPayment',
              autoClose: 10000,
            })
            return
          }
        } catch (error) {
          toast.error(error.message || error, {
            toastId: 'writeUpfrontCreditCardPayment',
            autoClose: 10000,
          })
        } finally {
          setIsSendingData(false)
        }
      },
      validate: (values) => {
        if (values.number.length !== 0) {
          if (!validateCardNumber(values.number)) {
            return {
              number: 'Número do cartão inválido',
            }
          }
        }

        const today = new Date()
        const currentMonth = today.getMonth() + 1
        const currentYear = today.getFullYear()

        if (parseInt(values?.year?.label) < currentYear) {
          return {
            year: 'Cartão expirado',
          }
        }

        if (parseInt(values?.year?.label) === currentYear && parseInt(values?.month?.label) < currentMonth) {
          return {
            month: 'Cartão expirado',
          }
        }

        return {}
      },
    })

  const statusTranslations = {
    pending: 'PENDENTE',
    paid: 'PAGA',
    processing: 'PROCESSANDO',
    expired: 'VENCIDA',
    cancelled: 'CANCELADA',
  }

  const handlePrint = () => {
    window.print()
  }

  function createPaymentToken(cardData): Promise<string> {
    return new Promise((resolve, reject) => {
      IuguLoaded().createPaymentToken(cardData, function (response) {
        if (response.errors) {
          const fieldErrors = Object.keys(response.errors)

          fieldErrors.forEach((field) => {
            setFieldError(field, 'Campo inválido')
          })

          reject(new Error('Erro ao processar seu pagamento'))
        } else {
          resolve(response.id)
        }
      })
    })
  }

  return (
    <InvoiceContainer>
      <InfoContainer>
        <div>
          <div>
            <SmallTextGray>CLIENTE</SmallTextGray>
            <p>{invoiceData?.client?.fullName}</p>
            <SmallTextGray>CPF/CNPJ</SmallTextGray>
            <p>{invoiceData?.client?.CPF || invoiceData?.client?.CNPJ}</p>
            <SmallTextGray>ENDEREÇO</SmallTextGray>
            <p>{invoiceData?.client?.address}</p>
          </div>
          <div>
            <SmallTextGray>CEDENTE</SmallTextGray>
            <p>{invoiceData?.grantor?.name}</p>
            <SmallTextGray>CPF/CNPJ CEDENTE</SmallTextGray>
            <p>{invoiceData?.grantor?.CPF || invoiceData?.grantor?.CNPJ}</p>
            <SmallTextGray>ENDEREÇO</SmallTextGray>
            <p>{invoiceData?.grantor?.address}</p>
          </div>

          <FlagContainer status={invoiceData?.invoiceInformation?.status}>
            <p>{statusTranslations[invoiceData?.invoiceInformation?.status] || invoiceData?.invoiceInformation?.status}</p>
          </FlagContainer>
          {process.env.NEXT_PUBLIC_API_ENV !== 'production' && <StagingFlagContainer>AMBIENTE DE TESTES</StagingFlagContainer>}
        </div>
      </InfoContainer>

      <DetailsContainer>
        <DetailsLine>
          <SmallTextGray>IDENTIFICAÇÃO DA FATURA {invoiceData?.invoiceInformation?.iuguId}</SmallTextGray>
          <SmallTextGray>Data de emissão {invoiceData?.invoiceInformation?.createdAt}</SmallTextGray>
        </DetailsLine>
        <DetailsLine>
          <BoldTitle>Detalhes da fatura</BoldTitle>
          <div>
            <SmallTextGray>Vencimento</SmallTextGray>
            <p>{invoiceData?.invoiceInformation?.dueDate}</p>
          </div>
        </DetailsLine>

        <DetailsLine>
          <SmallTextGray>Descrição</SmallTextGray>
          <SmallTextGray>Valor</SmallTextGray>
        </DetailsLine>
        <Divider />
        <DetailsItem>
          <p>{invoiceData?.invoiceInformation?.items[0]?.description}</p>
          <span className="line"></span>
          <p>{parsePrice(invoiceData?.invoiceInformation?.items[0]?.priceInCents / 100, true) || 'R$ 0,00'}</p>
        </DetailsItem>

        <Divider />
        <DetailsItem>
          <p>Multa/Juros</p>
          <span className="line"></span>
          <p>
            {parsePrice(
              invoiceData?.invoiceInformation?.items[1]?.priceInCents / 100 +
                invoiceData?.invoiceInformation?.items[2]?.priceInCents / 100,
              true,
            ) || 'R$ 0,00'}
          </p>
        </DetailsItem>

        <Divider />
        <DetailsItem>
          <p>Subtotal</p>
          <span className="line"></span>
          <p>
            {parsePrice(
              invoiceData?.invoiceInformation?.items[0]?.priceInCents / 100 +
                invoiceData?.invoiceInformation?.items[1]?.priceInCents / 100 +
                invoiceData?.invoiceInformation?.items[2]?.priceInCents / 100,
              true,
            ) || 'R$ 0,00'}
          </p>
        </DetailsItem>

        <Divider />
        <BoldTitle>Total {parsePrice(invoiceData?.invoiceInformation?.totalCents / 100, true) || 'R$ 0,00'}</BoldTitle>
      </DetailsContainer>

      {invoiceData?.status === 'pending' && (
        <>
          <DashedDivider />
          <PaymentContainer>
            <BoldTitle>Pagar fatura</BoldTitle>
            <SmallTextGray>Efetue o pagamento com segurança pela internet ou em uma agência bancária.</SmallTextGray>
            <p>Confira as opções de pagamento para esta fatura.</p>
          </PaymentContainer>

          {invoiceData?.pix?.qrcodeImage && invoiceData?.pix?.qrcodeText && (
            <>
              <DashedDivider />
              <div>
                <BoldTitle>Pix</BoldTitle>

                <PixContainer>
                  <div>
                    <Image src={invoiceData?.pix?.qrcodeImage} height={'200px'} width={'200px'} />
                    <InputInvoiceCode width="200px" value={invoiceData?.pix?.qrcodeText} noMask />
                    <CopyToClipboardButton text={'Copiar link Pix'} invoiceCode={invoiceData?.pix?.qrcodeText} />
                  </div>
                  <div>
                    <BoldTitle>O que é o Pix?</BoldTitle>
                    <SmallTextGray>
                      O Pix é a nova modalidade de transferências do Banco Central, que funciona 24 horas por dia e possui
                      confirmação em tempo real.
                    </SmallTextGray>
                  </div>
                </PixContainer>
              </div>
            </>
          )}

          {invoiceData?.canBePaidWithCreditCard && (
            <>
              <DashedDivider />
              <FormContainer>
                <BoldTitle>Cartão de crédito</BoldTitle>
                <form>
                  <Input
                    width="400px"
                    required
                    placeholder="Nome"
                    name="first_name"
                    id="first_name"
                    value={values.first_name}
                    icon={<Person />}
                    onChange={handleChange}
                    onBlur={() => {
                      setFieldTouched('first_name')
                      validateForm()
                    }}
                    isValid={!errors.first_name && touched.first_name}
                    hasError={errors.first_name && touched.first_name}
                    errorMessage={errors.first_name}
                  />
                  <Input
                    width="400px"
                    required
                    placeholder="Sobrenome"
                    name="last_name"
                    id="last_name"
                    value={values.last_name}
                    icon={<Person />}
                    onChange={handleChange}
                    onBlur={() => {
                      setFieldTouched('last_name')
                      validateForm()
                    }}
                    isValid={!errors.last_name && touched.last_name}
                    hasError={errors.last_name && touched.last_name}
                    errorMessage={errors.last_name}
                  />
                  <Input
                    width="400px"
                    required
                    placeholder="Número do cartão "
                    name="number"
                    id="number"
                    mask="9999 9999 9999 9999"
                    value={values.number}
                    icon={<Payment />}
                    onChange={handleChange}
                    onBlur={() => {
                      setFieldTouched('number')
                      validateForm()
                    }}
                    isValid={!errors.number && touched.number}
                    hasError={errors.number && touched.number}
                    errorMessage={errors.number}
                  />
                  <Input
                    width="400px"
                    required
                    placeholder="Código de segurança"
                    name="verification_value"
                    id="verification_value"
                    mask="9999"
                    value={values.verification_value}
                    icon={<Lock />}
                    onChange={handleChange}
                    onBlur={() => {
                      setFieldTouched('verification_value')
                      validateForm()
                    }}
                    isValid={!errors.verification_value && touched.verification_value}
                    hasError={errors.verification_value && touched.verification_value}
                    errorMessage={errors.verification_value}
                  />
                  <SelectorWrapper hasError={!!errors.month && !!touched.month}>
                    <Selector
                      width="400px"
                      required
                      placeholder="Mês de Expiração"
                      name="month"
                      id="month"
                      value={values.month}
                      options={monthsArray}
                      icon={<EventBusy />}
                      onChange={(e) => setFieldValue('month', e)}
                      onBlur={() => {
                        setFieldTouched('month')
                        validateForm()
                      }}
                    />
                    {errors.month && touched.month && <ErrorMessage>{errors.month}</ErrorMessage>}
                  </SelectorWrapper>
                  <SelectorWrapper hasError={!!errors.year && !!touched.year}>
                    <Selector
                      width="400px"
                      required
                      placeholder="Ano de Expiração"
                      name="year"
                      id="year"
                      value={values.year}
                      options={yearsArray}
                      onChange={(e) => setFieldValue('year', e)}
                      onBlur={() => {
                        setFieldTouched('year')
                        validateForm()
                      }}
                    />
                    {errors.year && touched.year && <ErrorMessage>{errors.year}</ErrorMessage>}
                  </SelectorWrapper>
                  {creditCardPaymentConditions.length > 1 && (
                    <SelectorWrapper hasError={false}>
                      <Selector
                        width="400px"
                        required
                        placeholder="Parcela de entrada"
                        name="installmentsToApply"
                        id="installmentsToApply"
                        value={values.installmentsToApply}
                        options={creditCardPaymentConditions}
                        icon={<EventBusy />}
                        onChange={(e) => setFieldValue('installmentsToApply', e)}
                        onBlur={() => {
                          setFieldTouched('installmentsToApply')
                          validateForm()
                        }}
                      />
                    </SelectorWrapper>
                  )}
                  <Button text="Pagar" style={{ marginTop: '20px' }} onClick={handleSubmit} isLoading={isSendingData} />
                </form>
              </FormContainer>
            </>
          )}
          <DashedDivider />

          <BankSlipContainer id="print" shouldHide={(isInIframe && iframeParams?.hideBoletoAsUpfront) || false}>
            <BoldTitle>Boleto Bancário</BoldTitle>

            <BankSlipGrid>
              <div>
                <Image src="/principia_logo_azul.png" width={203} height={83} objectFit="contain" />
              </div>

              <InvoiceFieldContainer>
                <p>
                  {invoiceData?.bankSlip?.bankName} - {invoiceData?.bankSlip?.bankCode}
                </p>
                <p>{invoiceData?.bankSlip?.digitableLine}</p>
              </InvoiceFieldContainer>
              <InvoiceFieldContainer>
                <SmallTextGray>Local de pagamento</SmallTextGray>
                <p>{invoiceData?.bankSlip?.paidAt}</p>
              </InvoiceFieldContainer>
              <InvoiceFieldContainer>
                <SmallTextGray>Nosso número</SmallTextGray>
                <p>{invoiceData?.bankSlip?.transactionNumber}</p>
              </InvoiceFieldContainer>
              <InvoiceFieldContainer>
                <SmallTextGray>Beneficiário</SmallTextGray>
                <p>{invoiceData?.bankSlip?.beneficiary?.name}</p>
                <p>{invoiceData?.bankSlip?.beneficiary?.CPF || invoiceData?.bankSlip?.beneficiary?.CNPJ}</p>
                <SmallTextGray>{invoiceData?.bankSlip?.beneficiary?.address}</SmallTextGray>
              </InvoiceFieldContainer>
              <InvoiceFieldContainer>
                <SmallTextGray>Vencimento</SmallTextGray>
                <p>{invoiceData?.invoiceInformation?.dueDate}</p>
              </InvoiceFieldContainer>
              <InvoiceFieldContainer>
                <SmallTextGray>Valor do Doc.</SmallTextGray>
                <p>{parsePrice(invoiceData?.invoiceInformation?.items[0]?.priceInCents / 100, true)}</p>
              </InvoiceFieldContainer>
              <InvoiceFieldContainer>
                <SmallTextGray>Instruções</SmallTextGray>
                <p>{invoiceData?.bankSlip?.instructionsTextArray?.[0]}</p>
              </InvoiceFieldContainer>

              <InvoiceFieldContainer>
                <SmallTextGray>Multa/Juros</SmallTextGray>
                <p>
                  {parsePrice(
                    (invoiceData?.invoiceInformation?.items[1]?.priceInCents +
                      invoiceData?.invoiceInformation?.items[2]?.priceInCents) /
                      100,
                    true,
                  )}
                </p>
              </InvoiceFieldContainer>
              <InvoiceFieldContainer>
                <SmallTextGray>Cliente</SmallTextGray>
                <p>{invoiceData?.client?.fullName}</p>
              </InvoiceFieldContainer>
              <InvoiceFieldContainer>
                <SmallTextGray>Valor a Pagar</SmallTextGray>
                <p>{parsePrice(invoiceData?.bankSlip?.totalCents / 100, true)}</p>
              </InvoiceFieldContainer>
            </BankSlipGrid>

            {invoiceData?.bankSlip?.digitableLine && (
              <BarcodeContainer>
                <SmallTextGray id="notPrint">Use este código de barras para pagamentos no bankline</SmallTextGray>

                <p id="notPrint">{invoiceData?.bankSlip?.digitableLine}</p>

                <Image src={invoiceData?.bankSlip?.barcodeImage} height={'140'} width={'850'} />
                <Button type="button" onClick={handlePrint} text="Imprimir" id="notPrint" />
              </BarcodeContainer>
            )}
          </BankSlipContainer>
        </>
      )}
    </InvoiceContainer>
  )
}

export default InvoiceTemplate
