import { ButtonV3 as Button, InputFieldV3 as Input } from '@provi/provi-components'
import { ChangeEvent } from 'react'
import { ICourses } from 'types'
import AgreeWithTos from '~/components/molecules/AgreeWithTos'
import { useGenerateLeadFromEmptyCourse } from './hooks'
import * as S from './style'

interface IGenerateLeadFromEmptyCourse {
  courseObject: ICourses
  onClose: () => void
}

const GenerateLeadFromEmptyCourse = ({ courseObject, onClose }: IGenerateLeadFromEmptyCourse) => {
  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validateForm,
    values,
    errors,
    dirty,
    isValid,
    touched,
    isChecked,
    handleChecked,
    isSendingData,
  } = useGenerateLeadFromEmptyCourse(courseObject, onClose)

  return (
    <S.GenerateLeadFromEmptyCourseWrapper>
      <S.GenerateLeadTitle>{courseObject.courseName}</S.GenerateLeadTitle>
      <S.GenerateLeadText>
        Informe seus dados de contato para receber um aviso sobre novas turmas desse curso:
      </S.GenerateLeadText>
      <S.GenerateLeadForm onSubmit={handleSubmit}>
        <Input
          width="100%"
          label="Nome"
          required
          placeholder="Cecilia"
          name="name"
          value={values.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('name', e.target.value)}
          onBlur={() => {
            setFieldTouched('name')
            validateForm()
          }}
          isValid={!errors.name && touched.name}
          hasError={errors.name && touched.name}
          errorMessage={errors.name}
        />
        <Input
          width="100%"
          label="E-mail"
          type="email"
          required
          placeholder="cecilia@email.com.br"
          name="email"
          value={values.email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('email', e.target.value)}
          onBlur={() => {
            setFieldTouched('email')
            validateForm()
          }}
          isValid={!errors.email && touched.email}
          hasError={errors.email && touched.email}
          errorMessage={errors.email}
        />
        <AgreeWithTos margin="6px 0 10px" isDisabled={!(isValid && dirty)} onChange={handleChecked} isChecked={isChecked} />

        <S.ButtonsWrapper>
          <Button width="fit-content" mobileWidth="100%" color="white" type="button" text="Cancelar" onClick={onClose} />
          <Button
            width="148px"
            mobileWidth="100%"
            type="submit"
            text="Receber aviso"
            disabled={!(isValid && dirty && isChecked)}
            onClick={handleSubmit}
            isLoading={isSendingData}
          />
        </S.ButtonsWrapper>
      </S.GenerateLeadForm>
    </S.GenerateLeadFromEmptyCourseWrapper>
  )
}

export default GenerateLeadFromEmptyCourse
