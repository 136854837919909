import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { useShowTaxesOnCheckout } from '~/hooks/useShowTaxesOnCheckout'
import { readFinancingSummary, writeFinancingSummary } from '~/services/api'
import { IFinancingSummaryData } from '~/types/index'
import { formatInstallmentText } from '~/utils/index'

export const useFinancingSummaryContent = () => {
  const { showTaxesOnCheckout } = useShowTaxesOnCheckout()
  const [financingSummaryData, setFinancingSummaryData] = useState<IFinancingSummaryData>()
  const { step, isLoadingData, isSendingData } = useContext(CheckoutContext)
  const [shouldShowMore, setShouldShowMore] = useState(false)

  useEffect(() => {
    async function findFinancingSummaryData() {
      const result = await readFinancingSummary()

      if (typeof result != 'string') {
        const sortedPaymentFlow = result?.response?.paymentFlow?.sort?.((a) => (a?.isUpfront ? -1 : 1))
        const newFinancingSummaryData = { ...result.response, paymentFlow: sortedPaymentFlow }

        setFinancingSummaryData(newFinancingSummaryData)
      }
    }

    findFinancingSummaryData()
  }, [])

  const handleNextScreen = useCallback(async () => {
    await writeFinancingSummary(step)
  }, [step])

  const summaryInstallmentsToApply = formatInstallmentText(financingSummaryData?.installmentsToApply || 0)

  const handleToggle = useCallback(() => setShouldShowMore((toggle) => !toggle), [setShouldShowMore])

  return {
    financingSummaryData,
    summaryInstallmentsToApply,
    shouldShowMore,
    handleToggle,
    handleNextScreen,
    isLoadingData,
    isSendingData,
    showTaxesOnCheckout,
  }
}
