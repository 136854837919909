type IParsePrice = number | string

export const parsePrice = (priceInReais: IParsePrice, asString?: boolean) => {
  const price = typeof priceInReais === 'string' ? parseFloat(priceInReais) : priceInReais || 0

  const formattedPrice = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price)

  if (isNaN(price)) {
    throw new Error(`Can't parse price: ${priceInReais}`)
  }

  if (asString || process.env.NODE_ENV === 'test') {
    return formattedPrice
  }

  const real = formattedPrice.split(',')[0]
  const cents = formattedPrice.split(',')[1]

  return (
    <>
      {real}
      <sup>{cents}</sup>
    </>
  )
}
