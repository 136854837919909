import { useContext, useState, useCallback, useEffect } from 'react'
import { CoursesContext } from '~/contexts/CoursesContext'
import { copyToClipboard } from '~/utils/copyToClipboard'

export const useWebviewModal = () => {
  const { modalRef } = useContext(CoursesContext)
  const [currentUrlHref, setCurrentUrlHref] = useState('')

  useEffect(() => {
    const token = sessionStorage.getItem('user_token')
    const url = new URL(window.location.href)

    url.searchParams.set('token', token)

    setCurrentUrlHref(url.href)
  }, [setCurrentUrlHref])

  const handleClick = useCallback(() => {
    copyToClipboard(currentUrlHref)
  }, [currentUrlHref])

  return {
    currentUrlHref,
    handleClick,
    modalRef,
  }
}
