import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ButtonArrows = styled.div`
  width: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  position: absolute;
  top: 15px;
  right: 16px;

  ${theme.breakpoints.down('md')} {
    visibility: hidden;
  }
`
