import { theme } from '@provi/provi-components'

export const PencilIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.57873 19H1.79696C1.58559 19 1.38288 18.916 1.23342 18.7666C1.08397 18.6171 1 18.4144 1 18.203V13.7514C1 13.6467 1.02061 13.5431 1.06066 13.4464C1.10072 13.3497 1.15942 13.2618 1.23343 13.1878L13.1878 1.23342C13.3373 1.08396 13.54 1 13.7514 1C13.9627 1 14.1655 1.08396 14.3149 1.23342L18.7666 5.68508C18.916 5.83454 19 6.03725 19 6.24862C19 6.45998 18.916 6.66269 18.7666 6.81215L6.57873 19ZM6.57873 19H18.5332M6.57873 19L1.05071 13.472M10.5635 3.85773L16.1423 9.43646"
        stroke={theme.colors.white100}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
