import { removeMask } from './removeMask'

const regex = /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-\s]*$/
export const isNameValid = (information: string) => information.length >= 2 && regex.test(information)

export const nameHasError = (information: string) =>
  (information?.length && information?.length < 2) || !regex.test(information)

export const isEmailValid = (information: string) => {
  const email = information.trim()

  if (isEmptyString(email)) {
    return false
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email)?.toLowerCase())
}

export const emailHasError = (information: string) => !!information?.length && !isEmailValid(information)

export const validateCEP = (value: string) => new RegExp(/^\d{5}-\d{3}$/g).test(value)

export const isPhoneValid = (information: string) => {
  const phone = information.replace(/\D/g, '')

  if (phone?.length === 11) {
    return true
  }

  return false
}

export const phoneHasError = (information: string) => {
  const phone = information?.replace(/\D/g, '')

  if (phone.length) {
    if (phone.length === 11) {
      return false
    }

    return true
  }
}

export const validateCPF = (value: string) => {
  if (!value) return false
  let sum = 0
  let rest
  const cpf = value.replace(/[^\d]+/g, '')
  if (cpf.length !== 11) return false
  if (cpf === '00000000000') return false
  if (cpf === '11111111111') return false
  if (cpf === '22222222222') return false
  if (cpf === '33333333333') return false
  if (cpf === '44444444444') return false
  if (cpf === '55555555555') return false
  if (cpf === '66666666666') return false
  if (cpf === '77777777777') return false
  if (cpf === '88888888888') return false
  if (cpf === '99999999999') return false
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(9, 10), 10)) return false
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(10, 11), 10)) return false
  return true
}

export function getFirstName(fullName: string) {
  return fullName ? fullName.split(' ')[0] : ''
}
export function getLastName(fullName: string) {
  if (!fullName) {
    return ''
  }
  return fullName
    .trim()
    .split(' ')
    .filter((_name, index) => index > 0)
}
function hasNumbers(string: string) {
  return string.split('').filter((item) => [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].includes(parseInt(item))).length
}
function isEmptyString(string: string) {
  return string.trim() === ''
}
function isEmpty(string: string) {
  return string.trim().length === 0
}
function isEmptyArray(array: string[]) {
  return array.length === 0
}

export const validateFullName = (fullName: string) => {
  fullName = fullName.trim()

  fullName = fullName.replace(/\s\s+/g, ' ')

  let result = true
  const REGEX = /^[A-Za-zçáàâãéèêíïóôõöúüÁÀÂÃÉÈÊÍÏÓÔÕÖÚÜ\-' ]+$/
  if (!REGEX.test(fullName)) {
    result = false
    return result
  }
  const firstName = getFirstName(fullName)
  const lastName = getLastName(fullName)

  if (
    firstName.length < 2 ||
    (lastName[lastName.length - 1] && lastName[lastName.length - 1].length > 0 && lastName[lastName.length - 1].length < 2)
  ) {
    return false
  }

  if (isEmpty(firstName) || isEmptyString(firstName) || (Array.isArray(lastName) && isEmptyArray(lastName))) {
    result = false
    return result
  }

  Array.isArray(lastName) &&
    lastName.forEach((name: string) => (isEmpty(name) || isEmptyString(name) || hasNumbers(name) ? (result = false) : null))
  return result
}

export const validateCardNumber = (value: string) => new RegExp(/^.{12,}$/).test(removeMask(value))

export const validateExpireDate = (value: string) => new RegExp(/^(0?[1-9]|1[012])\/\d{2}$/g).test(value)
