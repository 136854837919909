import { theme } from '@provi/provi-components'
import * as S from './style'

interface ICartIcon {
  number: number
  onClick?: () => void
}

const CartIcon = ({ number, ...rest }: ICartIcon) => {
  if (!number) {
    return (
      <S.Wrapper role="button" {...rest}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
          <path
            d="M18.25 19H6.67139C6.49574 19 6.32567 18.9384 6.19082 18.8258C6.05597 18.7133 5.9649 18.557 5.93348 18.3842L3.43015 4.61584C3.39873 4.44303 3.30766 4.28673 3.17281 4.17419C3.03796 4.06164 2.86789 4 2.69225 4H1M18.25 19C17.2145 19 16.375 19.8395 16.375 20.875C16.375 21.9105 17.2145 22.75 18.25 22.75C19.2855 22.75 20.125 21.9105 20.125 20.875C20.125 19.8395 19.2855 19 18.25 19ZM4 7.75H20.3513C20.4612 7.75 20.5697 7.77413 20.6692 7.82069C20.7687 7.86725 20.8568 7.9351 20.9272 8.01944C20.9975 8.10378 21.0486 8.20255 21.0766 8.30878C21.1046 8.415 21.1089 8.52608 21.0892 8.63416L19.862 15.3842C19.8306 15.557 19.7395 15.7133 19.6046 15.8258C19.4698 15.9384 19.2997 16 19.1241 16H5.5M8.875 20.875C8.875 21.9105 8.03553 22.75 7 22.75C5.96447 22.75 5.125 21.9105 5.125 20.875C5.125 19.8395 5.96447 19 7 19C8.03553 19 8.875 19.8395 8.875 20.875Z"
            stroke={theme.colors.black100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </S.Wrapper>
    )
  } else {
    return (
      <S.Wrapper role="button" {...rest}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
          <path
            d="M18.25 19H6.67139C6.49574 19 6.32567 18.9384 6.19082 18.8258C6.05597 18.7133 5.9649 18.557 5.93348 18.3842L3.43015 4.61584C3.39873 4.44303 3.30766 4.28673 3.17281 4.17419C3.03796 4.06164 2.86789 4 2.69225 4H1M18.25 19C17.2145 19 16.375 19.8395 16.375 20.875C16.375 21.9105 17.2145 22.75 18.25 22.75C19.2855 22.75 20.125 21.9105 20.125 20.875C20.125 19.8395 19.2855 19 18.25 19ZM21.0892 8.63416L19.862 15.3842C19.8306 15.557 19.7395 15.7133 19.6046 15.8258C19.4698 15.9384 19.2997 16 19.1241 16H5.5M8.875 20.875C8.875 21.9105 8.03553 22.75 7 22.75C5.96447 22.75 5.125 21.9105 5.125 20.875C5.125 19.8395 5.96447 19 7 19C8.03553 19 8.875 19.8395 8.875 20.875Z"
            stroke={theme.colors.black100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="12.5" cy="6.5" r="6.5" fill={theme.colors.bluePrimary} />
          <text x={number < 10 ? '10.5' : '7.5'} y="9" fontSize={8} fontWeight="700" fill={theme.colors.white100}>
            {number}
          </text>
        </svg>
      </S.Wrapper>
    )
  }
}

export default CartIcon
