import styled from 'styled-components'
import { Body2, loadingCSS } from '~/styles/global'
import { theme } from '@provi/provi-components'

export const OptionInputLoading = styled.div`
  height: 40px;
  width: 100%;
  margin: 8px 0;

  ${loadingCSS}
`

export const ChecklistOptionWrapper = styled.div`
  margin-bottom: 50px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 48px;
  }
`

export const SubTitleInformation = styled(Body2)`
  margin: 27px 0px 16px;
`
