import styled, { css } from 'styled-components'
import { Body1 } from '~/styles/global'

interface IMiniTextBox {
  isOpen: boolean
  fontSizeVariant?: boolean
}

export const MiniTextBoxTitle = styled(Body1)<IMiniTextBox>`
  font-weight: ${({ fontSizeVariant }) => (fontSizeVariant ? '500' : '600')};
  font-size: ${({ fontSizeVariant }) => (fontSizeVariant ? '20px' : '14px')};
  margin-bottom: 8px;
  transition: all 0.2s ease-in;

  ${({ isOpen }) =>
    isOpen &&
    css`
      font-size: 20px;
      font-weight: 500;
    `}
`

export const MiniTextBoxSubTitle = styled(Body1)<IMiniTextBox>`
  font-size: ${({ fontSizeVariant }) => (fontSizeVariant ? '14px' : '16px')};
  font-weight: 500;
  transition: all 0.2s ease-in;

  ${({ isOpen }) =>
    isOpen &&
    css`
      font-size: 14px;
    `}
`
