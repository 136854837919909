import { event } from '.'
import { IPaymentForms } from '~/types/index'

export const gtagAddPaymentInfo = (paymentMethod: IPaymentForms) => {
  event({
    action: 'add_payment_info',
    eventParams: {
      event_label: paymentMethod,
    },
  })
}
