import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2 } from '~/styles/global'

interface IButtonWrapper {
  shouldShowOnMobile?: boolean
}

export const PaymentMethodTicketWrapper = styled.div`
  max-width: 368px;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > svg {
    max-width: 315px;
    position: absolute;
    bottom: 0;
  }

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`

export const PaymentSummaryContent = styled.div<{ marginVariant: boolean }>`
  max-width: 315px;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-bottom: none;
  overflow: hidden;
  position: relative;
  display: none;
  margin-bottom: ${({ marginVariant }) => (marginVariant ? '24px' : '98px')} !important;

  ${theme.breakpoints.down('md')} {
    display: unset;
    margin: 0 auto;
  }

  @media (max-width: 365px) {
    margin: 0;
  }
`

export const PaymentMethodTicketText = styled(Body2)`
  margin-bottom: 181px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 43px;
  }
`
export const SummaryTotalPrice = styled.div`
  margin: 0 16px;
  padding: 4px 0 16px;
`

export const ButtonWrapper = styled.div<IButtonWrapper>`
  ${theme.breakpoints.down('md')} {
    display: ${({ shouldShowOnMobile }) => (shouldShowOnMobile ? 'flex' : 'none')};
  }
`
