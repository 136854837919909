import { ChangeEvent } from 'react'
import { ButtonV3 as Button, InputFieldV3 as Input } from '@provi/provi-components'
import GuarantorChecklistOptions from '~/components/molecules/GuarantorChecklistOptions'
import { masks } from '~/enums/masks'
import { useAppointGuarantorForm } from './hooks'
import * as S from './style'

interface IAppointGuarantorForm {
  title: string
}

const AppointGuarantorForm = ({ title }: IAppointGuarantorForm) => {
  const {
    setFieldTouched,
    errors,
    values,
    validateForm,
    setFieldValue,
    touched,
    handleEnterKey,
    handleSubmit,
    isValid,
    dirty,
    isChecklistOptions,
    isSendingData,
  } = useAppointGuarantorForm()

  return (
    <S.WrapperForm>
      <S.WrapperInput>
        <S.TitleForm>{title}</S.TitleForm>

        <Input
          label="CPF"
          placeholder="816.799.670-02"
          mask={masks.cpf}
          width="368px"
          value={values?.cpf || ''}
          name="cpf"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('cpf', e.target.value)}
          onBlur={() => {
            setFieldTouched('cpf')
            validateForm()
          }}
          isValid={!errors?.cpf && touched?.cpf}
          hasError={errors?.cpf && touched?.cpf}
          errorMessage={errors?.cpf}
          onKeyDown={handleEnterKey}
        />

        {!!isChecklistOptions?.showChecklist && (
          <GuarantorChecklistOptions checklistOptions={isChecklistOptions?.checklistOptions || ['']} iconVariant />
        )}
      </S.WrapperInput>

      <S.ButtonWrapper isVariant={!!isChecklistOptions?.showChecklist}>
        <Button
          text="Indicar avalista"
          type="submit"
          icon
          onClick={handleSubmit}
          disabled={!(isValid && dirty)}
          isLoading={isSendingData}
          mobileWidth="100%"
        />
      </S.ButtonWrapper>
    </S.WrapperForm>
  )
}

export default AppointGuarantorForm
