import { CheckBox } from '@provi/provi-components'
import * as S from './style'

interface IAgreeWithTos {
  onChange: () => void
  isChecked: boolean
  isDisabled: boolean
  margin?: string
}

const AgreeWithTos = ({ onChange, isChecked, isDisabled, margin }: IAgreeWithTos) => (
  <S.AgreeWithTosWrapper onChange={onChange} data-testid="termsofuse" margin={margin}>
    <CheckBox
      text={
        <S.TextTerms disabled={isDisabled}>
          Li e estou de acordo com os{' '}
          <a href="https://www.principia.net/termos-de-usuario-principiapay" target="_blank" rel="noopener noreferrer">
            Termos de uso
          </a>{' '}
          e a{' '}
          <a href="https://www.principia.net/politica-de-privacidade" target="_blank" rel="noopener noreferrer">
            Política de privacidade
          </a>{' '}
          da Principia.
        </S.TextTerms>
      }
      name="termsofuse"
      onChange={onChange}
      checked={!isDisabled && isChecked}
      disabled={isDisabled}
    />
  </S.AgreeWithTosWrapper>
)

export default AgreeWithTos
