import jwt from 'jsonwebtoken'
import { LocalStorageKeys } from '~/enums/localStorageKeys'

export const getCRID = (token?: string) => {
  const userToken = token || sessionStorage.getItem(LocalStorageKeys.user_token) || ''
  const decoded = jwt.decode(userToken) as { CreditRequestId: number }

  if (decoded) {
    return decoded.CreditRequestId
  }
}
