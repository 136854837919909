import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const RowContainer = styled.div`
  display: flex;

  ${theme.breakpoints.down('md')} {
    span {
      text-decoration-line: underline;
    }
  }
`
