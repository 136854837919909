import { theme } from '@provi/provi-components'
import * as S from './style'

const DateIcon = () => (
  <S.DateIconWrapper>
    <svg aria-label="Calendário" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1667 2.5V5M5.83333 2.5V5M2.5 8.33333H17.5M3.18182 4.16667H16.8182C17.1947 4.16667 17.5 4.43801 17.5 4.77273V16.8939C17.5 17.2287 17.1947 17.5 16.8182 17.5H3.18182C2.80526 17.5 2.5 17.2287 2.5 16.8939V4.77273C2.5 4.43801 2.80526 4.16667 3.18182 4.16667Z"
        stroke={theme.colors.black100}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </S.DateIconWrapper>
)

export default DateIcon
