import * as S from './styles'

interface IDescription {
  text: string
}

const Description = ({ text }: IDescription) => {
  return <S.DescriptionText>{text}</S.DescriptionText>
}

export default Description
