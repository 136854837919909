import * as S from './styles'

import { Link } from '~/components/atoms'
import { RowContact } from '~/components/molecules'

const ContactProvi = () => {
  return (
    <S.ContactContainer>
      <RowContact
        external
        text="+55 (11) 91444-7176"
        source="/assets/svg/whatsappLogo.svg"
        alt="ícone do whatsapp"
        url="https://api.whatsapp.com/send?phone=5511914447176&text=(PrincipiaPay) Oi, Principia! Pode me ajudar?"
      />
      <RowContact
        external
        text="pri@principia.net"
        source="/assets/svg/envelopeSimple.svg"
        alt="ícone de um envelope"
        url="mailto:pri@principia.net"
      />
      <S.LinksContainer>
        <Link text="Termos de uso e" url="https://www.principia.net/termos-de-usuario-principiapay" isVariant external />
        <Link text="Política de privacidade" url="https://www.principia.net/politica-de-privacidade" isVariant external />
      </S.LinksContainer>
    </S.ContactContainer>
  )
}

export default ContactProvi
