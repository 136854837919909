import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2 } from '~/styles/global'

export const ChecklistOptionContent = styled.div`
  display: flex;
  align-items: center;

  > svg {
    width: 21.6px;
    min-width: 21.6px;
    margin-right: 8px;
  }

  ${theme.breakpoints.down('md')} {
    > svg {
      width: 18px;
      min-width: 18px;
    }
  }
`

export const Option = styled(Body2)``
