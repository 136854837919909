import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { H4 } from '~/styles/global'

interface ITitleWrapper {
  isSearchOpen: boolean
}

interface IWrapper {
  isVariant: boolean | null
  isInIFrame?: boolean
}

export const HeaderWrapper = styled.header<IWrapper>`
  width: 100%;
  height: 72px;
  background: ${theme.colors.white100};
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  z-index: 100;

  ${({ isInIFrame }) =>
    isInIFrame &&
    css`
      display: none;
    `}

  ${theme.breakpoints.down('md')} {
    height: 64px;

    box-shadow: 0 0 0 2px ${theme.colors.bluePrimaryLight};
    border-radius: 0 0 16px 16px;

    ${({ isInIFrame }) =>
      isInIFrame &&
      css`
        display: flex;
      `}

    ${({ isVariant }) =>
      isVariant &&
      css`
        display: none;
      `}
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 28px;
`

export const TitleWrapper = styled.div<ITitleWrapper>`
  display: flex;
  align-items: center;
  gap: 16px;
  opacity: 1;
  transition: all 0.2s ease;

  ${theme.breakpoints.down('md')} {
    gap: 8px;
    width: 100%;
    height: 100%;

    ${({ isSearchOpen }) =>
      isSearchOpen &&
      css`
        position: absolute;
        opacity: 0;
      `}
  }
`

interface IDeviceHeader {
  isInCoursesScreen: boolean
}

export const DesktopHeader = styled.div<IDeviceHeader>`
  display: flex;
  align-items: center;
  gap: 16px;

  ${({ isInCoursesScreen }) =>
    !isInCoursesScreen &&
    css`
      ${theme.breakpoints.down('md')} {
        display: none;
      }
    `}

  ${theme.breakpoints.down('md')} {
    gap: 8px;
  }
`

export const MobileHeader = styled.div<IDeviceHeader>`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  width: 100%;
  user-select: none;
  height: 100%;
  z-index: 4;
  -webkit-tap-highlight-color: transparent;

  ${({ isInCoursesScreen }) =>
    isInCoursesScreen &&
    css`
      ${theme.breakpoints.down('md')} {
        display: none;
      }
    `}

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`

export const InvisibleClickable = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 64px;
  cursor: pointer;
  z-index: 4;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;

  ${theme.breakpoints.down('md')} {
    width: 24px;
    height: 24px;
  }
`

export const Title = styled(H4)`
  max-width: calc(100vw - 28px - 24px - 72px - 28px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1070px) {
    max-width: calc(100vw - 28px - 24px - 580px - 28px);
    font-size: 20px;
  }

  ${theme.breakpoints.down('md')} {
    max-width: calc(100vw - 28px - 24px - 72px - 28px);
    color: ${theme.colors.bluePrimary};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-transform: uppercase;

    ${theme.breakpoints.down('md')} {
      font-size: 13.2px;
      line-height: 20px;
      letter-spacing: 0.02em;
    }
  }
`

export const CardHeader = styled.div``

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const ButtonWrapper = styled.div`
  > button {
    white-space: nowrap;

    label {
      font-weight: 600;
      font-size: 13.2px;
      line-height: 20px;
      letter-spacing: 0.005em;
    }
  }

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`
