import { theme } from '@provi/provi-components'
import * as S from './style'

interface IOpenCloseCollapse {
  isOpen: boolean
}

const OpenCloseCollapse = ({ isOpen }: IOpenCloseCollapse) => (
  <S.OpenCloseCollapseWrapper isOpen={isOpen}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 11L8 6L13 11" stroke={theme.colors.black100} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </S.OpenCloseCollapseWrapper>
)

export default OpenCloseCollapse
