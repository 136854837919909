import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, Body2, Caption, loadingCSS } from '~/styles/global'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > div:nth-child(2) {
    min-height: 116px;
    min-width: 286px;
    margin: 90px 44px auto 0;
  }

  @media (max-width: 1040px) {
    min-height: 560px;
    position: relative;
    flex-direction: column;
    justify-content: unset;

    > div:nth-child(2) {
      margin: 0 24px;
    }
  }

  ${theme.breakpoints.down('md')} {
    min-height: 100%;

    > div:nth-child(2) {
      display: none;
    }
  }
`

export const PreSelfieWrapper = styled.div`
  max-width: 368px;
  min-height: 390px;
  margin: 35px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1040px) {
    min-height: 100%;

    > div:nth-child(2) {
      position: absolute;
      bottom: 24px;
    }
  }

  ${theme.breakpoints.down('md')} {
    min-height: 390px;

    div:nth-child(2) {
      min-width: 100%;
      position: relative;
    }
  }
`

export const OptionInputWrapper = styled.div``

export const OptionInputLoading = styled.div`
  height: 40px;
  width: 100%;
  margin: 8px 0;

  ${loadingCSS}
`

export const TitleInformation = styled(Body1)`
  margin-bottom: 27px;
`

export const TextInformation = styled(Body2)`
  margin-bottom: 16px;
`

export const ChecklistButtonLoading = styled.div`
  height: 40px;
  width: 25%;
  margin: 8px 0;

  ${loadingCSS}
`
export const ButtonText = styled(Caption).attrs({
  as: 'button',
})<{ disabled?: boolean }>`
  background: transparent;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? theme.colors.grey600 : theme.colors.bluePrimaryDark)};
  font-weight: bold;
`

export const ButtonTip = styled.button`
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  margin-left: 34px;
  margin-top: 8px;
`
