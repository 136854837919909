import styled from 'styled-components'
import { loadingCSS } from '~/styles/global'

export const Wrapper = styled.div`
  margin: 15px 16px;

  div {
    height: 40px;
    margin: 8px 0;

    ${loadingCSS}
  }
`

export const CardCourseLoading = styled.div`
  margin-top: 25px;
  height: 200px;
  width: 30%;
`

export const CourseNameLoading = styled.div`
  width: 25%;
`

export const CourseDescriptionLoading = styled.div`
  width: 53%;
`
