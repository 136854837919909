import { theme } from '@provi/provi-components'
import * as S from './style'

interface IPaymentFormType {
  type: string
}

const PaymentFormType = ({ type }: IPaymentFormType) => {
  const getIcon = () => {
    switch (type) {
      case 'CreditCard':
        return (
          <svg
            aria-label="Cartão de crédito"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.9997 16.0001H19.333M10.6663 16.0001H12.6663M1.3335 8.66664H22.6662M2.1535 4.66675H21.8452C22.2984 4.66675 22.6657 5.03155 22.6657 5.48156V18.5186C22.6657 18.9686 22.2984 19.3334 21.8452 19.3334H2.1535C1.70035 19.3334 1.33301 18.9686 1.33301 18.5186V5.48156C1.33301 5.03155 1.70035 4.66675 2.1535 4.66675Z"
              stroke={theme.colors.black100}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )
      case 'Boleto':
        return (
          <svg aria-label="Boleto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.9997 3.33307L22.6663 3.33331V7.99998M5.99967 20.6666H1.33301V15.9997M22.6663 15.9997V20.6666H17.9997M1.33301 7.99998V3.33331L5.99967 3.33307M6.66634 7.33307V16.6664M17.333 7.33307V16.6664M13.9997 7.33307V16.6664M9.99967 7.33307V16.6664"
              stroke={theme.colors.black100}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )
      case 'Pix':
        return (
          <svg aria-label="Pix" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
            <path d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z" />
          </svg>
        )
      default:
        return (
          <svg
            aria-label="Financiamento"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.6491 14.7624C20.0425 14.9979 18.8067 15.7046 18.2899 16.0815C17.7731 16.4584 16.9193 16.7175 15.8183 16.9296C15.5711 16.9767 15.279 17.0002 14.9645 17.0238C14.2005 17.0473 13.3017 16.9531 12.3131 16.8824C12.2457 16.8824 12.1783 16.8589 12.1109 16.8589C11.8637 16.8353 11.6615 16.6469 11.639 16.3878C11.6166 16.058 11.8637 15.7989 12.1558 15.846C12.2232 15.846 12.3131 15.8695 12.3805 15.8695C13.6612 15.9873 14.8746 16.1051 15.6385 15.9402C17.0541 15.6575 17.4585 14.7624 17.3686 14.0086C17.3012 13.3961 16.8519 12.8072 16.1553 12.8543C15.1667 12.925 13.9983 12.8543 12.8524 12.7601C11.5492 12.6659 10.2235 12.6188 9.27978 12.7366C8.22374 12.8543 6.08918 13.6788 4.20178 15.0215C3.28055 15.6811 2.24697 16.4584 1.61784 16.9531C1.30327 17.1887 1.23587 17.6598 1.48302 17.9896L4.22425 21.7115C4.44894 22.0412 4.89832 22.0884 5.21289 21.8528L6.22399 21.0283C6.47115 20.8399 6.78572 20.7692 7.05535 20.8634C8.89781 21.4759 10.8751 21.8764 12.1558 21.947C14.9645 22.0884 20.3795 18.602 21.9299 17.4713C23.4802 16.3642 22.3568 14.0793 20.6491 14.7624Z"
              stroke={theme.colors.black100}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.6663 9.99998C16.0596 9.99998 17.9997 8.05988 17.9997 5.66665C17.9997 3.27341 16.0596 1.33331 13.6663 1.33331C11.2731 1.33331 9.33301 3.27341 9.33301 5.66665C9.33301 8.05988 11.2731 9.99998 13.6663 9.99998ZM13.2442 3.35654V2.77118C13.2442 2.70846 13.289 2.66665 13.3561 2.66665H13.9602C14.0274 2.66665 14.0721 2.70846 14.0721 2.77118V3.33564C14.6987 3.37745 15.2133 3.81647 15.2581 4.40184C15.2581 4.46456 15.2133 4.52727 15.1462 4.52727H14.5197C14.4749 4.52727 14.4078 4.48546 14.4078 4.44365C14.363 4.2555 14.2064 4.13006 13.9826 4.13006H13.4456C13.1323 4.13006 12.8414 4.33912 12.7967 4.6318C12.7519 4.9663 13.0428 5.25898 13.4008 5.25898H13.8707C14.7211 5.25898 15.4147 5.94888 15.3252 6.76421C15.2581 7.39139 14.721 7.87222 14.0498 7.97675V8.56212C14.0498 8.62483 14.005 8.66665 13.9379 8.66665H13.3785C13.3113 8.66665 13.2666 8.62483 13.2666 8.56212V7.99766C12.64 7.95584 12.1254 7.51682 12.0806 6.93145C12.0806 6.86874 12.1254 6.80602 12.1925 6.80602H12.819C12.8638 6.80602 12.9309 6.84783 12.9309 6.88964C12.9757 7.0778 13.1323 7.20323 13.3561 7.20323H13.8931C14.2064 7.20323 14.4973 6.99417 14.542 6.68058C14.5868 6.34609 14.2959 6.05341 13.9379 6.05341H13.5351C12.7743 6.05341 12.0806 5.53076 12.0135 4.81996C11.9016 4.08825 12.4834 3.44017 13.2442 3.35654Z"
              fill={theme.colors.black100}
            />
          </svg>
        )
    }
  }

  return <S.Wrapper>{getIcon()}</S.Wrapper>
}

export default PaymentFormType
