import { useContext, useEffect } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import CheckoutSuccess from '../../CheckoutSuccess'
import * as S from './style'

const IncompleteCCB = () => {
  const { setIsLoadingData } = useContext(CheckoutContext)

  useEffect(() => {
    setIsLoadingData(false)
  }, [setIsLoadingData])

  return (
    <S.Wrapper>
      <CheckoutSuccess
        isSuccess
        title="Obrigada por aguardar"
        text={
          <>
            Fique de olho no seu e-mail para saber os próximos passos. Isso pode levar até 1 hora.
            <br /> <br />
            Até já!
          </>
        }
      />
    </S.Wrapper>
  )
}

export default IncompleteCCB
