import { useState, useCallback } from 'react'

export const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState({
    latitude: 0,
    longitude: 0,
  })

  const watchPosition = useCallback(() => {
    navigator.geolocation.watchPosition(
      (position) => {
        return setGeolocation({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        })
      },
      (e) => console.log(e, 'geolocation:watch:position'),
    )
  }, [])

  const getGeolocation = useCallback(() => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          })
        },
        (err) => {
          console.log(err, 'geolocation:get:current:position')
          reject(err)
        },
      )
    })
  }, [])

  if (typeof window === 'undefined') {
    return {
      latitude: 0,
      longitude: 0,
      getGeolocation: () => {},
      watchPosition: () => {},
    }
  }

  return {
    ...geolocation,
    getGeolocation,
    watchPosition,
  }
}
