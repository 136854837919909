import { memo } from 'react'
import { GuarantorChecklistMessage } from '~/components/organisms'
import * as S from './style'

const GuarantorChecklist = () => (
  <S.Wrapper>
    <GuarantorChecklistMessage />
  </S.Wrapper>
)

export default memo(GuarantorChecklist)
