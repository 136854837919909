import { useContext } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'

export const useGuarantorChecklistOptions = () => {
  const { isLoadingData } = useContext(CheckoutContext)

  return {
    isLoadingData,
  }
}
