import { memo } from 'react'
import { FeedbackHeaderCheckout } from '~/components/molecules'
import { CheckoutMessage } from '~/components/organisms'
import * as S from './style'

interface ICheckoutSuccess {
  isWithButton?: boolean
  isWithIcon?: boolean
  buttonText?: string | JSX.Element
  isTicket?: boolean
  isSuccess: boolean
  handleTryAgain?: () => void
  hrefURL?: string
  title: string
  text: string | JSX.Element | JSX.Element[]
  isPix?: boolean
  shouldTalkToUs?: boolean
}

const CheckoutSuccess = ({
  isSuccess,
  title,
  text,
  isWithButton,
  isWithIcon,
  handleTryAgain,
  buttonText,
  isTicket,
  hrefURL,
  isPix,
  shouldTalkToUs,
}: ICheckoutSuccess) => {
  return (
    <S.CheckoutSuccessWrapper>
      <FeedbackHeaderCheckout isSuccess={isSuccess} title={title} />

      <CheckoutMessage
        text={text}
        isTicket={isTicket}
        isPix={isPix}
        buttonText={buttonText}
        hrefURL={hrefURL}
        isWithButton={isWithButton}
        isWithIcon={isWithIcon}
        handleTryAgain={handleTryAgain}
        shouldTalkToUs={shouldTalkToUs}
      />
    </S.CheckoutSuccessWrapper>
  )
}

export default memo(CheckoutSuccess)
