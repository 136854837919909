import { useCallback, useContext } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { writeCheckoutStatus } from '~/services/api'

export const useBoletoForm = () => {
  const { chosenPaymentMethod, paymentInformationData, step, isSendingData } = useContext(CheckoutContext)
  const { cartData } = useContext(ParamsContext)

  const onSubmitButton = useCallback(async () => {
    if (!chosenPaymentMethod || !paymentInformationData?.response.Boleto?.paymentConditions[0].PartnerConditionId) {
      return
    }
    await writeCheckoutStatus({
      currentScreen: step,
      data: {
        paymentMethod: chosenPaymentMethod,
        PartnerConditionId: paymentInformationData?.response.Boleto?.paymentConditions[0].PartnerConditionId,
      },
    })
  }, [chosenPaymentMethod, paymentInformationData, step])

  const formatBusinessDaysText = (days: number) => {
    return days === 1 ? '1 dia' : `${days} dias`
  }

  return {
    onSubmitButton,
    cartData,
    formatBusinessDaysText,
    chosenPaymentMethod,
    isSendingData,
    paymentInformationData,
  }
}
