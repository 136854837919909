import useCountDown from '~/hooks/useCountdown'
import { useState, useContext, useEffect, useCallback } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readCreditApproved, writeCreditApproved, writeIncompleteCCB } from '~/services/api'
import { IReadCreditApprovedResponse } from '~/types/index'

export const useCreditApproved = () => {
  const { step, setStep, isLoadingData, setIsLoadingData, isSendingData } = useContext(CheckoutContext)
  const [requestData, setRequestData] = useState<IReadCreditApprovedResponse | null>(null)
  const [callNextRequests, setCallNextRequests] = useState(false)
  const [timer, setTimer] = useState(40000)

  const { timeLeft, actions } = useCountDown(timer, 1000)
  const { start } = actions

  const hasTimedOut = step === 'incompleteCCB' || (requestData?.screenWaitingTime && timeLeft === 0) || false

  const tryCreditApproved = useCallback(async () => {
    setCallNextRequests(true)

    await writeCreditApproved(step)
  }, [step, setCallNextRequests])

  useEffect(() => {
    const readCreditApprovedData = async () => {
      const result = await readCreditApproved()

      if (typeof result !== 'string') {
        setRequestData(result.response)
        setTimer(result.response.screenWaitingTime)
      }
    }

    readCreditApprovedData()
  }, [])

  useEffect(() => {
    const sendTimeoutMessage = async () => {
      const timeoutData = await writeIncompleteCCB()
      if (typeof timeoutData !== 'string') {
        // setting the step here in case ms_ccb is down
        setStep(timeoutData.nextScreen || 'incompleteCCB')
        setIsLoadingData(false)
      }
    }

    if (hasTimedOut) {
      sendTimeoutMessage()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTimedOut, setIsLoadingData])

  useEffect(() => {
    if (requestData && requestData.timeBetweenRequests && callNextRequests) {
      start()

      const timerInterval = setInterval(tryCreditApproved, requestData.timeBetweenRequests)
      return () => clearInterval(timerInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData, callNextRequests, start])

  const remainingTimeInPercentage = Math.floor((timeLeft / timer) * 100)

  return {
    screenWaitingTime: (requestData?.screenWaitingTime || 30000) / 1000,
    remainingTimeInPercentage,
    isLoadingData,
    tryCreditApproved,
    step,
    callNextRequests,
    isSendingData,
  }
}
