import { useMemo } from 'react'
import { ChecklistOption } from '~/components/atoms'
import { useGuarantorChecklistOptions } from './hooks'
import { Tip } from '..'
import * as S from './style'

interface IGuarantorChecklistOptions {
  checklistOptions: string[]
  iconVariant?: boolean
  withTip?: boolean
}

const GuarantorChecklistOptions = ({ checklistOptions, withTip = false, iconVariant = false }: IGuarantorChecklistOptions) => {
  const { isLoadingData } = useGuarantorChecklistOptions()

  const renderChecklistOptions = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return (
        <>
          <S.OptionInputLoading />
          <S.OptionInputLoading />
          <S.OptionInputLoading />
          <S.OptionInputLoading />
        </>
      )
    }

    return checklistOptions?.map((option: string) => <ChecklistOption key={option} text={option} isVariant={iconVariant} />)
  }, [isLoadingData, checklistOptions, iconVariant])

  return (
    <>
      <S.SubTitleInformation>
        Você pode indicar qualquer pessoa para ser avalista. Tenha em mãos o CPF dessa pessoa e confira se ela:
      </S.SubTitleInformation>

      <S.ChecklistOptionWrapper>{renderChecklistOptions}</S.ChecklistOptionWrapper>

      {withTip && (
        <Tip text="Avalista é uma pessoa que poderemos acionar e responsabilizar caso você deixe de pagar o seu financiamento." />
      )}
    </>
  )
}

export default GuarantorChecklistOptions
