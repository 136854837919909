import { useCallback, useContext, useEffect, useState } from 'react'
import { CoursesContext } from '~/contexts/CoursesContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import useDebounce from '~/hooks/useDebounce'
import { getCourses } from '~/services/api'
import { ICourses, ICoursesData } from '~/types/index'

interface IUseCarouselCourses {
  coursesData: ICoursesData
}

export const useCarouselCourses = ({ coursesData }: IUseCarouselCourses) => {
  const [newArrayCourses, setNewArrayCourses] = useState<ICourses[] | null>(coursesData.courses)
  const [searchedCourses, setSearchedCourses] = useState<ICourses[] | null>(null)
  const [hasNextPage, setHasNextPage] = useState(coursesData.hasNextPage)

  const { partnerSlug } = useContext(ParamsContext)
  const { enteredText, getParams } = useContext(CoursesContext)

  const debouncedValue = useDebounce<string>(enteredText, 250)
  const offset = newArrayCourses?.length || 0
  const limit = 6

  const fetchMoreData = useCallback(async () => {
    const { token, campaign } = getParams()
    const offsetParams = (!enteredText && hasNextPage && offset) || 0

    const response = await getCourses({
      slug: partnerSlug,
      campaignSlug: campaign,
      limit,
      token,
      offset: offsetParams,
      courseName: enteredText,
    })

    if (typeof response != 'string') {
      if (!enteredText) {
        // newCourses are response.courses without the courses already in the state
        const newCourses = response?.courses?.filter((course) => !newArrayCourses?.find((c) => c.CourseId === course.CourseId))

        newArrayCourses ? setNewArrayCourses([...newArrayCourses, ...newCourses]) : setNewArrayCourses(response?.courses)
        setHasNextPage(response?.hasNextPage)
        setSearchedCourses(null)
        return
      }

      setNewArrayCourses(null)
      setSearchedCourses(response?.courses)
      setHasNextPage(response?.hasNextPage)
    }
  }, [
    getParams,
    partnerSlug,
    setHasNextPage,
    hasNextPage,
    setSearchedCourses,
    setNewArrayCourses,
    offset,
    newArrayCourses,
    enteredText,
  ])

  useEffect(() => {
    fetchMoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const courses = searchedCourses || newArrayCourses

  return {
    fetchMoreData,
    hasNextPage,
    courses,
  }
}
