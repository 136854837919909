import * as S from './styles'

interface ILink {
  text: string | JSX.Element
  url: string
  isVariant?: boolean
  external?: boolean
}

const Link = ({ text, isVariant, url, external }: ILink) => {
  return (
    <S.LinkContainer href={url}>
      <S.SubLink href={url} target={external ? '_blank' : ''} isVariant={isVariant}>
        {text}
      </S.SubLink>
    </S.LinkContainer>
  )
}

export default Link
