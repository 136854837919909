import styled from 'styled-components'

export const Wrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin: none;
  padding: none;
  font-family: inherit;
`
