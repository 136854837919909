import { useCallback, useState } from 'react'

export const useInformationTextWithToolTip = () => {
  const [shouldShowToolTip, setShouldShowToolTip] = useState(false)

  const handleShowToolTip = useCallback(() => {
    if (!shouldShowToolTip) {
      setShouldShowToolTip(true)
      return
    }
    setShouldShowToolTip(false)
  }, [shouldShowToolTip])

  return {
    shouldShowToolTip,
    handleShowToolTip,
  }
}
