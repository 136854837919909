import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '~/components/atoms/ChevronRightIcon'
import MiniTextBox from '~/components/atoms/MiniTextBox'
import { WarningIcon } from '~/components/atoms/WarningIcon'
import { useDisclosure } from './hooks'

interface IDisclosureComponent {
  isOpen: boolean
  defaultOpen?: boolean
  children?: JSX.Element[]
  value: string | JSX.Element
  handleToggle?: () => void
  isError?: boolean
  errorMessage?: string
  fontSizeVariant?: boolean
  paddingVariant?: boolean
  title: string
}

export const DisclosureComponent = ({
  isOpen,
  defaultOpen,
  children,
  value,
  handleToggle,
  errorMessage,
  fontSizeVariant,
  paddingVariant,
  isError,
  title,
}: IDisclosureComponent) => {
  const { isFlex } = useDisclosure()

  return (
    <Disclosure
      as="div"
      className={`${isOpen && !paddingVariant ? 'py-4 sm:px-6 px-4' : 'sm:p-6 sm:py-4 p-4'}`}
      defaultOpen={defaultOpen}
    >
      <Disclosure.Button className={'w-full text-left'} onClick={handleToggle}>
        <MiniTextBox
          isOpen={isOpen}
          title={title}
          value={value}
          fontSizeVariant={fontSizeVariant}
          icon={isFlex && <ChevronRightIcon className={`${isOpen ? 'transform rotate-180' : ''}`} />}
        />
      </Disclosure.Button>
      <Disclosure.Panel
        static={true}
        className={`${isOpen ? 'max-h-full opacity-100' : 'transition-all opacity-0 duration-200 max-h-0 overflow-hidden'}`}
      >
        {children}
        {isError && (
          <div className="flex items-center mt-4 text-red-600">
            <WarningIcon className="mr-2" /> {errorMessage}
          </div>
        )}
      </Disclosure.Panel>
    </Disclosure>
  )
}
