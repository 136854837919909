import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

interface IFooterContainer {
  isInCheckout: boolean
  isVariant: boolean
}

export const FooterContainer = styled.footer<IFooterContainer>`
  margin: 0 28px;
  padding: 12px 0 19px;

  ${theme.breakpoints.down('md')} {
    ${({ isVariant }) =>
      isVariant &&
      css`
        padding-bottom: 100px;
      `}

    ${({ isInCheckout }) => isInCheckout && 'display: none'};
  }
`

export const FooterContent = styled.div`
  max-width: 1224px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(0, 80px)) 1fr;
  border-top: 2px solid ${theme.colors.bluePrimaryDark};
  padding-top: 5px;
  gap: 24px;

  > div {
    :nth-child(2) {
      grid-column: 4 / span 3;
    }

    :nth-child(3) {
      grid-column: 7 / span 3;
    }
  }

  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`
