import { useMemo } from 'react'
import { ButtonV3 as Button } from '@provi/provi-components'
import { ChecklistOption } from '~/components/atoms'
import { usePreSelfieContent } from './hooks'
import { Tip } from '~/components/molecules'
import * as S from './style'
import { WebviewModal } from '~/components/molecules/WebviewModal'

const PreSelfieContent = () => {
  const { checklistOptions, isLoadingData, isSendingData, handleSubmitButton, handleContinueFromPhone, isLocalLoading } =
    usePreSelfieContent()

  const renderChecklistOptions = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return (
        <>
          <S.OptionInputLoading />
          <S.OptionInputLoading />
          <S.OptionInputLoading />
          <S.OptionInputLoading />
        </>
      )
    }

    return checklistOptions?.map((option: string) => <ChecklistOption key={option} text={option} isVariant />)
  }, [isLoadingData, checklistOptions])

  return (
    <S.Wrapper>
      <WebviewModal />
      <S.PreSelfieWrapper>
        <S.OptionInputWrapper>
          <S.TitleInformation>Foto do rosto</S.TitleInformation>
          <S.TextInformation>
            Isso adiciona uma camada de segurança, já que nos ajuda a confirmar sua identidade. Siga essas dicas para tirar a
            foto:
          </S.TextInformation>

          {renderChecklistOptions}
        </S.OptionInputWrapper>

        {isLoadingData ? (
          <S.ChecklistButtonLoading />
        ) : (
          <Button text="Tirar foto" icon onClick={handleSubmitButton} isLoading={isSendingData && !isLocalLoading} />
        )}
      </S.PreSelfieWrapper>
      <Tip
        text="Caso não tenha câmera no computador, você pode receber um link por mensagem de texto para continuar pelo celular."
        button={
          <S.ButtonTip>
            <S.ButtonText onClick={handleContinueFromPhone} disabled={isLocalLoading}>
              Continuar pelo celular
            </S.ButtonText>
          </S.ButtonTip>
        }
      />
    </S.Wrapper>
  )
}

export default PreSelfieContent
