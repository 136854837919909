import * as S from './style'
import { InputField as Input } from '@provi/provi-components'
import { FocusEvent } from 'react'
import { masks } from '~/enums/masks'

interface IInputInvoiceCode {
  value?: string
  width?: string
  noMask?: boolean
}

const InputInvoiceCode = ({ value, width, noMask }: IInputInvoiceCode) => (
  <S.InputWrapper>
    <Input
      width={width}
      mask={noMask ? '' : masks.boleto}
      value={value}
      onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
      readOnly
    />
  </S.InputWrapper>
)

export default InputInvoiceCode
