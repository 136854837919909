import { useCallback, useContext } from 'react'
import { IPaymentForms } from 'types'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { gtagAddPaymentInfo } from '~/utils/gtag/gtagAddPaymentInfo'

export const usePaymentMethodCards = () => {
  const { chosenPaymentMethod, setChosenPaymentMethod, paymentInformationData, isPaymentInfoLoading } =
    useContext(CheckoutContext)

  const handlePaymentMethod = useCallback(
    (paymentMethod: IPaymentForms) => {
      setChosenPaymentMethod(paymentMethod)
      gtagAddPaymentInfo(paymentMethod)
    },
    [setChosenPaymentMethod],
  )

  return { isPaymentInfoLoading, chosenPaymentMethod, paymentInformationData, handlePaymentMethod }
}
