import { theme } from '@provi/provi-components'
import * as S from './style'

const AddButton = () => (
  <S.Wrapper>
    <svg
      aria-label="adicionar mais items no carrinho"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M11 2C11 1.44772 10.5523 1 10 1C9.44772 1 9 1.44772 9 2H11ZM9 18C9 18.5523 9.44772 19 10 19C10.5523 19 11 18.5523 11 18H9ZM18 11C18.5523 11 19 10.5523 19 10C19 9.44772 18.5523 9 18 9V11ZM2 9C1.44772 9 1 9.44772 1 10C1 10.5523 1.44772 11 2 11V9ZM9 2V10H11V2H9ZM9 10V18H11V10H9ZM10 11H18V9H10V11ZM10 9H2V11H10V9Z"
        fill={theme.colors.bluePrimary}
      />
    </svg>
  </S.Wrapper>
)

export default AddButton
