import { rules } from './rules'

const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig')

export const isWebview = (ua?: string) => {
  if (typeof window === 'undefined' && !ua?.length) {
    return false
  }

  if (!ua?.length) {
    ua = window.navigator.userAgent
  }

  return !!ua.match(webviewRegExp)
}
