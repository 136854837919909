import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { createPayment } from '~/services/api'
import { gtagAddPaymentInfo } from '~/utils/gtag/gtagAddPaymentInfo'
import { parsePrice } from '~/utils/parsePrice'

export const useCourseFinancingForm = () => {
  const {
    paymentInformationData,
    isInCourseFinancingReviewScreen,
    setIsInCourseFinancingReviewScreen,
    selectedPartnerConditionId,
    setSelectedPartnerConditionId,
    chosenPaymentMethod,
    step,
    isSendingData,
    isPaymentInfoLoading,
    selectedUpfront,
    setSelectedUpfront,
  } = useContext(CheckoutContext)
  const { cartData } = useContext(ParamsContext)
  const [isCaptchaValid, setIsCaptchaValid] = useState(false)
  const [isCourseFinancingLoading, setIsCourseFinancingLoading] = useState(true)
  const courseFinancingData = paymentInformationData?.response.CourseFinancing

  useEffect(() => {
    paymentInformationData?.response?.CourseFinancing && setIsCourseFinancingLoading(false)
    gtagAddPaymentInfo('CourseFinancing')
  }, [paymentInformationData?.response?.CourseFinancing])

  const handleSelectedPartnerConditionId = (partnerConditionId: number) => {
    if (partnerConditionId === selectedPartnerConditionId) {
      setSelectedPartnerConditionId(null)
      return
    }

    setSelectedPartnerConditionId(partnerConditionId)
  }

  const onRecaptchaChange = (token: string) => {
    if (token) {
      setIsCaptchaValid(true)
    } else {
      setIsCaptchaValid(false)
    }
  }

  useEffect(() => {
    window?.grecaptcha?.execute?.()
  }, [isInCourseFinancingReviewScreen])

  const handleSubmit = useCallback(async () => {
    if (!isInCourseFinancingReviewScreen) {
      setIsInCourseFinancingReviewScreen(true)
      return
    }

    if (selectedPartnerConditionId && chosenPaymentMethod && selectedUpfront !== null) {
      const body = {
        currentScreen: step,
        data: {
          PartnerConditionId: selectedPartnerConditionId,
          paymentMethod: chosenPaymentMethod,
          upfrontAmountInPercent: selectedUpfront,
        },
      }

      await createPayment(body)
    }
  }, [
    isInCourseFinancingReviewScreen,
    setIsInCourseFinancingReviewScreen,
    selectedPartnerConditionId,
    chosenPaymentMethod,
    step,
    selectedUpfront,
  ])

  const isSubmitDisabled = isInCourseFinancingReviewScreen ? !isCaptchaValid : !selectedPartnerConditionId

  const formatedCourseFinancingIOF = () => {
    const courseFinancing = paymentInformationData?.response?.CourseFinancing
    const registrationFeeAmountInCents =
      (courseFinancing?.options?.length > 0 && courseFinancing?.options[0]?.registrationFeeAmountInCents) || 0

    return {
      registrationFeeAmount: parsePrice(registrationFeeAmountInCents / 100, true) as string,
    }
  }

  useEffect(() => {
    // auto select last upfront and 12x partnerConditionId option
    const options = courseFinancingData?.options

    if (options?.length > 0) {
      const lastOption = options[options.length - 1]
      const lastOptionInstallments = lastOption.installments

      const bestInstallment =
        lastOptionInstallments.find((installment) => installment.installmentsToApply === 12) ||
        lastOptionInstallments[lastOptionInstallments.length - 1]

      const bestPartnerConditionId = bestInstallment.PartnerConditionId
      const lastUpfrontOption = lastOption.upfrontAmountInPercent

      setSelectedUpfront(lastUpfrontOption)
      setSelectedPartnerConditionId(bestPartnerConditionId)
    }
  }, [courseFinancingData?.options, setSelectedUpfront, setSelectedPartnerConditionId])

  return {
    courseFinancingData,
    selectedPartnerConditionId,
    handleSelectedPartnerConditionId,
    isInCourseFinancingReviewScreen,
    handleSubmit,
    isSubmitDisabled,
    cartData,
    onRecaptchaChange,
    isCourseFinancingLoading,
    formatedCourseFinancingIOF,
    isSendingData,
    isPaymentInfoLoading,
    userFirstName: paymentInformationData?.response?.client?.userFirstName,
  }
}
