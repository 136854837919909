import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 10 / span 4;

  a:nth-child(1),
  a:nth-child(2) {
    margin-left: 4px;
  }

  div + div {
    margin: 4px 0;
  }

  ${theme.breakpoints.down('md')} {
    div:nth-child(2) {
      margin: 7px 0 19px;
    }

    a {
      text-decoration: underline;
    }
  }
`

export const DataProviContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const LinksContainer = styled.div`
  display: flex;
  gap: 1px;
`
