import { useContext, memo } from 'react'
import * as S from './style'

import { ButtonRadio, CardCourseTotalPrice, RowSpecifics } from '~/components/molecules'
import { CourseSpecifics } from '~/components/organisms'
import { CoursesContext } from '~/contexts/CoursesContext'

import { ICourses } from 'types'

interface ICardCourse {
  classId?: number
  classNameCourse: string
  classDuration?: string | null
  isFullTime?: boolean | null
  modality?: string
  price: string
  attendType?: string
  campus?: string | null
  checkedRadio: boolean
  courseId: number
  workload?: ICourses['workload']
  removable?: boolean
  discountPercentage?: string | null
  totalPriceToPay?: number | string | null
  details?: string[] | null
  isProduct?: boolean
}

const CardCourse = ({
  classId,
  classNameCourse,
  classDuration,
  price,
  isFullTime,
  modality,
  attendType,
  campus,
  checkedRadio,
  courseId,
  workload,
  removable,
  discountPercentage,
  totalPriceToPay,
  details,
  isProduct,
}: ICardCourse) => {
  const { selectClass } = useContext(CoursesContext)

  return (
    <S.CardCourseWrapper
      onClick={() => removable && selectClass({ classId, courseId, courseClassName: classNameCourse, price })}
      selected={checkedRadio}
      data-testid={classId}
    >
      <S.CardCourseSpecifics>
        <S.Heading>
          <S.TextOverline>{classNameCourse}</S.TextOverline>
          <S.DurationCourse>{classDuration}</S.DurationCourse>
        </S.Heading>
        {details && (
          <S.DetailContent>
            {details.map((detail, index) => (
              <RowSpecifics key={index} text={detail} source="/assets/svg/isa-detail.checked.svg" alt="" />
            ))}
          </S.DetailContent>
        )}
        <CourseSpecifics
          attendType={attendType}
          isFullTime={isFullTime === null ? null : isFullTime}
          modality={modality}
          campus={campus}
          workload={workload}
        />
        <CardCourseTotalPrice discountPercentage={discountPercentage} price={price} totalPriceToPay={totalPriceToPay} />
      </S.CardCourseSpecifics>
      <ButtonRadio
        onClick={() => removable && selectClass({ classId, courseId, courseClassName: classNameCourse, price })}
        checked={checkedRadio}
        classId={classId}
        courseId={courseId}
        removable={removable}
        isProduct={isProduct}
      />
    </S.CardCourseWrapper>
  )
}

export default memo(CardCourse)
