import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, Body2, loadingCSS } from '~/styles/global'

export const PreDocumentContent = styled.section`
  display: flex;
  flex-direction: column;

  max-width: 368px;
  margin: 35px 24px 24px;

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: calc(100vh - (200px));
  }
`

export const MainPreDocumentContent = styled.div``

export const PreDocumentTitle = styled(Body1)`
  margin-bottom: 27px;
`

export const PreDocumentSubTitle = styled(Body2)``

export const RadioWrapper = styled.form`
  min-height: 310px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const RadioContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 268px;
  margin: 34px 0 32px;

  ${theme.breakpoints.down('md')} {
    max-width: 246px;
    margin: 32px 0 33px;
  }

  @media (max-width: 300px) {
    flex-direction: column;
    height: 110px;
  }
`

export const InformationsWrapper = styled.div`
  margin-bottom: 24px;

  > p {
    margin-bottom: 10px;
  }

  ${theme.breakpoints.down('md')} {
    > p {
      margin-bottom: 16px;
    }
  }
`

export const DocInputLoading = styled.div`
  height: 40px;
  width: 35%;
  margin: 8px 0;

  ${loadingCSS}
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 80px;

    > div:last-child {
      min-width: 100%;
    }
  }
`

export const LabelButtonCamera = styled(Body2)`
  color: ${theme.colors.bluePrimary};
  font-weight: 600;
  cursor: pointer;
`

export const ButtonCamera = styled.button`
  margin: 0px 8px;
  border: none;
  background: transparent;

  ${({ disabled }) =>
    disabled &&
    css`
      > p {
        color: ${theme.colors.greyPrimary};
        cursor: default;
      }
    `}
`
