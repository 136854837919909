import * as S from './style'

import { RowSpecifics } from '~/components/molecules'

import { ICourses } from 'types'

interface IListSpecifics {
  isFullTime: boolean
  attendType: string
  modality: string
  campus: string | null
  workload: ICourses['workload']
}

const ListSpecifics = ({ isFullTime, attendType, modality, campus, workload }: IListSpecifics) => {
  return (
    <S.ListSpecificsWrapper>
      {modality?.toLowerCase() === 'online' && modality != null ? (
        <>
          <RowSpecifics text="Remoto" source={'/assets/svg/course-icons/course-remote.svg'} alt="Curso remoto" />
          {attendType != null && (
            <RowSpecifics
              text={attendType || 'Aulas gravadas'}
              source={
                attendType?.toLowerCase() === 'ao vivo'
                  ? '/assets/svg/course-icons/course-live.svg'
                  : '/assets/svg/course-icons/course-recorded.svg'
              }
              alt="Curso remoto"
            />
          )}
        </>
      ) : (
        <>
          {modality != null && (
            <RowSpecifics text={modality} source={'/assets/svg/course-icons/course-presential.svg'} alt="Curso remoto" />
          )}
          {campus != null && (
            <RowSpecifics text={campus} source="/assets/svg/course-icons/course-unity.svg" alt="Unidade do curso" />
          )}
        </>
      )}
      {isFullTime != null && (
        <RowSpecifics
          text={isFullTime ? 'Tempo integral' : 'Meio período'}
          source="/assets/svg/course-icons/course-period.svg"
          alt="Período do curso"
        />
      )}
      {workload != null && (
        <RowSpecifics
          text={`${String(workload)} horas`}
          source="/assets/svg/course-icons/course-workload.svg"
          alt={`${String(workload)} horas de curso`}
        />
      )}
    </S.ListSpecificsWrapper>
  )
}

export default ListSpecifics
