import * as S from './styles'

import { ImageProps } from 'next/image'

const Svg = ({ ...rest }: ImageProps) => {
  return (
    <S.SvgContainer>
      <S.Svg {...rest} />
    </S.SvgContainer>
  )
}

export default Svg
