import * as S from './styles'

import { Description } from '~/components/atoms'

interface IDataProvi {
  companyName: string
  cnpj: string
}

const DataProvi = ({ companyName, cnpj }: IDataProvi) => {
  return (
    <S.DataProviContainer>
      <Description text={companyName} />
      <Description text={cnpj} />
    </S.DataProviContainer>
  )
}

export default DataProvi
