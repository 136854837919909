import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Caption } from '~/styles/global'

export const TipWrapper = styled.div`
  padding: 12px;
  max-width: 368px;
  border-radius: 8px;
  background-color: ${theme.colors.bluePrimaryLight};
`

export const TipContent = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    min-width: 24px;
    margin-right: 8px;
  }
`

export const TipText = styled(Caption)`
  max-width: 312px;
`
