import { Radio } from '@provi/provi-components'
import { useCallback, useMemo } from 'react'
import { ICourseFinancingInstallment, IPaymentResponse } from 'types'
import { formatMonthlyInterestText, parsePrice } from '~/utils/index'
import { useCourseFinancingPartnerConditionFlex } from './hooks'

import { formatDate } from '~/utils/formatDate'
import { DisclosureComponent } from '../Disclosure'
import * as S from './style'

interface ICourseFinancingPartnerConditionFlex {
  data: IPaymentResponse['CourseFinancing']
  selectedPartnerConditionId: number | null
  handleSelectedPartnerConditionId: (id: number) => void
}

interface IUpfrontBox {
  upfrontAmountInPercent: number
  maxSaving?: number
  upfrontAmountInCents: number
  registrationFeeAmountInCents?: number
}

const CourseFinancingPartnerConditionFlex = ({
  data,
  selectedPartnerConditionId,
  handleSelectedPartnerConditionId,
}: ICourseFinancingPartnerConditionFlex) => {
  const {
    handleUpfrontSelect,
    selectedUpfront,
    installments,
    upfrontObjectSelected,
    setIsUpfrontOpen,
    isUpfrontOpen,
    isInstallmentOpen,
    setIsInstallmentOpen,
    isError,
    handleInstallmentSelect,
    valuesInstallmentBoxes,
    isLoading,
    showTaxesOnCheckout,
  } = useCourseFinancingPartnerConditionFlex({
    handleSelectedPartnerConditionId,
    selectedPartnerConditionId,
    data,
  })

  const upfrontBox = useCallback(
    ({ upfrontAmountInPercent, upfrontAmountInCents, registrationFeeAmountInCents }: IUpfrontBox) => {
      const isUpfrontActive = selectedUpfront === upfrontAmountInPercent
      const totalUpfrontAmountInCents = upfrontAmountInCents + (registrationFeeAmountInCents || 0)
      return (
        <S.CourseFinancingPartnerConditionFlexWrapper
          key={upfrontAmountInPercent}
          onClick={() => handleUpfrontSelect(upfrontAmountInPercent)}
        >
          <S.PartnerConditionLabel as="span">
            <Radio
              text={''}
              onChange={() => handleUpfrontSelect(upfrontAmountInPercent)}
              checked={isUpfrontActive}
              variant={'secondary'}
              name={`upfront-${upfrontAmountInPercent}`}
            />
            {parsePrice(totalUpfrontAmountInCents / 100)}
          </S.PartnerConditionLabel>
        </S.CourseFinancingPartnerConditionFlexWrapper>
      )
    },
    [selectedUpfront, handleUpfrontSelect],
  )

  const installmentBox = useCallback(
    ({ PartnerConditionId, installmentsToApply, monthlyInterest, pmt }: ICourseFinancingInstallment) => {
      const isInstallmentActive = selectedPartnerConditionId === PartnerConditionId

      return (
        <S.CourseFinancingPartnerConditionFlexWrapper
          key={PartnerConditionId}
          onClick={() => {
            handleSelectedPartnerConditionId(PartnerConditionId)
            setIsInstallmentOpen((prev) => !prev)
          }}
        >
          <S.PartnerConditionLabel as="span">
            <Radio
              text={''}
              onChange={() => handleSelectedPartnerConditionId(PartnerConditionId)}
              checked={isInstallmentActive}
              name={`partner-condition-${selectedPartnerConditionId}`}
            />
            {installmentsToApply}x {parsePrice(pmt / 100)} <div className="mr-1" /> (Juros{' '}
            {formatMonthlyInterestText(monthlyInterest * 100)})
          </S.PartnerConditionLabel>
        </S.CourseFinancingPartnerConditionFlexWrapper>
      )
    },
    [handleSelectedPartnerConditionId, selectedPartnerConditionId, setIsInstallmentOpen],
  )

  const renderUpFrontDisclosure = useMemo(() => {
    const dueDate = formatDate(data?.upfrontInstalmentDueDate)
    const totalUpfrontAmountInCents =
      (upfrontObjectSelected?.upfrontAmountInCents || 0) + (upfrontObjectSelected?.registrationFeeAmountInCents || 0)
    const valuesUpfrontBoxes = selectedUpfront
      ? `${parsePrice(totalUpfrontAmountInCents / 100, true)}`
      : `Selecione o valor da entrada para pagar até o dia ${dueDate}`

    return (
      <DisclosureComponent
        title="Entrada"
        isOpen={isUpfrontOpen}
        fontSizeVariant={!selectedUpfront}
        value={valuesUpfrontBoxes}
        errorMessage={'Escolha o valor da entrada.'}
        isError={isError}
        defaultOpen={true}
        handleToggle={() => {
          setIsUpfrontOpen((prev) => !prev)
          setIsInstallmentOpen(false)
        }}
      >
        {data?.options?.map((option) => {
          return upfrontBox(option)
        })}
      </DisclosureComponent>
    )
  }, [
    isUpfrontOpen,
    data?.options,
    selectedUpfront,
    setIsUpfrontOpen,
    setIsInstallmentOpen,
    upfrontObjectSelected,
    data?.upfrontInstalmentDueDate,
    upfrontBox,
    isError,
  ])

  const renderInstallmentsDisclosure = useMemo(() => {
    return (
      <DisclosureComponent
        title="Parcelas"
        isOpen={isInstallmentOpen}
        fontSizeVariant={!installments}
        value={isLoading && !isInstallmentOpen ? <S.Loading /> : valuesInstallmentBoxes}
        defaultOpen={false}
        handleToggle={handleInstallmentSelect}
      >
        {upfrontObjectSelected?.installments?.map((installment) => {
          return installmentBox(installment)
        })}
      </DisclosureComponent>
    )
  }, [
    installments,
    isInstallmentOpen,
    handleInstallmentSelect,
    upfrontObjectSelected,
    isLoading,
    installmentBox,
    valuesInstallmentBoxes,
  ])

  const totalPrice = useMemo(() => {
    if (isLoading) {
      return (
        <>
          R$ <S.Loading isVariant />
        </>
      )
    }

    if (upfrontObjectSelected && installments) {
      return (
        <>
          {parsePrice(installments?.total / 100)}
          <small className="ml-1 text-sm align-top text-brand-primary">&#42;</small>
        </>
      )
    }

    return 'R$ -'
  }, [isLoading, upfrontObjectSelected, installments])

  return (
    <div className={`w-full border-2 border-solid border-brand-primaryLight rounded-lg sm:w-[464px]`}>
      {renderUpFrontDisclosure} <hr className="border-0 border-b-2 border-solid border-brand-primaryLight" />
      {renderInstallmentsDisclosure}
      <div className={showTaxesOnCheckout ? 'h-[59px] flex items-center bg-brand-primaryLight px-6 overflow-hidden' : 'hidden'}>
        <S.PartnerConditionPrice>Total a pagar: {totalPrice}</S.PartnerConditionPrice>
      </div>
    </div>
  )
}

export default CourseFinancingPartnerConditionFlex
