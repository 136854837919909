import { parsePrice, parseDiscount } from '~/utils/index'
import * as S from './style'

interface ICardCourseTotalPrice {
  discountPercentage?: string | null
  totalPriceToPay?: number | string | null
  price: string
}

const CardCourseTotalPrice = ({ discountPercentage, price, totalPriceToPay }: ICardCourseTotalPrice) => {
  const currentCoursePrice = discountPercentage ? totalPriceToPay : price

  return (
    <S.CardCourseTotalPriceWrapper>
      {discountPercentage && <S.CardTotalItemPrice hasDiscount={true}>{parsePrice(price || 0)}</S.CardTotalItemPrice>}
      <S.CardFullPrice>
        <S.CardTotalPriceValue>{parsePrice(currentCoursePrice || 0)}</S.CardTotalPriceValue>
        {discountPercentage && (
          <S.CardTotalItemPrice isVariant={true}>{parseDiscount(discountPercentage || 0)} OFF</S.CardTotalItemPrice>
        )}
      </S.CardFullPrice>
    </S.CardCourseTotalPriceWrapper>
  )
}

export default CardCourseTotalPrice
