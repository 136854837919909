import { useCallback, useContext, useMemo, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { useShowTaxesOnCheckout } from '~/hooks/useShowTaxesOnCheckout'
import { IPaymentResponse } from '~/types/index'
import { formatMonthlyInterestText } from '~/utils/formatMonthlyInterestText'
import { parsePrice } from '~/utils/parsePrice'

interface IUseCourseFinancingPartnerConditionFlex {
  handleSelectedPartnerConditionId: (id: number) => void
  selectedPartnerConditionId: number
  data: IPaymentResponse['CourseFinancing']
}

export const useCourseFinancingPartnerConditionFlex = ({
  handleSelectedPartnerConditionId,
  selectedPartnerConditionId,
  data,
}: IUseCourseFinancingPartnerConditionFlex) => {
  const { showTaxesOnCheckout } = useShowTaxesOnCheckout()
  const { selectedUpfront, setSelectedUpfront } = useContext(CheckoutContext)
  const [isLoading, setIsLoading] = useState(false)

  const [isUpfrontOpen, setIsUpfrontOpen] = useState(true)
  const [isInstallmentOpen, setIsInstallmentOpen] = useState(true)
  const [isError, setIsError] = useState(false)

  const upfrontObjectSelected = data.options.find((option) => option?.upfrontAmountInPercent === selectedUpfront)
  const installments = useMemo(
    () =>
      upfrontObjectSelected?.installments.find((installment) => installment.PartnerConditionId === selectedPartnerConditionId),
    [upfrontObjectSelected?.installments, selectedPartnerConditionId],
  )

  const handleUpfrontSelect = useCallback(
    (amount: number | null) => {
      setIsLoading(true)

      setTimeout(() => {
        setIsLoading(false)
      }, 600)
      if (amount === selectedUpfront) {
        setIsUpfrontOpen(true)
        handleSelectedPartnerConditionId(null)
        setSelectedUpfront(null)
        return
      }

      setIsError(false)
      setIsUpfrontOpen(false)
      setSelectedUpfront(amount)
      installments ? setIsInstallmentOpen(false) : setIsInstallmentOpen(true)
    },
    [
      selectedUpfront,
      setSelectedUpfront,
      handleSelectedPartnerConditionId,
      installments,
      setIsError,
      setIsInstallmentOpen,
      setIsLoading,
      setIsUpfrontOpen,
    ],
  )

  const handleInstallmentSelect = useCallback(() => {
    if (!upfrontObjectSelected?.upfrontAmountInCents) {
      setIsError(true)
      return
    }

    setIsUpfrontOpen(false)
    setIsInstallmentOpen((prev) => !prev)
    setIsError(false)
  }, [setIsInstallmentOpen, setIsUpfrontOpen, setIsError, upfrontObjectSelected?.upfrontAmountInCents])

  const textInstallmentBoxes = installments
    ? `${installments?.installmentsToApply}x ${parsePrice(installments?.pmt / 100, true)} (Juros ${formatMonthlyInterestText(
        installments?.monthlyInterest * 100,
      )})`
    : `Você pode parcelar o valor restante em até ${data?.maxInstallment} vezes.`

  const valuesInstallmentBoxes = isInstallmentOpen
    ? 'Agora, selecione em quantas parcelas você deseja pagar o valor restante:'
    : textInstallmentBoxes

  return {
    selectedUpfront,
    handleUpfrontSelect,
    isLoading,
    installments,
    upfrontObjectSelected,
    isUpfrontOpen,
    setIsUpfrontOpen,
    isInstallmentOpen,
    setIsInstallmentOpen,
    handleInstallmentSelect,
    valuesInstallmentBoxes,
    isError,
    showTaxesOnCheckout,
  }
}
