import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2 } from '~/styles/global'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const CartTitle = styled(Body2)``
