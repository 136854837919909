import { useContext } from 'react'
import { CartIcon } from '~/components/atoms'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import * as S from './style'

interface ICartHeader {
  number: number
}

const CartHeader = ({ number }: ICartHeader) => {
  const { step } = useContext(CheckoutContext)

  return (
    <S.Wrapper>
      <S.CartTitle id="resumo-do-pedido">Resumo do pedido</S.CartTitle>
      {step !== 'checkoutSuccess' && <CartIcon number={number} />}
    </S.Wrapper>
  )
}

export default CartHeader
