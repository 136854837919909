import * as S from './style'

import { CourseName, Label } from '~/components/atoms'

interface ICourseHeading {
  courseName: string
  courseDescription: string | null
  activeClasses: number
}

const CourseHeading = ({ courseName, courseDescription, activeClasses }: ICourseHeading) => {
  return (
    <S.CourseHeadingWrapper>
      <div>
        <CourseName courseName={courseName} isUnavailable={!activeClasses} />
        <Label text={courseDescription} isUnavailable={!activeClasses} />
        {!!activeClasses && (
          <Label text={`${activeClasses} ${activeClasses > 1 ? 'turmas disponíveis' : 'turma disponível'}:`} isVariant />
        )}
      </div>
    </S.CourseHeadingWrapper>
  )
}

export default CourseHeading
