import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  :hover {
    path {
      stroke: ${theme.colors.bluePrimaryDark};
      transition: stroke 0.1s ease;
    }
  }
`
