import { theme } from '@provi/provi-components'
import * as S from './style'

interface ITrashCanIcon extends React.HTMLProps<HTMLButtonElement> {
  courseName?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const TrashCanIcon = ({ courseName, onClick }: ITrashCanIcon) => (
  <S.Wrapper aria-label={`remover ${courseName || ''} do carrinho`} title="Remover" onClick={onClick}>
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M18 8H2M8.00031 12L8 15M12 12V15M16 8C16 8 16 18 16 19C16 20 15 20 15 20H5C5 20 4 20 4 19C4 18 4 8 4 8M14 8V5C14 5 14 4 13 4C12 4 8 4 7 4C6 4 6 5 6 5V8"
        stroke={theme.colors.bluePrimary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </S.Wrapper>
)

export default TrashCanIcon
