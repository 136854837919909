import { CloseButton } from '~/components/atoms'
import * as S from './style'

interface ITooltip {
  text: string | JSX.Element
}

const Tooltip = ({ text }: ITooltip) => (
  <S.TooltipWrapper>
    <S.TooltipText as="p">{text}</S.TooltipText>
    <CloseButton />
  </S.TooltipWrapper>
)

export default Tooltip
