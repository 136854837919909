import { theme } from '@provi/provi-components'

interface ISuccessIcon {
  isVariant?: boolean
}

const SuccessIcon = ({ isVariant }: ISuccessIcon) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
      fill={isVariant ? theme.colors.bluePrimary : theme.colors.white100}
    />
    <path
      d="M30 15L17.5 27.5L10 20"
      stroke={isVariant ? theme.colors.white100 : theme.colors.bluePrimary}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SuccessIcon
