import { useCallback, useContext, useEffect } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { CoursesContext } from '~/contexts/CoursesContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { writeExistingRequests } from '~/services/api'
import { IWriteExistingRequests } from '~/types/index'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import { translatePaymentForm } from '~/utils/translatePaymentForm'

export const useExistingRequests = () => {
  const { existingRequestsData, isSendingData, setIsRedirecting } = useContext(CheckoutContext)
  const { closeModal, modalRef, openModal } = useContext(CoursesContext)
  const { route, router } = useContext(ParamsContext)

  const availablePaymentMethods = existingRequestsData?.map((request) =>
    request?.availablePaymentMethod?.map((paymentMethod, index) => {
      if (index === request?.availablePaymentMethod?.length - 1) {
        return translatePaymentForm(paymentMethod) + '.'
      }

      return translatePaymentForm(paymentMethod) + ', '
    }),
  )

  const proceed = useCallback(
    async (creditRequestId: IWriteExistingRequests) => {
      const response = await writeExistingRequests(creditRequestId)
      if (typeof response != 'string') {
        if (route !== '/checkout/[slug]' && response.partnerSlug) {
          setIsRedirecting(true)
          await router.push(changeRouteKeepParams(router.asPath, `/checkout/${response.partnerSlug}`))
        }
      }
    },
    [route, router, setIsRedirecting],
  )

  useEffect(() => {
    openModal()
  }, [openModal])

  return {
    existingRequestsData,
    modalRef,
    closeModal,
    proceed,
    isSendingData,
    availablePaymentMethods,
  }
}
