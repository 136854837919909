export const checklistInformationsCNH = [
  'Remova o plástico de proteção;',
  'Desdobre o documento;',
  'Não use flash ou lanterna.',
]

export const checklistInformationsRG = [
  'Remova o plástico de proteção;',
  'Fotografe um lado de cada vez;',
  'Não use flash ou lanterna.',
]
