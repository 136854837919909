import { theme } from '@provi/provi-components'
import * as S from './style'

interface ITip {
  text: string | JSX.Element
  button?: JSX.Element
}

const InformationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke={theme.colors.black100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 11.25H12V16.5H12.75"
      stroke={theme.colors.black100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8125 9C12.4338 9 12.9375 8.49632 12.9375 7.875C12.9375 7.25368 12.4338 6.75 11.8125 6.75C11.1912 6.75 10.6875 7.25368 10.6875 7.875C10.6875 8.49632 11.1912 9 11.8125 9Z"
      fill={theme.colors.black100}
    />
  </svg>
)

const Tip = ({ text, button }: ITip) => (
  <S.TipWrapper>
    <S.TipContent>
      <InformationIcon />
      <S.TipText>{text}</S.TipText>
    </S.TipContent>
    {button}
  </S.TipWrapper>
)

export default Tip
