import * as S from './style'

import { Radio } from '@provi/provi-components'
import { memo } from 'react'

interface IButton {
  checked: boolean
  classId: number
  courseId: number
  removable: boolean
  onClick: (classId: number) => void
  isProduct?: boolean
}

const ButtonRadio = ({ checked, onClick, classId, courseId, removable, isProduct, ...rest }: IButton) => {
  const selectedText = isProduct ? 'Produto selecionado' : 'Turma selecionada'
  const selectText = isProduct ? 'Selecionar produto' : 'Selecionar turma'

  return (
    <S.ButtonWrapper selected={checked} removable={removable}>
      <Radio
        text={checked ? selectedText : selectText}
        onClick={() => onClick(classId)}
        onChange={() => {}}
        checked={checked}
        variant={checked ? 'primary' : 'white'}
        name={`course-${courseId}`}
        {...rest}
      />
    </S.ButtonWrapper>
  )
}

export default memo(ButtonRadio)
