import { parseDiscount, parsePrice } from '~/utils/index'
import * as S from './style'

interface ICartTotalPrice {
  discountPercentage?: string | null
  totalPrice?: string | null
  totalPriceToPay?: number | string | null
  hasInstallment?: boolean
}

const CartTotalPrice = ({ discountPercentage, totalPrice, totalPriceToPay, hasInstallment }: ICartTotalPrice) => (
  <S.CartTotalPriceWrapper>
    {discountPercentage && (
      <S.CartTotalDiscount>
        <S.CartItemDiscountedItem>
          <S.CartTotalItemPrice hasDiscount={true}>{parsePrice(totalPrice || 0)}</S.CartTotalItemPrice>
          <S.CartTotalItemPrice isVariant={true}>{parseDiscount(discountPercentage)} OFF</S.CartTotalItemPrice>
        </S.CartItemDiscountedItem>
      </S.CartTotalDiscount>
    )}
    <S.CartFullPrice>
      <S.CartTotalPriceLabel>{`Total${hasInstallment ? ' Parcelado' : ''}:`}</S.CartTotalPriceLabel>
      <S.CartTotalPriceValue title={`Total do carrinho: ${parsePrice(totalPriceToPay || 0, true)}`}>
        {parsePrice(totalPriceToPay || 0)}
      </S.CartTotalPriceValue>
    </S.CartFullPrice>
  </S.CartTotalPriceWrapper>
)

export default CartTotalPrice
