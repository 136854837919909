import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2, Overline } from '~/styles/global'

interface ICardCourseWrapper {
  selected: boolean
}

export const CardCourseWrapper = styled.div<ICardCourseWrapper>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 256px;
  min-height: 150px;

  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-radius: 8px;
  margin-top: 3px;

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid ${theme.colors.bluePrimary};
    `}

  ${theme.breakpoints.down('md')} {
    width: 214px;
    min-height: 256px;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Heading = styled.div``

export const CardCourseSpecifics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  padding: 10px 16px;
  user-select: none;
`

export const TextOverline = styled(Overline)`
  color: ${theme.colors.bluePrimary};
`

export const DurationCourse = styled(Body2)`
  color: ${theme.colors.bluePrimary};
`

export const DetailContent = styled.div`
  margin: 5px 0 -20px;

  div {
    div:first-child {
      width: 15px;
    }
  }
`
