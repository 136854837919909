import { toast } from 'react-toastify'

const copyToClipboardWithCommand = (content: string) => {
  const el = document.createElement('textarea')
  el.value = content
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const copyToClipboard = (text: string) => {
  if (navigator.clipboard && navigator.permissions) {
    navigator.clipboard
      .writeText(text)
      .then(() =>
        toast.success('Copiado com sucesso', {
          toastId: 'copyToClipboard',
        }),
      )
      .catch(() => {
        copyToClipboardWithCommand(text)
      })
  } else if (document.queryCommandSupported('copy')) {
    copyToClipboardWithCommand(text)

    toast.success('Copiado com sucesso', {
      toastId: 'copyToClipboard',
    })
  }
}
