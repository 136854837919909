import styled, { css } from 'styled-components'

export const CourseFinancingSelectorWrapper = styled.div<{ isFlex?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 368px;

  ${({ isFlex }) =>
    isFlex &&
    css`
      gap: 0;
    `}
`
