import { KeyboardEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { IPopulatePreRegisterInfo } from 'types'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { errorMessages, requiredErrorMessages } from '~/enums/defaultMessages'
import { validateCPF } from '~/utils/validation'
import { removeMask } from '~/utils/removeMask'
import { readAppointGuarantor, writeAppointGuarantor } from '~/services/api'

interface IChecklistOptions {
  showChecklist: boolean
  checklistOptions?: string[]
}

export const useAppointGuarantorForm = () => {
  const { step, setIsLoadingData, isSendingData } = useContext(CheckoutContext)
  const [isChecklistOptions, setChecklistOptions] = useState<IChecklistOptions>({
    showChecklist: false,
    checklistOptions: [''],
  })

  const proceed = useCallback(
    async (cpfValue) => {
      const removeCPFMask = removeMask(cpfValue.cpf) as string

      await writeAppointGuarantor({
        currentScreen: step,
        data: {
          CPF: removeCPFMask,
        },
      })
    },
    [step],
  )

  const { values, errors, touched, handleSubmit, isValid, dirty, setFieldValue, validateForm, setFieldTouched, isSubmitting } =
    useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
        cpf: '',
      },
      validationSchema: Yup.object({
        cpf: Yup.string().required(requiredErrorMessages.guarantorCpf).min(14, errorMessages.cpf),
      }),

      onSubmit: proceed,

      validate: (fields: IPopulatePreRegisterInfo) => {
        const fieldsArray = Object.keys(fields)
        return fieldsArray.reduce((prev, cur) => {
          if (cur === 'cpf' && fields[cur] && fields[cur].length === 14 && !validateCPF(fields[cur])) {
            return {
              ...prev,
              [cur]: errorMessages.invalidCPF,
            }
          }
          return { ...prev }
        }, {})
      },
    })

  const handleEnterKey = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      !isSubmitting && handleSubmit(event)
    }
  }

  useEffect(() => {
    async function getGuarantorChecklist() {
      const result = await readAppointGuarantor()

      if (typeof result != 'string') {
        setChecklistOptions(result?.response)
      }
    }

    getGuarantorChecklist().finally(() => setIsLoadingData(false))
  }, [isChecklistOptions?.showChecklist, setIsLoadingData])

  return {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    isChecklistOptions,
    isSendingData,
  }
}
