import * as S from './style'

interface IButtonChoice {
  text: string
  isActive: boolean
  onClick?: () => void
}

const ButtonChoice = ({ text = 'Checked', isActive, onClick }: IButtonChoice) => (
  <S.LabelWrapper>
    <S.Label onClick={onClick} data-testid="test-label" isActive={isActive}>
      <S.TextButton>{text}</S.TextButton>
    </S.Label>
  </S.LabelWrapper>
)

export default ButtonChoice
