import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { IInvoiceInformationStatus } from '~/types/index'

const statusColors: { [key in IInvoiceInformationStatus]: string } = {
  pending: '#FFA500',
  processing: '#007BFF',
  paid: '#28A745',
  cancelled: '#DC3545',
  expired: '#DC3545',
}

interface IFlagContainerProps {
  status: IInvoiceInformationStatus
}

interface ISelectorWrapper {
  hasError: boolean
}

export const InvoiceContainer = styled.div`
  @media print {
    > :not(#print) {
      display: none;
    }
  }

  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    max-width: 1268px;
    padding: 2% 10%;
  }
`
export const BankSlipContainer = styled.div<{ shouldHide?: boolean }>`
  > p {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    max-width: 100% !important;
  }
  @media print {
    #notPrint {
      display: none;
    }
    > div {
      margin-top: 10px;
    }
  }

  ${({ shouldHide }) =>
    shouldHide &&
    css`
      display: none;
    `}
`

export const BankSlipGrid = styled.div`
  min-width: fit-content !important;
  display: grid;
  margin: 0 15px;
  grid-template-columns: repeat(12, 1fr); /* Alterado para 12 colunas */
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  div:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-area: 1 / 1 / 2 / 3;
  }
  div:nth-child(2) {
    grid-area: 1 / 3 / 2 / 13;
    p {
      word-wrap: break-word !important;
      max-width: 100% !important;
    }
  }
  div:nth-child(3) {
    grid-area: 2 / 1 / 3 / 10;
  }
  div:nth-child(4) {
    grid-area: 2 / 10 / 3 / 13;
  }
  div:nth-child(5) {
    grid-area: 3 / 1 / 5 / 10;
  }

  div:nth-child(6) {
    grid-area: 3 / 10 / 4 / 13;
  }
  div:nth-child(7) {
    grid-area: 4 / 10 / 5 / 13;
  }
  div:nth-child(8) {
    grid-area: 5 / 1 / 6 / 10;
  }
  div:nth-child(9) {
    grid-area: 5 / 10 / 6 / 13;
  }
  div:nth-child(10) {
    grid-area: 6 / 1 / 7 / 10;
  }
  div:nth-child(11) {
    grid-area: 6 / 10 / 7 / 13;
  }
  @media (max-width: 767px) {
    display: none;
  }

  @media print {
    display: grid !important; /* Mostra a div durante a impressão */
  }
`

export const InvoiceFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
`
export const InfoContainer = styled.div`
  max-width: 100vw !important;
  width: 100vw !important;
  background-color: lightgray;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-between;
    background-color: lightgray;
    position: relative;
    overflow: hidden;
    max-width: 1268px;
    width: 100%;
    padding: 2% 10%;
    > div {
      max-width: 250px;
    }

    > :nth-child(2) {
      text-align: end;
    }
    @media (max-width: 767px) {
      padding-top: 50px !important;
    }
  }
`
export const FlagContainer = styled.div<IFlagContainerProps>`
  position: absolute;
  overflow: hidden;
  background-color: ${(props) => statusColors[props.status] || 'gray'};
  color: white;
  padding: 10px 30px;
  transform: rotate(45deg);
  width: 300px;
  text-align: center;
  top: 40px;
  right: -55px;
  font-size: 18px;
  font-weight: bold;
  opacity: 0.7;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100vw !important;
    height: 50px;
    transform: rotate(0);
  }
`

export const StagingFlagContainer = styled.div`
  position: absolute;
  overflow: hidden;
  background-color: red;
  color: white;
  padding: 10px 30px;
  transform: rotate(-45deg);
  white-space: nowrap;
  min-width: 400px;
  text-align: center;
  top: 60px;
  left: -120px;
  font-size: 18px;
  font-weight: bold;
  opacity: 0.7;
`
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > p {
    text-align: end;
  }
  @media (max-width: 400px) {
    padding: 0 !important;
    scale: 0.9;
  }
`

export const DetailsItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .line {
    flex: 1;
    height: 1px;
    border-bottom: 1px dashed black; /* Estilo da linha tracejada */
  }
`

export const DetailsLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  p:nth-of-type(2) {
    text-align: end;
  }

  & > div {
    text-align: end;
  }

  @media (max-width: 767px) {
    :nth-of-type(1) {
      flex-direction: column;
    }
  }
`
export const PaymentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const PixContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  > div {
    width: 30%;
  }

  /* copy to clipboard selector */
  > div > div {
    margin: 0;
    button {
      width: 200px;
      height: 30px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    > div {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  .select-menu {
    div:first-child {
      z-index: 0;
    }
  }
`

export const BarcodeContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 50px;

  button {
    margin: 10px 0;
  }

  @media (max-width: 767px) {
    p {
      max-width: 100%;
      word-wrap: break-word !important;
    }
    > div > img {
      display: none !important;
    }
  }
  @media print {
    > div > img {
      display: block !important;
    }
  }
`

export const SmallTextGray = styled.p`
  color: gray;
  padding: 8px 0;
`
export const BoldTitle = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
`

export const Divider = styled.div`
  padding: 0 !important;
  width: 100%;
  border-bottom: 2px solid lightgray;
`
export const DashedDivider = styled.div`
  padding: 0 !important;
  width: 100%;
  border-bottom: 3px dashed lightgray;
`

export const SelectorWrapper = styled.div<ISelectorWrapper>`
  /* seleciona apenas as bordas do select */
  > div > div > div {
    border: 2px solid ${({ hasError }) => (hasError ? theme.colors.red200 : 'default')};
  }
`

export const ErrorMessage = styled.span`
  color: ${theme.colors.red300};
`
