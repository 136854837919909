import { useCallback, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ICourses } from 'types'
import { createLeadFromCourse } from '~/services/api'
import { toast } from 'react-toastify'

interface IHandleSubmit {
  name: string
  email: string
}

export const useGenerateLeadFromEmptyCourse = (coursesObject: ICourses, onClose: () => void) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isSendingData, setIsSendingData] = useState(false)

  const { handleSubmit, setFieldValue, setFieldTouched, validateForm, handleBlur, values, errors, dirty, isValid, touched } =
    useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
        name: '',
        email: '',
      },

      onSubmit: (values) => {
        sendData(values)
      },

      validationSchema: Yup.object({
        name: Yup.string().required('Nome é obrigatório').min(2, 'Você precisa digitar um nome válido'),
        email: Yup.string().email('Email inválido').required('Email é obrigatório'),
      }),
    })

  const sendData = useCallback(
    async ({ name, email }: IHandleSubmit) => {
      setIsSendingData(true)
      const body = {
        name,
        email,
        CourseId: coursesObject.CourseId,
      }

      const data = await createLeadFromCourse(body)

      setIsSendingData(false)
      if (typeof data !== 'string') {
        toast.success('Sucesso! Você receberá um email quando uma nova turma estiver disponível', {
          toastId: 'generateLeadFromEmptyCourse',
        })
        onClose()
      }
    },
    [setIsSendingData, coursesObject, onClose],
  )

  const handleChecked = () => setIsChecked(!isChecked)

  return {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleBlur,
    values,
    errors,
    dirty,
    isValid,
    touched,
    isChecked,
    handleChecked,
    isSendingData,
  }
}
