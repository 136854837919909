import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, Body2 } from '~/styles/global'

export const CreditApprovedWrapper = styled.div`
  margin: 35px 40px 24px 24px;
  max-width: 368px;

  ${theme.breakpoints.down('sm')} {
    margin: 14px 24px;
  }
`

export const CreditApprovedTitle = styled(Body1)``

export const CreditApprovedSubTitle = styled(Body2)`
  margin: 27px 0 46px;
`

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 32px;

  div {
    max-width: 100%;
    :before {
      transition-duration: 1s;
      transition-timing-function: linear;
    }
  }

  p {
    margin-top: 0;
  }

  ${theme.breakpoints.down('sm')} {
    margin-top: 36px;
    gap: 17px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 65%;
`
