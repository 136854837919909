import { theme } from '@provi/provi-components'

const ShutterIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M38 39H18C17.4696 39 16.9609 38.7893 16.5858 38.4142C16.2107 38.0391 16 37.5304 16 37V23C16 22.4696 16.2107 21.9609 16.5858 21.5858C16.9609 21.2107 17.4696 21 18 21H21.9993L23.9993 18H31.9993L33.9993 21H38C38.5304 21 39.0391 21.2107 39.4142 21.5858C39.7893 21.9609 40 22.4696 40 23V37C40 37.5304 39.7893 38.0391 39.4142 38.4142C39.0391 38.7893 38.5304 39 38 39Z"
      stroke={theme.colors.bluePrimary}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 34C30.4853 34 32.5 31.9853 32.5 29.5C32.5 27.0147 30.4853 25 28 25C25.5147 25 23.5 27.0147 23.5 29.5C23.5 31.9853 25.5147 34 28 34Z"
      stroke={theme.colors.bluePrimary}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ShutterIcon
