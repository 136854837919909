import * as S from './styles'

import { Svg, Link } from '~/components/atoms'

interface IRowContact {
  text: string | JSX.Element
  source: string
  alt: string
  url: string
  isVariant?: boolean
  external?: boolean
}

const RowContact = ({ text, source, alt, url, isVariant, external }: IRowContact) => {
  return (
    <S.RowContainer>
      <Svg src={source} alt={alt} width={20} height={20} />
      <Link url={url} text={text} isVariant={isVariant} external={external} />
    </S.RowContainer>
  )
}

export default RowContact
