import * as S from './styles'

import { Description } from '~/components/atoms'

interface IAddressProvi {
  companyAddress: string
  city: string
  cep: string
}

const AddressProvi = ({ companyAddress, city, cep }: IAddressProvi) => {
  return (
    <S.DataProviContainer>
      <Description text={companyAddress} />
      <Description text={city} />
      <Description text={cep} />
    </S.DataProviContainer>
  )
}

export default AddressProvi
