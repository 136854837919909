import { useCallback, useContext, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'

export const useCourseFinancingDateReview = () => {
  const { chosenCourseFinancingInstallment, paymentInformationData, selectedUpfront } = useContext(CheckoutContext)
  const [shouldShowMore, setShouldShowMore] = useState(false)

  const hasUpfrontPayment =
    chosenCourseFinancingInstallment?.paymentFlow?.some((installment) => installment.isUpfront === true) || false

  const handleToggle = useCallback(() => setShouldShowMore((toggle) => !toggle), [setShouldShowMore])

  const selectedUpfrontAmount =
    paymentInformationData?.response.CourseFinancing?.options.find(
      ({ upfrontAmountInPercent }) => upfrontAmountInPercent === selectedUpfront,
    ) || paymentInformationData?.response.CourseFinancing?.options?.[0]

  const upfrontAmountInCents = selectedUpfrontAmount.upfrontAmountInCents
  const registrationFeeAmountInCents = selectedUpfrontAmount.registrationFeeAmountInCents || 0

  const { dueDate: upfrontDueDate } =
    chosenCourseFinancingInstallment?.paymentFlow?.find((paymentFlow) => paymentFlow.isUpfront) || {}

  return {
    chosenCourseFinancingInstallment,
    shouldShowMore,
    handleToggle,
    hasUpfrontPayment,
    upfrontAmountInCents,
    upfrontDueDate,
    registrationFeeAmountInCents,
  }
}
