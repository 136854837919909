import * as S from './style'

interface ISummaryReviewText {
  title: string
  value: string | JSX.Element
  largerFont?: boolean
  colorVariant?: boolean
  hidden?: boolean
}

const SummaryReviewText = ({ title, value, largerFont = false, colorVariant = false, hidden = false }: ISummaryReviewText) => {
  return (
    <S.SummaryReviewWrapper className={largerFont ? 'largerFont' : ''} hidden={hidden}>
      <S.SummaryReviewText>{title}</S.SummaryReviewText>
      <S.SummaryReviewText colorVariant={colorVariant}>{value}</S.SummaryReviewText>
    </S.SummaryReviewWrapper>
  )
}

export default SummaryReviewText
