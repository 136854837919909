import { memo } from 'react'
import { AppointGuarantorForm } from '~/components/organisms'
import * as S from './style'

const AppointGuarantor = () => (
  <S.Wrapper>
    <AppointGuarantorForm title="Indicação de avalista" />
  </S.Wrapper>
)

export default memo(AppointGuarantor)
