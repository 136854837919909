import { cn } from '~/utils/cn'

interface IAlert {
  children: React.ReactNode
  className?: string
  status: 'success' | 'error' | 'warning' | 'info'
}

export const Alert = ({ children, className, status, ...props }: IAlert) => {
  const borderColors = {
    success: 'bg-green-600',
    error: 'bg-red-600',
    warning: 'bg-orange-600',
    info: 'bg-blue-600',
  }

  const bgColors = {
    success: 'bg-green-100',
    error: 'bg-red-100',
    warning: 'bg-orange-100',
    info: 'bg-blue-100',
  }

  return (
    <div className={cn(`w-full p-5 pl-10 rounded-md overflow-hidden relative ${bgColors[status]} ${className}`)} {...props}>
      <div className={cn(`h-full w-1 absolute top-0 left-0 ${borderColors[status]}`)} />
      {children}
    </div>
  )
}
