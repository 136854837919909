import * as S from './style'
import { TrashCanIcon } from '~/components/atoms'
import { parsePrice } from '~/utils/index'
import { IItem, IRemoveFromCart } from 'types'

interface ICartItem {
  item: IItem
  handleRemoveFromCart?: ({ classId }: IRemoveFromCart) => void
  isReadOnly?: boolean
}

const CartItem = ({ item, handleRemoveFromCart, isReadOnly }: ICartItem) => (
  <S.CartItemWrapper>
    <S.CartItemNameWrapper>
      <S.CartItemName title={item.courseName} isReadOnly={isReadOnly}>
        {item.courseName}
      </S.CartItemName>
      {/* todo: hide TrashCanIcon when partner has no courses and/or after screen 6.*.1 (in miro) | DO THIS WHEN WE HAVE A GLOBAL STORE  */}
      {!isReadOnly && item?.removable !== false && typeof handleRemoveFromCart === 'function' && (
        <TrashCanIcon onClick={() => handleRemoveFromCart({ classId: item.CourseClassId })} courseName={item.courseName} />
      )}
    </S.CartItemNameWrapper>

    <S.CartClassName>{item.className}</S.CartClassName>
    <S.CartItemPriceWrapper>
      <S.CartItemPrice>{parsePrice(item.price)}</S.CartItemPrice>
    </S.CartItemPriceWrapper>
  </S.CartItemWrapper>
)

export default CartItem
