import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.button`
  background: none;
  border: none;
  padding: 0 14px 0 16px;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 38px;
  border-radius: 24px;
  background: ${theme.colors.bluePrimaryLight};
`

export const LabelWrapper = styled.span``

export const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: ${theme.colors.bluePrimaryDark};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloseIcon = styled.img``
