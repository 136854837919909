import { useCallback, useContext, useEffect, useState } from 'react'
import { readGuarantorChecklist, writeGuarantorChecklist } from '~/services/api'
import { CheckoutContext } from '~/contexts/CheckoutContext'

export const useGuarantorChecklistMessage = () => {
  const { isLoadingData, setIsLoadingData, step, isSendingData } = useContext(CheckoutContext)
  const [checklistOptions, setChecklistOptions] = useState<string[]>([])

  useEffect(() => {
    async function getGuarantorChecklist() {
      setIsLoadingData(true)
      const result = await readGuarantorChecklist()

      if (typeof result != 'string') {
        setChecklistOptions(result.response.checklistOptions)
      }
    }

    getGuarantorChecklist().finally(() => setIsLoadingData(false))
  }, [setIsLoadingData])

  const handleSubmitButton = useCallback(async () => {
    await writeGuarantorChecklist({ currentScreen: step })
  }, [step])

  return {
    isLoadingData,
    setIsLoadingData,
    handleSubmitButton,
    checklistOptions,
    isSendingData,
  }
}
