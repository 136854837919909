import { useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readWaitingGuarantorSignature } from '~/services/api'
import { ICourseName, IGuarantorSignatureResponse } from '~/types/index'

export const useWaitingGuarantorSignature = () => {
  const { step, isLoadingData, setIsLoadingData } = useContext(CheckoutContext)
  const [guarantorSignatureData, setGuarantorSignatureData] = useState<IGuarantorSignatureResponse>()

  useEffect(() => {
    async function readGuarantorSignatureData() {
      setIsLoadingData(true)
      const result = await readWaitingGuarantorSignature(step)
      typeof result != 'string' && setGuarantorSignatureData(result.response)
    }

    readGuarantorSignatureData().finally(() => setIsLoadingData(false))
  }, [setIsLoadingData, step])

  function getCoursesNames(course: ICourseName[]) {
    let name = ''

    for (let i = 0; i < course?.length; i++) {
      if (i > 0) {
        if (i === course?.length - 1) name += ' e '
        else name += ', '
      }
      name += course[i].courseName
    }

    return name
  }

  return {
    isLoadingData,
    guarantorSignatureData,
    getCoursesNames,
  }
}
