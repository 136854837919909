import { useCallback, useContext, useEffect, useState } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { readReviewFlow, writeReviewFlow } from '~/services/api'
import { IReviewBlock } from '~/types/index'

export const useReviewFlow = () => {
  const [reviewBlocks, setReviewBlocks] = useState<IReviewBlock[] | null>(null)
  const { step, setStep, setIsLoadingData, isLoadingData, progressBar } = useContext(CheckoutContext)

  useEffect(() => {
    async function getReviewFlow() {
      setIsLoadingData(true)
      const result = await readReviewFlow(step)
      typeof result !== 'string' && setReviewBlocks(result.response.reviewBlocks)
    }

    getReviewFlow().finally(() => setIsLoadingData(false))
  }, [step, setReviewBlocks, setIsLoadingData])

  const proceed = useCallback(async () => {
    const result = await writeReviewFlow(step)
    typeof result !== 'string' && setStep(result.nextScreen)
  }, [setStep, step])

  const isVerifiedInformation = step === 'endReview'

  return {
    step,
    proceed,
    reviewBlocks,
    isLoadingData,
    progressBar,
    isVerifiedInformation,
  }
}
