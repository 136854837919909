import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, Body2 } from '~/styles/global'

export const GenerateLeadFromEmptyCourseWrapper = styled.div`
  margin: 15px 24px 24px;
  max-width: 368px;

  ${theme.breakpoints.down('md')} {
    min-height: 100vh;
  }
`

export const GenerateLeadTitle = styled(Body1)`
  margin-bottom: 11px;
`

export const GenerateLeadText = styled(Body2)``

export const GenerateLeadForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 22px;

  ${theme.breakpoints.down('md')} {
    margin-top: 20px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 24px 0;
    z-index: 1;

    flex-direction: column-reverse;
    align-items: center;
    gap: 8px;

    width: 100%;
    background: rgba(255, 255, 255, 0.75);
  }
`
