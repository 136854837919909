import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Body2 } from '~/styles/global'

interface ICartItemPrice {
  hasDiscount?: boolean
  isVariant?: boolean
}

export const CartTotalPriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-top: 2px;
  border-top: 1px solid ${theme.colors.bluePrimary};
  gap: 2px;
`

export const CartTotalPriceLabel = styled(Body2)`
  ${theme.breakpoints.down('md')} {
    font-weight: 500;
    font-size: 15.84px;
    line-height: 24px;
    letter-spacing: 0.005em;
  }
`

export const CartTotalPriceValue = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.down('md')} {
    font-weight: 500;
    font-size: 15.84px;
    letter-spacing: 0.005em;
  }
`

export const CartTotalDiscount = styled.span`
  display: flex;
  flex-direction: column;
`

export const CartItemDiscountedItem = styled.span`
  display: flex;
  gap: 4px;
`

export const CartTotalItemPrice = styled.span<ICartItemPrice>`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${({ isVariant }) => (isVariant ? theme.colors.bluePrimary : theme.colors.black100)};

  ${({ hasDiscount }) =>
    hasDiscount &&
    css`
      color: ${theme.colors.greyPrimary};
      text-decoration-line: line-through;
    `};

  ${theme.breakpoints.down('md')} {
    line-height: 16px;
    letter-spacing: 0.0075em;
  }
`

export const CartFullPrice = styled.span`
  display: flex;
  gap: 4px;
`
