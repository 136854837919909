import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const SearchedItemsWrapper = styled.div`
  display: flex;
  gap: 16px;

  ${theme.breakpoints.down('md')} {
    // hide scrollbar on mobile, but keep functionality
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }

    position: absolute;
    padding-bottom: 12px;
    padding-left: 24px;
    z-index: 2;
    top: 78px;
    left: 0;
    right: 0;

    border-bottom: 2px solid ${theme.colors.bluePrimaryLight};
  }
`
