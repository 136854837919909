import { TextButton } from '@provi/provi-components'
import { copyToClipboard } from '~/utils/copyToClipboard'
import { CopyIcon } from '~/components/atoms'
import * as S from './styles'

interface ITextButtonField {
  text: string
  invoiceCode?: string
}

const TextButtonField = ({ text, invoiceCode }: ITextButtonField) => (
  <S.TextButtonWrapper>
    <TextButton
      text={
        <>
          {text} <CopyIcon />
        </>
      }
      onClick={() => {
        copyToClipboard(invoiceCode || '')
      }}
    >
      Copiar código
    </TextButton>
  </S.TextButtonWrapper>
)

export default TextButtonField
