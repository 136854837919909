import styled from 'styled-components'

export const CourseHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    max-width: 480px;
    padding: 0 16px;
  }
`
