import { ButtonV3 as Button } from '@provi/provi-components'
import { useMemo } from 'react'
import { SummaryReviewText } from '~/components/atoms'
import { IPaymentFlow } from '~/types/index'
import { formatInstallmentNumber, formatMonthlyInterestText, parseDiscount, parsePrice } from '~/utils/index'
import { useFinancingSummaryContent } from './hooks'
import * as S from './style'

interface IDateCells {
  index: number
  data: IPaymentFlow
}

const DateCells = ({ index, data }: IDateCells) => {
  const { paymentValue, paymentDate, isUpfront } = data || {}

  if (!paymentValue || !paymentDate) {
    return null
  }

  return (
    <>
      <S.DateCellsText justifyContent="flex-start">{formatInstallmentNumber(index, isUpfront)}</S.DateCellsText>
      <S.DateCellsText justifyContent="center">{parsePrice(paymentValue)}</S.DateCellsText>
      <S.DateCellsText justifyContent="flex-end">{paymentDate}</S.DateCellsText>
    </>
  )
}

const FinancingSummaryContent = () => {
  const {
    financingSummaryData,
    handleToggle,
    summaryInstallmentsToApply,
    handleNextScreen,
    isLoadingData,
    isSendingData,
    shouldShowMore,
    showTaxesOnCheckout,
  } = useFinancingSummaryContent()

  const renderFinancingSummaryData = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return Array.from({ length: 6 }).map((_, index) => <S.SummaryRowLoading key={index} />)
    }

    return (
      <>
        <S.SummaryReviewWrapper>
          <S.SummaryReviewTitle>{financingSummaryData?.partnerName}</S.SummaryReviewTitle>
          <S.SummaryReviewContainer>
            {financingSummaryData?.courseNames.map((course, index) => (
              <S.SummaryReviewContent key={index}>
                <S.SummaryReviewTitle>{course.courseName}</S.SummaryReviewTitle>
                <S.SummaryReviewTitle>{course.className}</S.SummaryReviewTitle>
              </S.SummaryReviewContent>
            ))}
          </S.SummaryReviewContainer>

          <SummaryReviewText
            title={`${summaryInstallmentsToApply}`}
            value={parsePrice(financingSummaryData?.PMT || 0)}
            largerFont
          />
          <SummaryReviewText title="Entrada" value={parsePrice(financingSummaryData?.upfrontAmount || 0)} largerFont />
          <SummaryReviewText
            hidden={!showTaxesOnCheckout}
            title="Total a pagar"
            value={parsePrice(financingSummaryData?.totalPriceToPay || 0)}
            largerFont
            colorVariant
          />
          <hr />

          <SummaryReviewText title="IOF total" value={parsePrice(financingSummaryData?.IOF || 0)} largerFont />
          <SummaryReviewText
            title="Taxa de juros"
            value={formatMonthlyInterestText((financingSummaryData && financingSummaryData?.monthlyInterest * 100) || 0)}
            largerFont
          />

          <SummaryReviewText
            title="Custo efetivo total (CET)"
            value={`${parseDiscount((financingSummaryData && financingSummaryData?.CETAnnual * 100) || 0, 2)} a.a.`}
            largerFont
          />

          <hr />
          <S.DateCellsWrapper shouldShowMore={shouldShowMore} cellsCount={financingSummaryData?.paymentFlow?.length || 2}>
            {financingSummaryData?.paymentFlow?.map((paymentData, index) => {
              return <DateCells key={index} index={index} data={paymentData} />
            })}
          </S.DateCellsWrapper>

          {!showTaxesOnCheckout && shouldShowMore ? (
            <div className="py-4">
              <SummaryReviewText
                title="Total a pagar"
                value={parsePrice(financingSummaryData?.totalPriceToPay || 0)}
                largerFont
              />
            </div>
          ) : null}
          {financingSummaryData?.paymentFlow?.length > 4 && (
            <S.SeeMore onClick={handleToggle}>{shouldShowMore ? 'Ver menos' : 'Ver todas as parcelas'}</S.SeeMore>
          )}
        </S.SummaryReviewWrapper>

        <Button text="Continuar" icon onClick={handleNextScreen} isLoading={isSendingData} mobileWidth="100%" />
      </>
    )
  }, [
    financingSummaryData,
    handleToggle,
    summaryInstallmentsToApply,
    shouldShowMore,
    isLoadingData,
    handleNextScreen,
    isSendingData,
    showTaxesOnCheckout,
  ])

  return (
    <S.FinancingSummaryWrapper>
      <S.FinancingSummaryTitle>Resumo do financiamento</S.FinancingSummaryTitle>

      {renderFinancingSummaryData}
    </S.FinancingSummaryWrapper>
  )
}

export default FinancingSummaryContent
