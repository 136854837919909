import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Button } from '~/styles/global'

interface IDateCellsText {
  justifyContent: string
  isSubTitleReview?: boolean
}

interface IDateCellsWrapper {
  shouldShowMore: boolean
  cellsCount: number
}

export const CourseFinancingDateReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
  max-width: 368px;
  margin-top: 6px;
  margin-bottom: 46px;
  padding-top: 5px;
  border-top: 1px solid ${theme.colors.bluePrimaryLight};

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`
export const CourseFinancingDueDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
  max-width: 368px;
  margin-top: 16px;

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`
export const DateReviewWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const DateCellsWrapper = styled.div<IDateCellsWrapper>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;

  width: 100%;
  max-height: 54px;
  margin-bottom: 8px;
  overflow: hidden;
  cursor: default;
  transition: max-height 0.3s ease-in-out;

  ${({ shouldShowMore, cellsCount }) =>
    shouldShowMore &&
    cellsCount &&
    css`
      max-height: calc(${cellsCount} * 28px);
    `}

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

export const DateCellsText = styled.span<IDateCellsText>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};

  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;

  ${({ isSubTitleReview }) =>
    isSubTitleReview &&
    css`
      margin-bottom: 10px;
    `}

  ${theme.breakpoints.down('md')} {
    font-size: 12.8px;
  }
`

export const SeeMore = styled(Button)``
