import * as S from './style'

import { SvgArrow } from '~/components/atoms'

interface IButtonArrow {
  isRight?: boolean
  disabledArrow?: boolean
  handleClick: () => void
}

const ButtonArrow = ({ handleClick, disabledArrow, isRight, ...rest }: IButtonArrow) => {
  return (
    <S.Button
      disabled={disabledArrow}
      disabledArrow={disabledArrow}
      aria-disabled={disabledArrow}
      onClick={handleClick}
      aria-label={isRight === true ? 'Avançar' : 'Voltar'}
    >
      <SvgArrow isRight={isRight} {...rest} />
    </S.Button>
  )
}

export default ButtonArrow
