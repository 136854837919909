import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, Body2, loadingCSS } from '~/styles/global'

export const Wrapper = styled.div`
  max-width: 368px;
  min-height: 389px;
  margin: 35px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${theme.breakpoints.down('sm')} {
    > div:nth-child(2) {
      min-width: 100%;
    }
  }
`

export const InfoGuarantor = styled.div`
  margin: 16px 0;

  .loading-title {
    height: 56px;
  }
`

export const InfoGuarantorWrapper = styled.div``

export const PreApprovedTitle = styled(Body1)`
  margin-bottom: 27px;
`

export const PreApprovedSubTitle = styled(Body2)``

export const DataInputLoading = styled.div`
  height: 40px;
  width: 100%;
  margin: 8px 0;

  ${loadingCSS}
`

export const DataInputLoadingWrapper = styled.div`
  max-width: 60%;
  margin: 27px 0;
`
