import { useCallback, useContext } from 'react'

import { CoursesContext } from '~/contexts/CoursesContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { IRemoveFromCart } from 'types'

export const useCart = () => {
  const { selectedClasses, selectClass } = useContext(CoursesContext)
  const { cartData, isCartLoading, mutateCart } = useContext(ParamsContext)

  const updateTotalPriceToPay = useCallback(
    (totalPrice, hasInstallment) => {
      const updatedCartData = {
        ...cartData,
        totalPriceToPay: totalPrice,
        hasInstallment: hasInstallment,
      }
      mutateCart(async () => updatedCartData, { revalidate: false })
    },
    [cartData, mutateCart],
  )

  const removeFromCart = useCallback(
    ({ classId }: IRemoveFromCart) => {
      const newSelectedClasses = selectedClasses?.find((item) => item.classId === classId)

      newSelectedClasses != null && selectClass(newSelectedClasses)
    },
    [selectedClasses, selectClass],
  )

  return { cart: cartData, removeFromCart, isCartLoading, updateTotalPriceToPay }
}
