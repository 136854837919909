import * as S from './style'

interface IMiniTextBox {
  icon?: JSX.Element
  isOpen: boolean
  title: string | JSX.Element
  value: string | JSX.Element
  fontSizeVariant?: boolean
}

const MiniTextBox = ({ title, value, isOpen, fontSizeVariant, icon }: IMiniTextBox) => {
  return (
    <div className="flex justify-between">
      <div className="max-w-[384px]">
        <S.MiniTextBoxTitle isOpen={isOpen} fontSizeVariant={fontSizeVariant}>
          {title}
        </S.MiniTextBoxTitle>
        <S.MiniTextBoxSubTitle isOpen={isOpen} fontSizeVariant={fontSizeVariant}>
          {value}
        </S.MiniTextBoxSubTitle>
      </div>
      {icon}
    </div>
  )
}

export default MiniTextBox
