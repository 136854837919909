import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div<{ smallCloseIcon?: boolean }>`
  h1 {
    color: ${theme.colors.bluePrimary};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.005em;
    text-align: left;

    ${theme.breakpoints.down('md')} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  p {
    margin-top: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0075em;
    text-align: left;

    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.005em;
    }
  }

  > button {
    background: none;
    border: none;
    margin-top: 12px;
    margin-right: 12px;
    position: absolute;
    top: 0;
    right: 0;

    ${({ smallCloseIcon }) =>
      smallCloseIcon &&
      css`
        margin-top: 16px;

        img {
          width: 16px;
        }
      `}

    &:hover {
      cursor: pointer;
    }

    ${theme.breakpoints.down('md')} {
      margin-top: 14px;
      margin-right: 14px;

      ${({ smallCloseIcon }) =>
        smallCloseIcon &&
        css`
          margin-top: 16px;
        `}
    }
  }
`
