import { DateIcon } from '~/components/atoms'
import { useCourseFinancingDateReview } from './hooks'
import { parsePrice, formatInstallmentNumber } from '~/utils/index'

import * as S from './style'
import { formatDate } from '~/utils/formatDate'

interface IDateCells {
  index: number
  hasUpfrontPayment: boolean
  data: {
    pmt: number
    dueDate: string
    upfrontAmountInCents: number
    registrationFeeAmountInCents?: number
  }
}

const DateCells = ({ index, data, hasUpfrontPayment }: IDateCells) => {
  const { pmt, dueDate, upfrontAmountInCents, registrationFeeAmountInCents } = data

  const realInstallmentNumber = hasUpfrontPayment ? index : index + 1
  const isUpfrontAmountInCents =
    realInstallmentNumber === 0 && parsePrice((upfrontAmountInCents + (registrationFeeAmountInCents || 0)) / 100)

  return (
    <>
      <S.DateCellsText justifyContent="flex-start">{formatInstallmentNumber(realInstallmentNumber)}</S.DateCellsText>
      <S.DateCellsText justifyContent="center">{isUpfrontAmountInCents || parsePrice(pmt / 100)}</S.DateCellsText>
      <S.DateCellsText justifyContent="flex-end">{formatDate(dueDate)}</S.DateCellsText>
    </>
  )
}

const CourseFinancingDateReview = () => {
  const {
    chosenCourseFinancingInstallment,
    shouldShowMore,
    handleToggle,
    hasUpfrontPayment,
    upfrontAmountInCents,
    upfrontDueDate,
    registrationFeeAmountInCents,
  } = useCourseFinancingDateReview()

  return (
    <>
      {upfrontDueDate && (
        <S.CourseFinancingDueDateWrapper>
          <S.DateReviewWrapper>
            <S.DateCellsText justifyContent="flex-start">Validade das condições:</S.DateCellsText>
          </S.DateReviewWrapper>
          <S.DateCellsText justifyContent="flex-start" isSubTitleReview={true}>
            As condições de financiamento são válidas até o dia {formatDate(upfrontDueDate)}, data de vencimento da entrada. Os
            valores apresentados podem sofrer alterações após o término deste período
          </S.DateCellsText>
        </S.CourseFinancingDueDateWrapper>
      )}
      <S.CourseFinancingDateReviewWrapper>
        <S.DateReviewWrapper>
          <DateIcon />
          <S.DateCellsText justifyContent="flex-start">Datas de vencimento</S.DateCellsText>
        </S.DateReviewWrapper>
        <S.DateCellsText justifyContent="flex-start" isSubTitleReview={true}>
          A entrada pode ser paga no cartão, boleto ou Pix, enquanto as demais parcelas devem ser pagas com boleto ou Pix.
        </S.DateCellsText>
        <S.DateCellsWrapper
          shouldShowMore={shouldShowMore}
          cellsCount={chosenCourseFinancingInstallment?.paymentFlow?.length || 2}
        >
          {chosenCourseFinancingInstallment?.paymentFlow?.map((paymentFlow, index) => {
            return (
              <DateCells
                key={index}
                index={index}
                data={{
                  dueDate: paymentFlow.dueDate,
                  pmt: chosenCourseFinancingInstallment?.pmt,
                  upfrontAmountInCents,
                  registrationFeeAmountInCents,
                }}
                hasUpfrontPayment={hasUpfrontPayment}
              />
            )
          })}
        </S.DateCellsWrapper>
        <S.SeeMore onClick={handleToggle}>{shouldShowMore ? 'Ver menos' : 'Ver todas as parcelas'}</S.SeeMore>
      </S.CourseFinancingDateReviewWrapper>
    </>
  )
}

export default CourseFinancingDateReview
