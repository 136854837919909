import { useContext, useMemo } from 'react'
import { ProgressBar, theme } from '@provi/provi-components'
import { BackButton } from '~/components/atoms'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { useProgressBar } from '~/hooks/index'
import { useWindowSize } from '~/utils/useWindowSize'
import * as S from './style'

const HeaderCheckout = () => {
  const { step, isInCourseFinancingReviewScreen, isLoadingData, isSendingData } = useContext(CheckoutContext)
  const { getInfosFromStep, hideProgressBar } = useProgressBar()
  const { width } = useWindowSize()

  const { title, previousScreenUniqueName, showProgressBar, titleColor, arrowDisabled } = getInfosFromStep()

  const isReviewingPayment = step === 'paymentSelection' && isInCourseFinancingReviewScreen && width && width < 800

  const renderProgressBar = useMemo(() => {
    if (!hideProgressBar) return null

    return (
      <S.HeaderBasicInfoWrapper>
        <S.HeaderCheckoutTitle titleColor={titleColor}>{title}</S.HeaderCheckoutTitle>
        <ProgressBar
          progress={showProgressBar}
          backgroundColor={theme.colors.bluePrimaryLight}
          color={theme.colors.bluePrimary}
        />
      </S.HeaderBasicInfoWrapper>
    )
  }, [hideProgressBar, showProgressBar, titleColor, title])

  return (
    <S.HeaderCheckoutContainer isReviewingPayment={isReviewingPayment}>
      {hideProgressBar && (
        <BackButton step={previousScreenUniqueName} disabled={isLoadingData || isSendingData || arrowDisabled} />
      )}
      {isReviewingPayment ? <span>Resumo do pedido</span> : renderProgressBar}
    </S.HeaderCheckoutContainer>
  )
}

export default HeaderCheckout
