import { theme } from '@provi/provi-components'
import styled from 'styled-components'

interface ICheckCard {
  isActive: boolean
}

export const WrapperForm = styled.section`
  margin: 35px 24px 24px;

  ${theme.breakpoints.down('md')} {
    margin-top: 14px;
  }

  button {
    margin-top: 62px;
  }
`

export const TermsRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 370px;

  label {
    margin-top: 10px;
  }

  a {
    color: ${theme.colors.black100};
  }
`

export const CheckCardWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`

export const CheckCard = styled.div<ICheckCard>`
  display: flex;
  min-height: 64px;
  min-width: 368px;
  max-width: 408px;
  margin: 20px 0 0 0;
  border-radius: 8px;

  padding-top: 8px;
  padding-bottom: 8px;

  :hover {
    cursor: pointer;
    border: 2px solid ${theme.colors.bluePrimary};
  }

  label {
    cursor: pointer;
    font-size: 16px;
    font-style: normal;

    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    ${({ isActive }) => isActive && `font-weight: 600; color: ${theme.colors.bluePrimary};`}
  }

  ${({ isActive }) =>
    isActive ? `border: 2px solid ${theme.colors.bluePrimary}` : `border: 2px solid ${theme.colors.bluePrimaryLight}`}
`
