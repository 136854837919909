import { theme } from '@provi/provi-components'

const RowSummaryTicketSvg = () => (
  <svg width="312" height="7" viewBox="0 0 312 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 6L11.3762 1.6487C12.3657 1.23374 13.4805 1.23374 14.47 1.6487L23.2992 5.3513C24.2888 5.76626 25.4035 5.76626 26.3931 5.3513L35.2223 1.6487C36.2118 1.23374 37.3266 1.23374 38.3161 1.6487L47.1454 5.3513C48.1349 5.76626 49.2497 5.76626 50.2392 5.3513L59.0685 1.6487C60.058 1.23374 61.1728 1.23374 62.1623 1.6487L70.9916 5.3513C71.9811 5.76626 73.0958 5.76626 74.0854 5.3513L82.9146 1.6487C83.9042 1.23374 85.0189 1.23374 86.0084 1.6487L94.8377 5.3513C95.8272 5.76626 96.942 5.76626 97.9315 5.3513L106.761 1.6487C107.75 1.23374 108.865 1.23374 109.855 1.6487L118.684 5.3513C119.673 5.76626 120.788 5.76626 121.778 5.3513L130.607 1.6487C131.596 1.23374 132.711 1.23374 133.701 1.6487L142.53 5.3513C143.52 5.76626 144.634 5.76626 145.624 5.3513L154.453 1.6487C155.443 1.23374 156.557 1.23374 157.547 1.6487L166.376 5.3513C167.366 5.76626 168.48 5.76626 169.47 5.3513L178.299 1.6487C179.289 1.23374 180.404 1.23374 181.393 1.6487L190.222 5.3513C191.212 5.76626 192.327 5.76626 193.316 5.3513L202.145 1.6487C203.135 1.23374 204.25 1.23374 205.239 1.6487L214.068 5.3513C215.058 5.76626 216.173 5.76626 217.162 5.3513L225.992 1.6487C226.981 1.23374 228.096 1.23374 229.085 1.6487L237.915 5.3513C238.904 5.76626 240.019 5.76626 241.008 5.3513L249.838 1.6487C250.827 1.23374 251.942 1.23374 252.932 1.6487L261.761 5.3513C262.75 5.76626 263.865 5.76626 264.855 5.3513L273.684 1.6487C274.673 1.23374 275.788 1.23374 276.778 1.6487L285.607 5.3513C286.596 5.76626 287.711 5.76626 288.701 5.3513L297.53 1.6487C298.52 1.23374 299.634 1.23374 300.624 1.6487L311 6"
      stroke={theme.colors.bluePrimary}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RowSummaryTicketSvg
