import { theme } from '@provi/provi-components'
import * as S from './style'

interface ISvgArrow {
  isRight?: boolean
}

const SvgArrow = ({ isRight, ...rest }: ISvgArrow) => {
  return (
    <S.SvgContainer>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d={isRight ? 'M3 12L20.25 12M14 5L21 12L14 19.5' : 'M21 12L3.75 12M10 19L3 12L10 4.5'}
          stroke={theme.colors.black100}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="arrow-svg"
          {...rest}
        />
      </svg>
    </S.SvgContainer>
  )
}

export default SvgArrow
