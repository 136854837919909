import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Caption, loadingCSS } from '~/styles/global'

interface ICardWrapper {
  isActive: boolean
  isDisabled: boolean
}

type ICartTitle = ICardWrapper

export const CardWrapper = styled.button<ICardWrapper>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 144px;

  background: ${theme.colors.white100};
  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;

  :focus,
  :focus-within,
  :active {
    outline: 3px auto ${theme.colors.bluePrimary};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border: 2px solid ${theme.colors.bluePrimary};
      background: ${theme.colors.bluePrimaryLight};

      svg {
        path:nth-child(1) {
          stroke: ${theme.colors.bluePrimaryDark};
        }

        path:nth-child(2) {
          fill: ${theme.colors.bluePrimaryDark};
        }
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background: ${theme.colors.greyLight};
      border: 2px solid ${theme.colors.greyLight};
      cursor: not-allowed;

      svg {
        path:nth-child(1) {
          stroke: ${theme.colors.greyPrimary};
        }

        path:nth-child(2) {
          fill: ${theme.colors.greyPrimary};
        }
      }
    `}

  ${theme.breakpoints.down('md')} {
    width: 115px;
    height: 60px;

    svg {
      width: 24px;
    }
  }
`

export const CardTitle = styled(Caption)<ICartTitle>`
  color: ${theme.colors.bluePrimary};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${theme.colors.bluePrimaryDark};
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${theme.colors.greyPrimary};
    `}
`

export const LoadingCardWrapper = styled(CardWrapper)`
  /* cursor: default; */
  /* outline: none !important; */
  ${loadingCSS}
`

export const LoadingPaymentMethodIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  transform: rotate(180deg);
  ${loadingCSS}
`

export const LoadingCardTitle = styled.div`
  width: 80%;
  height: 20px;
  ${loadingCSS}
  transform: rotate(180deg);
`
