import { FinancingSummaryContent } from '~/components/organisms'
import * as S from './style'

const FinancingSummary = () => (
  <S.Wrapper>
    <FinancingSummaryContent />
  </S.Wrapper>
)

export default FinancingSummary
