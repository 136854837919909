import styled from 'styled-components'
import { SpinnerLoading } from '~/styles/global'

export const LoadingRingWrapper = styled.div`
  min-height: 496px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.75);
`

export const Loading = styled(SpinnerLoading)``
