import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body1, loadingCSS } from '~/styles/global'

interface IButtonWrapper {
  isVariant: boolean
}

export const WrapperForm = styled.section`
  margin: 35px 24px 24px;
  max-width: 368px;
  min-height: 389px;
  position: relative;

  ${theme.breakpoints.down('md')} {
    margin-top: 14px;
  }
`

export const WrapperInput = styled.form`
  > div:last-of-type {
    margin-bottom: 32px;
  }
`

export const TitleForm = styled(Body1)`
  margin-bottom: 22px;
`

export const ButtonWrapper = styled.div<IButtonWrapper>`
  position: ${({ isVariant }) => (isVariant ? 'relative' : 'absolute')};
  margin-top: 16px;
  bottom: 0;

  @media (max-width: 500px) {
    > div {
      min-width: calc(100vw - 48px);
    }
  }
`

export const OptionInputLoading = styled.div`
  height: 40px;
  width: 100%;
  margin: 8px 0;

  ${loadingCSS}
`
