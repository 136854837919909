import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { Body2, loadingCSS } from '~/styles/global'

export const FeedbackBody = styled.div`
  max-width: 368px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 46px 24px 23.5px;

  ${theme.breakpoints.down('md')} {
    min-width: calc(100% - 48px);
  }
`

export const FeedbackTitle = styled(Body2)``

export const BodyTicket = styled(Body2)`
  ${theme.breakpoints.up('md')} {
    margin: 0 0 38px;
  }
`

export const ButtonWrapper = styled.div`
  padding-top: 24px;

  button {
    border: none;
  }
`

export const LoadingInvoiceCode = styled.div`
  margin: 27.8px 0 22px;
  max-width: 380px;
  height: 48px;

  ${loadingCSS}
`

export const CheckoutMessageLoadingButton = styled.div`
  height: 24px;
  max-width: 145px;

  &:last-child {
    margin-top: 56px;
    max-width: 184px;
  }

  ${loadingCSS}
`

export const QrCodeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  background-color: ${theme.colors.white100};

  ${loadingCSS}
`

export const LoadingQrCodeImage = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  margin-top: 16px;

  ${loadingCSS}
`
