import { InputFieldV3 as Input } from '@provi/provi-components'
import { FocusEvent, useMemo } from 'react'
import { CopyIcon } from '~/components/atoms'
import * as S from './styles'

interface ICopyToClipboard {
  value: string
  buttonText: string
  onClick: () => void
}

export const CopyToClipboard = ({ buttonText, value, onClick }: ICopyToClipboard) =>
  useMemo(() => {
    return (
      <S.CopyToClipboardWrapper>
        <Input width="100%" value={value} onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()} readOnly />

        <S.ButtonWrapper onClick={onClick}>
          <S.ButtonText as="span">{buttonText} </S.ButtonText>
          <CopyIcon />
        </S.ButtonWrapper>
      </S.CopyToClipboardWrapper>
    )
  }, [buttonText, onClick, value])
