import { memo, useContext } from 'react'
import { useRouter } from 'next/router'
import * as S from './style'
import { CartHeader, CartItems, CartPaymentForms, CartFooterInformation, CartReview } from '~/components/molecules'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { useCart } from './hooks'

const Cart = () => {
  const router = useRouter()
  const { cart, removeFromCart, isCartLoading } = useCart()
  const { isInCourseFinancingReviewScreen, step, paymentInformationData } = useContext(CheckoutContext)

  return (
    <S.CartWrapper>
      <CartHeader number={cart?.courses?.length || 0} />
      <CartItems cartData={cart} isCartLoading={isCartLoading} removeFromCart={removeFromCart} />

      {isInCourseFinancingReviewScreen ||
      step === 'checkoutSuccess' ||
      (step === 'creditInAnalysis' && paymentInformationData) ||
      (cart?.paymentMethod && Object.keys?.(cart?.paymentMethod).length) ? (
        <CartReview hasBorderTop={step === 'checkoutSuccess'} shouldUseCartData={true} />
      ) : (
        <CartPaymentForms paymentForms={cart?.availablePaymentMethod} />
      )}
      {router.route !== '/courses/[slug]' && <CartFooterInformation />}
    </S.CartWrapper>
  )
}

export default memo(Cart)
