import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface ICartReviewWrapper {
  hasBorderTop?: boolean
}

export const CartReviewWrapper = styled.div<ICartReviewWrapper>`
  border-top: ${({ hasBorderTop }) => (hasBorderTop ? `1px solid ${theme.colors.bluePrimaryLight}` : 'none')};
  padding-top: 7px;

  width: 100%;
  max-width: 368px;

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const CartReviewTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 100%;
  margin-top: 10px;
  margin-bottom: 19px;
`
