import * as S from './style'

const LoadingCarouselCourses = () => (
  <S.Wrapper>
    <S.CourseNameLoading />
    <S.CourseDescriptionLoading />
    <S.CardCourseLoading />
  </S.Wrapper>
)

export default LoadingCarouselCourses
