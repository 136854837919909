import { theme } from '@provi/provi-components'

export const ChevronRightIcon = ({ ...args }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...args}>
    <path
      d="M4.5 15L12 7.5L19.5 15"
      stroke={theme.colors.black100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
