type IParseDiscount = number | string

export const parseDiscount = (discountInPercentage: IParseDiscount, maximumFractionDigits?: number): string => {
  const discount = typeof discountInPercentage === 'string' ? parseInt(discountInPercentage) : discountInPercentage

  const formattedDiscount = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits || 0,
  }).format(discount / 100)

  if (isNaN(discount)) {
    throw new Error(`Can't parse price: ${discountInPercentage}`)
  }

  return formattedDiscount || '0'
}
