import { useRouter } from 'next/router'
import { ChangeEvent, Dispatch, SetStateAction, memo, useCallback, useContext, useRef } from 'react'

import { CoursesContext } from '~/contexts/CoursesContext'
import { useInIframe } from '~/hooks/useInIframe'
import CartIcon from '../CartIcon'
import * as S from './style'

interface ISearchInput {
  isSearchOpen: boolean
  setIsSearchOpen: Dispatch<SetStateAction<boolean>>
  isCartOpen: boolean
  setIsCartOpen: Dispatch<SetStateAction<boolean>>
  cartItems: number
  cart: JSX.Element
}

const SearchInput = ({ isSearchOpen, setIsSearchOpen, isCartOpen, setIsCartOpen, cartItems, cart, ...rest }: ISearchInput) => {
  const { isInIframe } = useInIframe()
  const { enteredText, setEnteredText } = useContext(CoursesContext)

  const router = useRouter()
  const inputRef = useRef<HTMLLabelElement>(null)

  const handleClick = useCallback(() => {
    setIsSearchOpen(true)
    setIsCartOpen(false)
    setTimeout(() => inputRef?.current?.focus(), 300)
  }, [setIsSearchOpen, setIsCartOpen, inputRef])

  const handleChange = useCallback(
    (text: string) => {
      setEnteredText(text)
    },
    [setEnteredText],
  )

  const handleBlur = useCallback(() => {
    setIsSearchOpen(false)
  }, [setIsSearchOpen])

  const handleCart = useCallback(() => {
    setIsCartOpen(!isCartOpen)
  }, [setIsCartOpen, isCartOpen])

  const isInCoursesRoute = useCallback(() => {
    return router.route === '/courses/[slug]'
  }, [router])

  return (
    <S.Wrapper isSearchOpen={isSearchOpen}>
      <S.ButtonWrapper>
        {/* todo: hide CartIcon when partner has no active courses (Z1) | DO THIS WHEN WE HAVE A GLOBAL STORE */}
        <CartIcon number={cartItems} onClick={handleCart} />
        {/* todo: only show search button(below this) when partner has over X courses (TBD) | DO THIS WHEN WE HAVE A GLOBAL STORE  */}
        {isInCoursesRoute() && !isInIframe && (
          <S.Button aria-label="Abrir o campo de pesquisa" onClick={handleClick} isSearchOpen={isSearchOpen}>
            <S.Icon src="/assets/svg/magnifying-glass.svg" alt="Pesquisa" />
          </S.Button>
        )}
      </S.ButtonWrapper>
      {isCartOpen && <S.CartWrapper isCartOpen={isCartOpen}>{cart}</S.CartWrapper>}

      {isInCoursesRoute() && (
        <S.InputWrapper isSearchOpen={isSearchOpen}>
          <S.Input
            data-test-id="search-input"
            inputRef={inputRef}
            placeholder="Pesquisar"
            icon={<S.Icon src="/assets/svg/magnifying-glass.svg" alt="Pesquisa" />}
            width="288px"
            mobileWidth="calc(100vw - 104px)"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            value={enteredText}
            onBlur={handleBlur}
            {...rest}
          />
        </S.InputWrapper>
      )}
    </S.Wrapper>
  )
}

export default memo(SearchInput)
