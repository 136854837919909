import { PreDocumentContent } from '~/components/organisms'
import * as S from './style'

const PreDocument = () => (
  <S.Wrapper>
    <PreDocumentContent />
  </S.Wrapper>
)

export default PreDocument
