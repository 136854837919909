import { SuccessIcon } from '..'
import * as S from './style'

interface IChecklistOption {
  text: string
  isVariant?: boolean
}

const ChecklistOption = ({ text, isVariant = false }: IChecklistOption) => (
  <S.ChecklistOptionContent>
    <SuccessIcon isVariant={isVariant} /> <S.Option>{text}</S.Option>
  </S.ChecklistOptionContent>
)

export default ChecklistOption
