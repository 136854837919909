import CourseFinancingPartnerCondition from '~/components/atoms/CourseFinancingPartnerCondition'
import CourseFinancingPartnerConditionFlex from '~/components/atoms/CourseFinancingPartnerConditionFlex'
import * as S from './style'
import { IPaymentResponse } from 'types'

interface ICourseFinancingSelector {
  data: IPaymentResponse['CourseFinancing']
  selectedPartnerConditionId: number | null
  handleSelectedPartnerConditionId: (id: number) => void
}

const CourseFinancingSelector = ({
  data,
  selectedPartnerConditionId,
  handleSelectedPartnerConditionId,
}: ICourseFinancingSelector) => {
  const installment = data?.options[0]?.installments

  if (data?.type === 'flex') {
    return (
      <S.CourseFinancingSelectorWrapper isFlex>
        <CourseFinancingPartnerConditionFlex
          selectedPartnerConditionId={selectedPartnerConditionId}
          handleSelectedPartnerConditionId={handleSelectedPartnerConditionId}
          data={data}
        />
      </S.CourseFinancingSelectorWrapper>
    )
  }

  return (
    <S.CourseFinancingSelectorWrapper>
      <CourseFinancingPartnerCondition
        partnerCondition={{ ...data?.options[0], ...installment }}
        selectedPartnerConditionId={selectedPartnerConditionId}
        handleSelectedPartnerConditionId={handleSelectedPartnerConditionId}
      />
    </S.CourseFinancingSelectorWrapper>
  )
}

export default CourseFinancingSelector
