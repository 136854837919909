import { FailureIcon, SuccessIcon } from '~/components/atoms'
import * as S from './style'

interface IFeedbackHeaderCheckout {
  title: string
  isSuccess?: boolean
  hasIcon?: boolean
  isLoading?: boolean
}

const FeedbackHeaderCheckout = ({ title, isSuccess = false, hasIcon = false, isLoading = false }: IFeedbackHeaderCheckout) => (
  <S.FeedbackHeader isSuccess={isSuccess}>
    {isLoading ? null : hasIcon ? isSuccess ? <SuccessIcon /> : <FailureIcon /> : null}
    {isLoading || !title ? null : <S.FeedbackTitle>{title}</S.FeedbackTitle>}
  </S.FeedbackHeader>
)

export default FeedbackHeaderCheckout
