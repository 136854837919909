import * as S from './style'

interface IChip {
  label: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Chip = ({ label, ...rest }: IChip) => {
  return (
    <S.Wrapper {...rest}>
      <S.LabelWrapper>
        <S.Label>
          {label}
          <S.CloseIcon src="/assets/svg/close-button.svg" alt={`Remover ${label} do campo de pesquisa`} title="Remover" />
        </S.Label>
      </S.LabelWrapper>
    </S.Wrapper>
  )
}

export default Chip
