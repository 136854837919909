import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Body2, Caption, loadingCSS } from '~/styles/global'

export const Loading = styled.div<{ isVariant?: boolean }>`
  ${loadingCSS}
  min-width: 244px;
  max-width: 244px;
  height: 24px;
  background: ${theme.colors.greyLight};
  border-radius: 4px;

  ${({ isVariant }) =>
    isVariant &&
    css`
      min-width: 62px;
      max-width: 62px;
      height: 24px;
      margin-left: 5px;
    `}
`

export const CourseFinancingPartnerConditionFlexWrapper = styled.button`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white100};

  width: 100%;
  height: 100%;
  max-height: 46px;

  font-family: 'Montserrat', sans-serif;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  @media (prefers-reduced-motion) {
    transition: all 0s linear;
  }
`

export const PartnerConditionLabel = styled(Body2)`
  display: flex;
  align-items: center;
  padding: 4px 0;

  height: 100%;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-weight: 600;

  label {
    input {
      display: none;
    }
  }

  sup {
    top: -0.3rem;
  }
`

export const PartnerConditionPrice = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;

  display: flex;
  align-items: center;

  sup {
    top: -0.3rem;
  }
`

export const PartnerConditionInconstant = styled(Caption)``

const defaultPartnerConditionStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-left: 48px;
  padding-right: 16px;
`

export const PartnerConditionUpfrontPayment = styled(Caption)`
  ${defaultPartnerConditionStyle}

  margin-bottom: 6px;
  color: ${theme.colors.bluePrimary};
`

export const InstallmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-top: 32px;
`

export const InstallmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
