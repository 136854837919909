import { PreApprovedContent } from '~/components/organisms'
import * as S from './style'

const PreApproved = () => (
  <S.Wrapper>
    <PreApprovedContent />
  </S.Wrapper>
)

export default PreApproved
