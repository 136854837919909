import { publicEncrypt } from 'crypto-browserify'
import { removeMask } from './removeMask'

interface IHashCard {
  cardNumber: string
  cvv: string
  expirationDate: string
  holderName: string
}

const CREDIT_CARD_PUB_KEY =
  process.env.NEXT_PUBLIC_API_ENV === 'production'
    ? process.env.NEXT_PUBLIC_CREDIT_CARD_KEY_PROD
    : process.env.NEXT_PUBLIC_CREDIT_CARD_KEY_DEV

/**
 * Hashes a credit card using CREDIT_CARD's public key
 * @param {data} - The credit card data
 * @param {IHashCard['cardNumber']} data.cardNumber - The credit card number
 * @param {IHashCard['cvv']} data.cvv - The credit card cvv
 * @param {IHashCard['expirationDate']} data.expirationDate - The credit card expiration date in the format "MM/YY"
 * @param {IHashCard['holderName']} data.holderName - The credit card holder full name
 * @returns {Promise<string>} - The hashed credit card
 */
export const hashCard = async ({ cardNumber, cvv, expirationDate, holderName }: IHashCard): Promise<string> => {
  try {
    const month = expirationDate.split('/')[0] || ''
    const year = expirationDate.split('/')[1] || ''

    const body = {
      number: removeMask(cardNumber),
      cvv,
      expirationMonth: month,
      expirationYear: year,
      holderName,
    }

    const buffer = Buffer.from(JSON.stringify(body))

    const hashedCard = publicEncrypt(CREDIT_CARD_PUB_KEY, buffer).toString('base64')

    return hashedCard

    return ''
  } catch (error) {
    process.env.NEXT_PUBLIC_API_ENV === 'staging' && console.log('error', error)
    return 'Ocorreu um erro inesperado'
  }
}
