import moment from 'moment'

export function validateBirthDate(birthDate: string) {
  if (birthDate?.length < 10) {
    return false
  }
  const splittedDate = birthDate?.split('/')
  if (splittedDate?.length < 3) {
    return false
  }

  const date = moment(birthDate, 'DD/MM/YYYY')

  if (!date.isValid()) {
    return false
  }

  // 16 years * 365.25 (365 days with leap years - 1 day (in case it's their birthday))
  if (date.year() < 1900 || moment().diff(moment(date, 'DD/MM/YYYY'), 'day') <= 16 * 365.25 - 1) {
    return false
  }

  return date.isBefore(moment())
}
