import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  ${theme.breakpoints.up('md')} {
    > button {
      display: none;
    }
  }
`
