import moment from 'moment'
import { useContext } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { useShowTaxesOnCheckout } from '~/hooks/useShowTaxesOnCheckout'
import { translatePaymentForm } from '~/utils/translatePaymentForm'

interface IUseCartReview {
  shouldUseCartData: boolean
}

export const useCartReview = ({ shouldUseCartData }: IUseCartReview) => {
  const { cartData } = useContext(ParamsContext)
  const { showTaxesOnCheckout } = useShowTaxesOnCheckout()

  const {
    chosenPaymentMethod,
    paymentInformationData,
    chosenCourseFinancingInstallment,
    checkoutSuccessObject,
    selectedUpfront,
  } = useContext(CheckoutContext)

  const invoiceDate = moment(checkoutSuccessObject?.paymentInfo?.invoiceDueDate).format('DD/MM/YYYY')
  const tryToUseCartData = shouldUseCartData && cartData?.paymentMethod
  const selectedUpfrontAmount =
    paymentInformationData?.response.CourseFinancing?.options.find(
      ({ upfrontAmountInPercent }) => upfrontAmountInPercent === selectedUpfront,
    ) || paymentInformationData?.response.CourseFinancing?.options?.[0]

  const courseFinancingCartPaymentData = {
    upfrontAmount: tryToUseCartData
      ? (cartData?.paymentMethod.upfrontAmount * 100 || 0) + (cartData?.paymentMethod.registrationFeeAmount * 100 || 0)
      : (selectedUpfrontAmount?.upfrontAmountInCents || 0) + (selectedUpfrontAmount?.registrationFeeAmountInCents || 0),
    installmentsToApply: tryToUseCartData
      ? cartData?.paymentMethod.installmentsToApply || 0
      : chosenCourseFinancingInstallment?.installmentsToApply || 0,
    installmentPrice: tryToUseCartData ? cartData?.paymentMethod.PMT * 100 : chosenCourseFinancingInstallment?.pmt || 0,
    monthlyInterest: tryToUseCartData
      ? cartData?.paymentMethod.monthlyInterest / 100
      : chosenCourseFinancingInstallment?.monthlyInterest || 0,
    totalAmount: tryToUseCartData ? cartData?.paymentMethod.total * 100 || 0 : chosenCourseFinancingInstallment?.total,
  }

  const boletoCartPaymentData = {
    totalAmount: tryToUseCartData ? cartData?.paymentMethod.total || 0 : checkoutSuccessObject?.paymentInfo?.amount,
  }

  const creditCardCartPaymentData = {
    installmentsToApply: tryToUseCartData
      ? cartData?.paymentMethod.installmentsToApply || 0
      : checkoutSuccessObject?.paymentInfo?.installments || 0,
    installmentPrice: tryToUseCartData ? cartData?.paymentMethod.PMT : checkoutSuccessObject?.paymentInfo?.amount || 0,
    monthlyInterest: tryToUseCartData
      ? cartData?.paymentMethod.monthlyInterest
      : checkoutSuccessObject?.paymentInfo?.monthlyInterest || 0,
    totalAmount: tryToUseCartData ? cartData?.paymentMethod.total || 0 : checkoutSuccessObject?.paymentInfo?.amount || 0,
  }

  return {
    chosenPaymentMethod: tryToUseCartData
      ? translatePaymentForm(cartData?.paymentMethod.method)
      : translatePaymentForm(chosenPaymentMethod || ''),
    checkoutSuccessObject,
    invoiceDate,
    courseFinancingCartPaymentData,
    boletoCartPaymentData,
    creditCardCartPaymentData,
    showTaxesOnCheckout,
  }
}
