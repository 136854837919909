import { ChangeEvent, KeyboardEvent, useMemo } from 'react'
import { InputFieldV3 as Input } from '@provi/provi-components'
import mailcheck from 'mailcheck'
import { useInputFieldEmail } from './hooks'
import { FormikErrors, FormikTouched } from 'formik'
import * as S from './styles'

interface IInputFieldEmail {
  fieldName: 'email' | 'confirmEmail'
  label: string
  placeholder: string
  emailValue: string
  setFieldValue: (fieldName: string, value: string) => void
  setFieldTouched: (fieldName: string) => void
  validateForm: () => void
  handleEnterKey: (event: KeyboardEvent<HTMLFormElement>) => void
  hasError: FormikErrors<unknown> | string
  hasTouched: FormikTouched<unknown> | string
  mobileWidth?: string
}

export const InputFieldEmail = ({
  fieldName,
  label,
  placeholder,
  emailValue,
  setFieldValue,
  setFieldTouched,
  validateForm,
  hasError,
  hasTouched,
  handleEnterKey,
  mobileWidth,
}: IInputFieldEmail) => {
  const { setSuggestionEmail, suggestionEmail } = useInputFieldEmail()

  const renderSuggestionEmail = useMemo(() => {
    if (suggestionEmail && emailValue && !hasError && hasTouched && suggestionEmail != emailValue) {
      return (
        <S.EmailTypoText>
          Você quer dizer{' '}
          <S.EmailTypoButton
            onClick={() => {
              setFieldValue(fieldName, suggestionEmail)
              setSuggestionEmail('')
              validateForm()
            }}
          >
            {suggestionEmail}
          </S.EmailTypoButton>
          ?
        </S.EmailTypoText>
      )
    }
  }, [fieldName, setFieldValue, suggestionEmail, setSuggestionEmail, validateForm, emailValue, hasError, hasTouched])

  return (
    <S.EmailTypoWrapper>
      <Input
        label={label}
        placeholder={placeholder}
        width="368px"
        value={emailValue}
        name={fieldName}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFieldValue(fieldName, e.target.value)

          mailcheck.run({
            email: e.target.value,
            domains: ['gmail.com', 'outlook.com', 'hotmail.com', 'yahoo.com.br', 'live.com', 'provi.com.br'],
            topLevelDomains: ['com', 'com.br', 'net', 'org', 'info'],
            secondLevelDomains: ['gmail', 'hotmail', 'outlook', 'yahoo', 'live', 'provi'],
            suggested: (suggestion: any) => {
              setSuggestionEmail(suggestion.full)
            },
          })
        }}
        onBlur={() => {
          setFieldTouched(fieldName)
          validateForm()
        }}
        isValid={!hasError && hasTouched}
        hasError={hasError && hasTouched}
        errorMessage={hasError}
        onKeyDown={handleEnterKey}
        mobileWidth={mobileWidth}
      />
      {renderSuggestionEmail}
    </S.EmailTypoWrapper>
  )
}
