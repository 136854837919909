import { parsePrice } from '~/utils/parsePrice'
import * as S from './style'

interface ISummaryTicket {
  classNameByPartner: string
  courseName: string
  price: string
}

const SummaryTicket = ({ classNameByPartner, courseName, price }: ISummaryTicket) => (
  <S.SummaryWrapper>
    <S.CaptionTitle>{classNameByPartner}</S.CaptionTitle>
    <S.CaptionCourseName>{courseName}</S.CaptionCourseName>
    <S.CaptionPrice>{parsePrice(price)}</S.CaptionPrice>
  </S.SummaryWrapper>
)

export default SummaryTicket
