import { useMemo } from 'react'
import CheckoutSuccess from '../../CheckoutSuccess'
import { useWaitingGuarantorSignature } from './hooks'
import * as S from './style'

const WaitingGuarantorSignature = () => {
  const { guarantorSignatureData, isLoadingData, getCoursesNames } = useWaitingGuarantorSignature()

  const renderSignatureMessageText = useMemo(() => {
    if (!isLoadingData) {
      return (
        <>
          <S.MessageTextLine>
            Você contratou o financiamento para o curso {getCoursesNames(guarantorSignatureData?.courseNames || [])} com{' '}
            {`${guarantorSignatureData?.partnerName}`}.
          </S.MessageTextLine>
          <S.MessageTextLine data-recording-sensitive data-clarity-mask="true">
            Para garantir o acesso ao curso, {`${guarantorSignatureData?.guarantorName}`} precisa assinar o contrato digital
            também.
          </S.MessageTextLine>
          <S.MessageTextLine>E atenção: vamos te mandar os próximos passos por e-mail.</S.MessageTextLine>
        </>
      )
    }
  }, [isLoadingData, guarantorSignatureData, getCoursesNames])

  return (
    <S.Wrapper>
      <CheckoutSuccess title="Contrato assinado" text={renderSignatureMessageText || ''} isSuccess />
    </S.Wrapper>
  )
}

export default WaitingGuarantorSignature
