import { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { CoursesContext } from '~/contexts/CoursesContext'
import { readPreSelfie, sendContinueFromPhone, writePreSelfie } from '~/services/api'
import { isWebview } from '~/utils/isWebview'

export const usePreSelfieContent = () => {
  const [checklistOptions, setChecklistOptions] = useState<string[]>([])
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const { isLoadingData, step, isSendingData } = useContext(CheckoutContext)
  const { openModal } = useContext(CoursesContext)

  useEffect(() => {
    async function getPreSelfie() {
      const result = await readPreSelfie()

      if (typeof result != 'string') {
        setChecklistOptions(result.response.checklistOptions)
      }
    }

    getPreSelfie()
  }, [])

  const handleSubmitButton = useCallback(async () => {
    const shouldShowModal = isWebview()

    if (shouldShowModal) {
      window?.smartlook?.('track', 'showModalWebView')
      openModal()
      return
    }

    await writePreSelfie({ currentScreen: step })
  }, [step, openModal])

  const handleContinueFromPhone = useCallback(async () => {
    setIsLocalLoading(true)
    const data = await sendContinueFromPhone()

    if (typeof data != 'string') {
      toast.success(`Acabamos de enviar um link para o seu celular por ${data.method || 'SMS'}.`, {
        toastId: 'continueFromPhone',
      })
    }

    // wait 10s to enable button again
    setTimeout(() => setIsLocalLoading(false), 10000)
  }, [])

  return {
    checklistOptions,
    isLoadingData,
    handleSubmitButton,
    isSendingData,
    handleContinueFromPhone,
    isLocalLoading,
  }
}
