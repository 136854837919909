import styled from 'styled-components'
import { Body2 } from '~/styles/global'
import { theme } from '@provi/provi-components'

export const CopyToClipboardWrapper = styled.div`
  display: flex;

  > div > label > p {
    margin-top: 0;
  }
`

export const ButtonText = styled(Body2)``

export const ButtonWrapper = styled.button`
  background: ${theme.colors.bluePrimary};
  min-width: 103px;
  color: ${theme.colors.white100};
  height: 48px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 4px 4px 0px;

  > svg {
    width: 16px;
    margin-left: 10px;

    path {
      stroke: ${theme.colors.white100};
    }
  }
`
