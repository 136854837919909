import { TranslatedMethods } from '../types'

/**
 * This method translates the payment form into either Portuguese regardless of the language.
 */
export const translatePaymentForm = (paymentMethod: string): TranslatedMethods => {
  switch (paymentMethod?.toLowerCase()) {
    case 'boleto':
      return 'Boleto'
    case 'creditcard':
    case 'cartão de crédito':
      return 'Cartão de crédito'
    case 'coursefinancing':
    case 'financiamento':
      return 'Boleto parcelado'
    case 'pix':
      return 'Pix'
    default:
      return 'Boleto parcelado'
  }
}
