import styled, { css } from 'styled-components'
import { Overline } from '~/styles/global'

interface IHeaderCheckoutTitle {
  titleColor?: string
}

export const HeaderCheckoutContainer = styled.div<{ isReviewingPayment?: boolean }>`
  ${({ isReviewingPayment }) =>
    isReviewingPayment &&
    css`
      display: flex;
      align-items: flex-end;
    `}
`

export const HeaderBasicInfoWrapper = styled.div`
  margin: 10px 24px 0;
`

export const HeaderCheckoutTitle = styled(Overline)<IHeaderCheckoutTitle>`
  color: ${({ titleColor }) => titleColor};
`
