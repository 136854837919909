import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2 } from '~/styles/global'

interface ILabel {
  isActive: boolean
}

export const LabelWrapper = styled.div`
  width: 115px;
`

export const Label = styled.label<ILabel>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  cursor: pointer;
  user-select: none;

  color: ${theme.colors.bluePrimary};

  ${({ isActive }) =>
    isActive &&
    css`
      border: 2px solid ${theme.colors.bluePrimary};
      background: ${theme.colors.bluePrimaryLight};
    `}

  &:focus,
  &:focus-within {
    border: 2px solid ${theme.colors.bluePrimary};
    background: ${theme.colors.bluePrimaryLight};
    color: ${theme.colors.bluePrimaryDark};
  }

  &:active {
    background: ${theme.colors.blueSecundaryDark} !important;
  }

  &:hover {
    letter-spacing: 0.005em;
    color: ${theme.colors.bluePrimaryDark};
    background: ${theme.colors.bluePrimaryLight};
  }
`

export const TextButton = styled(Body2)`
  font-weight: 600;
`
