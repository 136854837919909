import { theme } from '@provi/provi-components'
import Image from 'next/image'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { Body1, Body2, Overline } from '~/styles/global'

interface ILinkProp {
  isVariant?: boolean
}

interface ILabelText {
  isVariant?: boolean
  isUnavailable?: boolean
}

export const SvgContainer = styled.div`
  grid-column: 1 / span 3;
`

export const Svg = styled(Image)``

export const DescriptionText = styled(Overline)`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-transform: none;
`

export const LinkContainer = styled(Link)``

export const SubLink = styled.a<ILinkProp>`
  color: ${theme.colors.black100};
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-decoration-line: none;

  ${({ isVariant }) =>
    isVariant &&
    css`
      text-decoration-line: underline;
    `}

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
  }
`

export const PriceValue = styled(Body1)`
  color: ${theme.colors.bluePrimary};
`

export const OldPrice = styled(Overline)`
  font-size: 12.8px;
  line-height: 20px;
  color: ${theme.colors.greyPrimary};
  text-decoration-line: line-through;
`

export const LabelText = styled(Body2)<ILabelText>`
  margin: 11px 0;

  ${({ isVariant }) =>
    isVariant &&
    css`
      font-weight: 600;
    `}

  ${({ isUnavailable }) =>
    isUnavailable &&
    css`
      color: ${theme.colors.greyPrimary};
    `}
`
