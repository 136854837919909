import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const BackButtonContainer = styled.button`
  display: flex;
  background: transparent;
  margin: 19px 24px 0;
  border: none;
  cursor: pointer;
  width: fit-content;

  &:disabled {
    cursor: not-allowed;
  }

  ${theme.breakpoints.up('md')} {
    margin: 12px 24px 0;
  }
`
