import { useContext, useCallback, useMemo } from 'react'
import { theme } from '@provi/provi-components'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { IStep } from '../types'
import { formScreenRegex } from '~/constants/formScreenRegex'

interface IProgressBar {
  title: string
  showProgressBar: number
  previousScreenUniqueName: IStep
  arrowDisabled?: boolean
  titleColor?: string
}

export const useProgressBar = () => {
  const { progressBar, step } = useContext(CheckoutContext)

  const title = useMemo(
    () => ({
      preRegistration: 'Cadastro',
      basicInfo: 'Cadastro',
      address: 'Cadastro',
      paymentSelection: 'Pagamento',
      emailVerification: 'Verificação',
      checkoutEmailVerification: 'Verificação',
      smsVerification: 'Verificação',
      introGuarantor: 'análise de crédito',
      guarantorChecklist: 'análise de crédito',
      appointGuarantor: 'análise de crédito',
      preApproved: 'análise de crédito',
      guarantorAnalysis: 'Indicação de avalista',
      preSelfie: 'Documentos',
      preDocument: 'Documentos',
      endBasicInfo: 'Documentos',
      financingSummary: 'Contrato',
      creditApproved: 'Contrato',
      contractSummary: 'Contrato',
      couponCode: 'Cupom',
      selectProduct: 'Seleção de Produto',
    }),
    [],
  )

  const getInfosFromStep = useCallback((): IProgressBar => {
    const defaultProgressParams = {
      previousScreenUniqueName: progressBar?.previousScreenUniqueName || 'preRegistration',
      arrowDisabled: !progressBar?.canBack,
      showProgressBar: progressBar?.percentageCompleted || 50,
      titleColor: theme.colors.bluePrimary,
    }

    const mainTitle = formScreenRegex.test(step) ? 'Questões abertas' : title[step as keyof typeof title]

    return {
      title: mainTitle,
      ...defaultProgressParams,
    }
  }, [step, progressBar, title])

  const hideProgressBar = Object.keys(title).includes(step) || formScreenRegex.test(step)

  return {
    getInfosFromStep,
    hideProgressBar,
    title,
  }
}
