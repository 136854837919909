import { ButtonV3 as Button, TextButton } from '@provi/provi-components'
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Footer, Header } from '~/components/index'
import Cart from '~/components/organisms/Cart'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'

import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { IPartnerData } from 'types'
import { CoursesContext } from '~/contexts/CoursesContext'
import { useInIframe } from '~/hooks/useInIframe'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import { cn } from '~/utils/cn'
import * as S from './styles'

interface IMainPublic {
  title?: string
  children: JSX.Element | ReactNode
  ssrPartnerData?: IPartnerData
}

const MainPublic = ({ title, ssrPartnerData, children }: IMainPublic) => {
  const router = useRouter()
  const { isInIframe } = useInIframe()
  const {
    step,
    setStep,
    isLoadingData,
    isSendingData,
    setIsLoadingData,
    setIsRedirecting,
    setPartnerId,
    isHeaderCheckoutShown,
    chosenPaymentMethod,
    isInCourseFinancingReviewScreen,
    inPageError,
  } = useContext(CheckoutContext)
  const { route, partnerSlug, isCartLoading, cartData, productType, partnerData } = useContext(ParamsContext)
  const { unavailableClickedCourseObject } = useContext(CoursesContext)
  const [shouldHideHeader, setShouldHideHeader] = useState(false)

  const allPartnerData = useMemo(() => {
    return ssrPartnerData || partnerData
  }, [ssrPartnerData, partnerData])

  const isInCoursesScreen = router.route === '/courses/[slug]'

  const isProviPay = productType === 'ProviPay'
  const isISA = productType === 'ISA'

  useEffect(() => {
    const hideHeader =
      !isHeaderCheckoutShown || (step === 'paymentSelection' && chosenPaymentMethod && isInCourseFinancingReviewScreen) || isISA

    setShouldHideHeader(hideHeader)
  }, [isHeaderCheckoutShown, step, chosenPaymentMethod, isInCourseFinancingReviewScreen, isISA])

  useEffect(() => {
    setPartnerId(allPartnerData?.PartnerId || null)
    setIsLoadingData(false)
    setIsRedirecting(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPartnerData?.PartnerId, setPartnerId, router.pathname])

  const handleClick = useCallback(() => {
    if (typeof window !== 'undefined') {
      const url = changeRouteKeepParams(router.asPath, `/checkout/${partnerSlug}`)

      router.push(url)
      // set the step so that it doesn't default
      !step && setStep('preRegistration')
      setIsLoadingData(true)
    }
  }, [partnerSlug, router, setIsLoadingData, setStep, step])

  const renderBuyButton = useMemo(() => {
    return (
      <Button
        text="Comprar"
        icon
        color="primary"
        width="100%"
        mobileWidth="100%"
        isLoading={isLoadingData || isSendingData || isCartLoading}
        onClick={handleClick}
      />
    )
  }, [isLoadingData, isSendingData, isCartLoading, handleClick])

  const renderCart = useMemo(() => {
    return <Cart />
  }, [])

  const seoDescription =
    'Acreditamos que as pessoas podem fazer coisas incríveis se tiverem o incentivo certo no momento correto. Alavanque sua carreira. Conte com a PrincipiaPay!'

  const seoImage = '/og_logo_principiapay.png'

  return (
    <S.Wrapper isInIframe={isInIframe}>
      <NextSeo
        title={`PrincipiaPay${title ? ` | ${title}` : ''}`}
        description={seoDescription}
        openGraph={{
          title: `PrincipiaPay${title ? ` | ${title}` : ''}`,
          description: seoDescription,
          locale: 'pt_BR',
          site_name: 'PrincipiaPay',
          url: `https://pay.principia.net${router.asPath}`,
          type: 'website',
          images: [
            {
              url: seoImage,
              alt: allPartnerData?.partnerName ? `${allPartnerData?.partnerName} logo` : 'ProviPay logo',
            },
          ],
        }}
      />
      <Header
        partnerName={allPartnerData?.partnerName || 'Principia'}
        partnerLogo={allPartnerData?.partnerImage}
        cartItems={cartData?.courses?.length || 0}
        cart={renderCart}
      />
      <S.Content>
        <div className="flex flex-col gap-4 w-full col-start-2 col-end-[span_9] ">
          <div
            id="error-portal"
            className={cn({
              'pt-10 sm:pt-0': true,
              hidden: !inPageError || step !== 'singlePageCheckout',
            })}
          />
          <S.Main isVariant={shouldHideHeader || Boolean(inPageError)}>
            {isInCoursesScreen && !isInIframe && (
              <S.ButtonWrapperContinueRequest>
                <TextButton
                  text="Já comecei uma compra"
                  icon
                  onClick={() => router.push(changeRouteKeepParams(router.asPath, `/checkout/${partnerSlug}`))}
                />
              </S.ButtonWrapperContinueRequest>
            )}
            {children}
          </S.Main>
        </div>
        <S.Sidebar isInIframe={isInIframe}>
          {![
            'checkoutEmailVerification',
            'reviewInfo',
            'checkoutFail',
            'guarantorDenied',
            'checkoutSuccess',
            'invalidRequest',
            'existingRequests',
            'incompleteCCB',
            'incompleteBasicInfoAnalysis',
            'incompleteDocumentAnalysisTimeout',
            'waitingGuarantorSignature',
          ].includes(step) &&
            (step || route === '/courses/[slug]') &&
            !unavailableClickedCourseObject &&
            isProviPay && <S.Cart>{renderCart}</S.Cart>}

          {route === '/courses/[slug]' && !unavailableClickedCourseObject && !!router.query?.classes?.length && (
            <S.ButtonWrapper>{renderBuyButton}</S.ButtonWrapper>
          )}
        </S.Sidebar>

        {route === '/courses/[slug]' && !unavailableClickedCourseObject && !!router.query?.classes?.length && (
          <S.MobileButtonWrapper>{renderBuyButton}</S.MobileButtonWrapper>
        )}
      </S.Content>
      <Footer isInCheckout={route === '/checkout/[slug]'} isInIframe={isInIframe} />
    </S.Wrapper>
  )
}

export default MainPublic
