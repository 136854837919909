import { theme } from '@provi/provi-components'

interface IUploadIcon {
  disabled?: boolean
}

const UploadIcon = ({ disabled = false }: IUploadIcon) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.71875 6.40405L10 3.1228L13.2812 6.40405M10 11.8751V3.12507M17.5 10.6251V16.2501C17.5 16.4158 17.4342 16.5748 17.3169 16.692C17.1997 16.8092 17.0408 16.8751 16.875 16.8751H3.125C2.95924 16.8751 2.80027 16.8092 2.68306 16.692C2.56585 16.5748 2.5 16.4158 2.5 16.2501V10.6251"
      stroke={disabled ? theme.colors.greyPrimary : theme.colors.white100}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UploadIcon
