import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const InputWrapper = styled.div`
  margin: 26px 0 22px;

  label > div {
    height: 48px;
    border: 2px solid ${theme.colors.bluePrimaryLight};
  }
`
