import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const CartFooterInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  padding-top: 5px;
  border-top: 1px solid ${theme.colors.bluePrimaryLight};

  div {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`
