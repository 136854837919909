import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { Caption } from '~/styles/global'

export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  position: absolute;
  bottom: calc(100% + 20px);
  left: 0;
  width: 100%;
  max-width: 368px;
  padding: 13px 14px 11px 16px;
  border-radius: 8px;

  background: ${theme.colors.bluePrimaryDark};

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 28px;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid ${theme.colors.bluePrimaryDark};
  }
`

export const TooltipText = styled(Caption)`
  width: 100%;
  max-width: 304px;
  color: ${theme.colors.white100};
  cursor: default;
`
