import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const PaymentMethodCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 144px);
  gap: 16px;

  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-wrap: nowrap;
    width: calc(100vw - 24px);
    padding-right: 24px;
    gap: 8px;
    overflow-x: auto;

    button {
      min-width: 115px;
    }

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`
