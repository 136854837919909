import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IButtonWrapper {
  selected: boolean
  removable: boolean
}

export const RowSpecificsWrapper = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
    text-transform: capitalize;
  }
`

export const ButtonWrapper = styled.button<IButtonWrapper>`
  width: 100%;
  height: 44px;
  background: ${theme.colors.bluePrimaryLight};
  border: none;
  display: flex;
  padding-left: 11px;
  align-items: center;
  cursor: ${({ removable }) => (removable ? 'pointer' : 'default')};

  label,
  label > input {
    cursor: ${({ removable }) => (removable ? 'pointer' : 'default')};

    :focus-within,
    :focus,
    :active {
      outline: none;
      border: none;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${theme.colors.bluePrimary};
    }
  `}
`

export const Button = styled.button<{ disabledArrow: boolean }>`
  border: none;
  background: transparent;
  cursor: ${({ disabledArrow }) => (disabledArrow ? 'initial' : 'pointer')};

  -webkit-tap-highlight-color: transparent;

  .arrow-svg {
    transition: stroke 0.2s ease;

    ${({ disabledArrow }) =>
      disabledArrow &&
      css`
        stroke: ${theme.colors.grey500};
      `};
  }

  :active,
  :focus,
  :focus-within {
    outline: none;
  }
`
