import { InputFieldV3, theme } from '@provi/provi-components'
import styled, { css, keyframes } from 'styled-components'

interface IWrapper {
  isSearchOpen: boolean
}

type IInputWrapper = IWrapper

interface IButton {
  isSearchOpen?: boolean
}

interface ICartWrapper {
  isCartOpen: boolean
}

const openInput = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`

const openCart = keyframes`
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateX(0);
  }
`

export const Wrapper = styled.div<IWrapper>`
  * {
    margin: 0;
    padding: 0;
  }

  display: flex;

  ${theme.breakpoints.down('md')} {
    ${({ isSearchOpen }) =>
      isSearchOpen &&
      css`
        width: 100%;
        justify-content: center;
      `}
  }
`

export const CartWrapper = styled.div<ICartWrapper>`
  display: none;
  transition: all 0.2s ease;
  animation: ${openCart} 0.4s ease reverse;

  ::-webkit-scrollbar {
    width: 0;
  }

  ${theme.breakpoints.down('md')} {
    ${({ isCartOpen }) =>
      isCartOpen &&
      css`
        animation: ${openCart} 0.2s ease;
        display: flex;
        background: ${theme.colors.white100};
        width: 100%;
        max-height: calc(100vh - 48px);
        position: absolute;
        left: 0;
        right: 0;
        top: 48px;

        padding: 0 24px 22px;
        border-right: 1px solid ${theme.colors.bluePrimaryLight};
        border-left: 1px solid ${theme.colors.bluePrimaryLight};
        border-bottom: 1px solid ${theme.colors.bluePrimaryLight};
        border-radius: 0 0 16px 16px;
        z-index: 3;
        overflow-y: scroll;
      `}
  }
`

export const InputWrapper = styled.div<IInputWrapper>`
  ${theme.breakpoints.down('md')} {
    display: none;
    transform: translateX(100%);
    animation: ${openInput} 0.2s ease-in-out;

    ${({ isSearchOpen }) =>
      isSearchOpen &&
      css`
        display: flex;
        transform: translateX(0);
        margin-left: 24px;
      `}
  }
`

export const Input = styled(InputFieldV3)``

export const Icon = styled.img`
  margin-right: 8px;
`

export const ButtonWrapper = styled.span`
  display: none;
  z-index: 4;

  ${theme.breakpoints.down('md')} {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`

export const Button = styled.button<IButton>`
  background: none;
  border: none;
  cursor: pointer;

  img {
    margin: 0;
    width: 24px;
    min-width: 24px;
    display: flex;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }

    :active {
      opacity: 1;
    }
  }

  ${({ isSearchOpen }) =>
    isSearchOpen &&
    css`
      display: none;
    `}
`
