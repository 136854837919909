import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { Body2, Button } from '~/styles/global'

export const CarouselContainer = styled.div`
  padding-left: 16px;
`

export const CarouselWrapper = styled.div`
  padding: 15px 0px 34px;
  border-bottom: 2px solid ${theme.colors.bluePrimaryLight};
  overflow: hidden;
  position: relative;
`

export const CarouselContent = styled.div``

export const UnavailableCourseText = styled(Body2)`
  margin: 0 16px;
  max-width: 480px;
`

export const UnavailableCourseButton = styled(Button)``

export const EmptyCarouselContent = styled.div`
  padding-top: 79px;
  padding-left: 24px;

  h3 {
    font-size: 20px;
    font-weight: normal;
  }

  p {
    font-size: 16px;
    margin-top: 31px;
  }
`
