import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface ICheckCard {
  isActive: boolean
}

interface ILabel {
  marginTop?: number
  blue?: boolean
  shouldSpan?: boolean
}

export const WrapperForm = styled.section`
  margin: 35px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-height: 400px;
  align-items: stretch;

  ${theme.breakpoints.down('md')} {
    margin-top: 14px;
  }
`

export const Label = styled.label<ILabel>`
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}

  ${({ blue }) =>
    blue &&
    css`
      color: ${theme.colors.bluePrimary};

      :hover {
        cursor: pointer;
      }
    `}

    ${theme.breakpoints.down('md')} {
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.005em;
    text-align: left;
  }
`

export const Row = styled.div<ILabel>`
  display: flex;
  flex-direction: column;
  max-width: 368px;
  justify-content: space-between;

  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  ${({ shouldSpan }) => shouldSpan && `flex: 1 1`}
`

export const CheckCardWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`

export const CheckCard = styled.div<ICheckCard>`
  display: flex;
  min-height: 64px;
  min-width: 368px;
  max-width: 408px;
  margin: 20px 0 0 0;
  border-radius: 8px;

  padding-top: 8px;
  padding-bottom: 8px;

  :hover {
    cursor: pointer;
    border: 2px solid ${theme.colors.bluePrimary};
  }

  label {
    cursor: pointer;
    font-size: 16px;
    font-style: normal;

    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    ${({ isActive }) => isActive && `font-weight: 600; color: ${theme.colors.bluePrimary};`}
  }

  ${({ isActive }) =>
    isActive ? `border: 2px solid ${theme.colors.bluePrimary}` : `border: 2px solid ${theme.colors.bluePrimaryLight}`}
`

export const ChangeIdentificationButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  max-width: 368px;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    width: 100%;
    gap: 18px;
  }
`
