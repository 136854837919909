import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const TextButtonWrapper = styled.div`
  margin-bottom: 56px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 0;
  }
`
