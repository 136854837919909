import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2, loadingCSS } from '~/styles/global'

interface ICourseFinancingFormWrapper {
  isInCourseFinancingReviewScreen: boolean
}

export const CourseFinancingFormWrapper = styled.div<ICourseFinancingFormWrapper>`
  display: flex;
  flex-direction: column;
  gap: 18px;

  margin-top: 41px;

  ${({ isInCourseFinancingReviewScreen }) =>
    isInCourseFinancingReviewScreen &&
    css`
      gap: 0;
      margin-top: 18px;
    `}

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const CourseFinancingFormText = styled(Body2)`
  max-width: 464px;
`

export const ButtonWrapper = styled.div`
  margin-top: 14px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const CourseFinancingCartItems = styled.div`
  margin-bottom: 35px;
  width: 100%;
`

export const CourseFinancingFormTextLoading = styled.div`
  ${loadingCSS}
  width: 100%;
  max-width: 368px;
  height: 72px;
  border-radius: 8px;
`

export const CourseFinancingSelectorLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const CourseFinancingSelectorLoading = styled.div`
  ${loadingCSS}
  width: 100%;
  max-width: 368px;
  height: 44px;
  border-radius: 8px;
`
