import { event } from '.'

interface IGtagPurchase {
  paymentInfo?: {
    amount: number | null
    method: string
  }
  classObjects?: {
    CourseClassId: number | null
    CourseId: number | null
    className: string
    courseName: string
    price: string | null
  }[]
}

export const gtagPurchase = ({ paymentInfo, classObjects }: IGtagPurchase) => {
  event({
    action: 'purchase',
    eventParams: {
      value: paymentInfo?.amount,
      paymentMethod: paymentInfo?.method,
      currency: 'BRL',
      items: classObjects?.map((item) => {
        return {
          CourseClassId: item?.CourseClassId,
          CourseId: item?.CourseId,
          className: item?.className,
          courseName: item?.courseName,
          price: item?.price,
          quantity: 1,
        }
      }),
    },
  })
}
