import * as S from './style'

interface ILoadingInput {
  inputWidth?: string
  hasLabel?: boolean
}

const LoadingInput = ({ inputWidth, hasLabel = true }: ILoadingInput) => {
  return (
    <S.InputLoadingContainer inputWidth={inputWidth}>
      {hasLabel && <S.LoadingTitle />}
      <S.LoadingInput />
    </S.InputLoadingContainer>
  )
}

export default LoadingInput
